import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';

@Injectable()
export class PermissionService {
  constructor(private sessionService: SessionService) {}

  hasCurrentUser(): boolean {
    return this.sessionService.currentUser !== undefined;
  }

  checkPermissions(permission): boolean {
    if (!this.hasCurrentUser()) {
      return false;
    }

    const currentUsersPermissions = this.sessionService.currentUser.permissions;
    const permissionSplit = permission.split(':');
    const permissionWildCardCheck1 = `${permissionSplit[0]}:*`;
    const permissionWildCardCheck2 = `${permissionSplit[0]}:${permissionSplit[1]}:*`;
    const permissionWildCardCheck3 = `${permissionSplit[0]}:${permissionSplit[1]}:${permissionSplit[2]}:*`;

    if (
      currentUsersPermissions.indexOf('*') > -1 || // if the user has all the permissions
      currentUsersPermissions.indexOf(permissionWildCardCheck1) > -1 || // if the user has permission for all under a service
      currentUsersPermissions.indexOf(permissionWildCardCheck2) > -1 || // if the user has permission to perform all actions under a page
      currentUsersPermissions.indexOf(permissionWildCardCheck3) > -1 ||
      currentUsersPermissions.indexOf(permission) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export const Permissions = {
  all: '*',
  ui_quick_launch: 'portal:journey_center:quick_launch',
  ui_journey_launcher: 'portal:journey_center:journey_launcher',
  ui_journey_launcher_msg_only: 'portal:journey_center:journey_launcher_csr',
  ui_journey_list: 'portal:journey_center:journey_list',
  ui_journey_builder: 'portal:journey_center:journey_builder',
  ui_media_asset: 'portal:journey_center:media_library',
  ui_performance: 'portal:data:performance',
  ui_client_list: 'portal:settings:client_list',
  ui_client_config: 'portal:settings:configure',
  ui_user_mgmt: 'portal:settings:user_mgmt',
  ui_customer: 'portal:settings:customer_lookup',
  ui_sftp_mgmt: 'portal:settings:sftp_mgmt',
  ui_sftp_mgmt_setup: 'portal:settings:sftp_mgmt_setup',
  ui_api_mgmt: 'portal:settings:apimgmt',
  client: 'cs:client:view',
  client_edit: 'cs:client:edit',
  client_edit_ext_fields: 'cs:clientcfg:ext_dynamic_fields:edit',
  client_edit_two_way_confg: 'cs:clientcfg:two_way_config:edit',
  client_create: 'cs:client:create',
  product_group: 'cs:product_group:view',
  product_group_edit: 'cs:product_group:edit',
  product_group_create: 'cs:product_group:create',
  user: 'cdm:portal_user:view',
  user_edit: 'cdm:portal_user:edit',
  user_create: 'cdm:portal_user:create',
  user_reset_password: 'cdm:portal_user:reset_password',
  customer: 'cdm:customer:view',
  customer_detail: 'cdm:customer_details:view',
  roc_customer_view: 'roc:customer:view',
  roc_gpg_edit: 'roc:gpg:*',
  journey: 'ms:journey:view',
  journey_create: 'ms:journey:create',
  journey_trigger: 'ms:journey:trigger',
  journey_promote: 'ms:journey:promote',
  journey_edit: 'ms:journey:edit',
  journey_delete: 'ms:journey:delete',
  journey_restore: 'ms:journey:delete',
  journey_schedule: 'ms:journey_schedule:*',
  media_asset: 'ms:media_asset:view',
  media_asset_edit: 'ms:media_asset:edit',
  media_asset_create: 'ms:media_asset:create',
  jobs: 'fe:job:view',
  jobs_add: 'fe:job:add:*',
  jobs_edit: 'fe:job:edit',
  jobs_delete: 'fe:job:delete',
  jobs_create: 'fe:job:create',
  jobs_schedule: 'fe:job_schedule:create',
  jobs_cancel: 'fe:job_schedule:cancel',
  jobs_add_journey_messaging: 'fe:job:add:journey_messaging',
  consent: 'ms:consent:view',
  consent_add: 'ms:consent:create',
  consent_edit: 'ms:consent:edit',
  consent_delete: 'ms:consent:delete',
  two_way_setup: 'portal:two_way:setup',
  two_way_mgmt: 'portal:two_way:mgmt',
  two_way_messaging: 'portal:two_way:messaging',
  new_two_way_messaging: 'portal:two_way:messaging',
  sso_all: 'sso:*',
  sso_edit: 'sso:edit',
  sso_view: 'sso:view',
  sso_create: 'sso:create',
  apikey_all: 'apikey:*',
  apikey_view: 'apikey:view',
  apikey_create: 'apikey:create',
  exp_lib_all: 'exp_lib:*',
  exp_lib_experiences: 'exp_lib:experiences:view',
  exp_lib_admin: 'exp_lib:admin:*',
  exp_lib_admin_view: 'exp_lib:admin:view',
  exp_lib_admin_edit: 'exp_lib:admin:edit',
  exp_lib_experience_import: 'exp_lib:experience:import',
  exp_lib_experience_export: 'exp_lib:experience:export',
  reporting_configuration_read: 'reportingconfiguration:read',
  reporting_configuration_update: 'reportingconfiguration:update',
  reporting_configuration_create: 'reportingconfiguration:create',
  reporting_configuration_delete: 'reportingconfiguration:delete',
  relay_iq_admin: 'relay_iq:admin:*',
};
