import { environment } from '@env/environment';
import { SecureHttp } from '@app/security/secure-http';
import { Filter, SearchCriteria } from '@app/core/utils/search-criteria';
import { Journey } from '@app/core/models/journey';
import { Ordering } from '@app/core/utils/ordering';
import { SessionService } from '@app/security/session.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JourneyService {
  selectableComponennts: any[] = [];

  constructor(private sessionService: SessionService, private http: SecureHttp, private router: Router) {
  }

  getJourneyList(limit: number, offset: number, searchCriteria?: SearchCriteria, ordering?: Ordering, liveOnly?: boolean, hideMask: boolean = true, altClientId?: string): Observable<Array<Journey>> {
    const clientId = altClientId ? altClientId : this.sessionService.currentUser.client.id;
    let result;
    if (!searchCriteria) {
      searchCriteria = new SearchCriteria();
    }

    if (!searchCriteria.filtersIfFieldExists) {
      searchCriteria.filtersIfFieldExists.push(new Filter('deleted', 'false'));
    }

    let url = `${environment.searchURLBase}/client/${clientId}/journeys?limit=${limit}&offset=${offset}`;
    if (ordering) {
      url = `${url}&${ordering.getQueryStringUnderscorish()}`;
    }
    const searchRequest = searchCriteria.getSearchRequest();
    searchRequest['include_draft'] = !liveOnly;

    result = this.http.post(url, searchRequest, null, hideMask).pipe(
      map(response => {
        const journeys: Journey[] = [];
        const journeyObjects = response.journeys;
        for (const journeyObject of journeyObjects) {
          try {
            const journey = Journey.deserialize(journeyObject);
            journeys.push(journey);
          } catch (e) {
            // Ignore old journeys the don't parse.
          }
        }
        return journeys;
      })
    );

    return result;
  }

  getJourneyById(journeyId: string, altClientId?: string): Observable<Journey> {
    const clientId = altClientId ? altClientId : this.sessionService.currentUser.client.id;
    const url = `${environment.messagingURLBase}/client/${clientId}/journey/${journeyId}?include_draft=true`;
    return this.http.get(url).pipe(
      map(response => Journey.deserialize(response))
    )
  }

  getJourneyByTriggerId(triggerId: string, altClientId: string): Observable<Journey> {
    const clientId = altClientId ? altClientId : this.sessionService.currentUser.client.id;
    const url = `${environment.messagingURLBase}/client/${clientId}/journey_trigger/${triggerId}/journey?include_draft=true`;
    return this.http.get(url).pipe(
      map(response => Journey.deserialize(response))
    )
  }

  createJourney(journey: Journey): Observable<string> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.messagingURLBase}/client/${clientId}/journeys/`;
    return this.http.post(url, journey.draft.serialize()).pipe(
      map(response => response.id)
    )
  }

  stripClientTriggerIds(draftComponents: any[]): void {
    draftComponents.forEach((component) => {
      if (component.type === 'APITrigger') {
        component.client_trigger_id = undefined;
      }
    });
  }

  copyJourney(journeyDoc: Journey): Observable<string> {
    const oldIDArray: Object[] = [];
    const draftComponents = journeyDoc.latestContent.components;
    const newJourneyName = 'Copy of ' + journeyDoc.latestContent.name;
    let newFamilyName;

    if (!journeyDoc.draft) {
      journeyDoc.draft = journeyDoc.live;
    }

    this.stripClientTriggerIds(draftComponents);

    journeyDoc.draft.name = newJourneyName;

    draftComponents.forEach(component => {
      oldIDArray.push({
        oldID: component.name,
        newID: component.createGuid()
      });

      if (component.wire && component.wire.actions.length > 0) {
        for (let i = 0; i < component.wire.actions.length; i++) {
          component.wire.actions[i].id = component.createGuid();
        }
      }
    });

    oldIDArray.forEach(entry => {
      const matchedNameComponent = draftComponents.find(component => component['name'] === entry['oldID']);
      const matchedToComponent = draftComponents.find(component => component['to'] === entry['oldID']);
      const matchedParentComponents = draftComponents.filter(component => component['parent'] === entry['oldID']);
      let matchedResponseMsg;

      for (let i = 0; i < draftComponents.length; i++) {
        const component = draftComponents[i];
        if (component.wire && component.wire.actions.length > 0) {
          matchedResponseMsg = component.wire.actions.find(
            action => action['type'] === 'message' && action['value'] === entry['oldID']);
          if (matchedResponseMsg != null) {
            break;
          }
        }
      }

      matchedNameComponent.name = entry['newID'];

      if (matchedNameComponent.type === 'APITrigger') {
        newFamilyName = entry['newID'];
        matchedNameComponent.family = newFamilyName;
      }

      if (matchedNameComponent.type === 'WorkflowEnder' && newFamilyName) {
        matchedNameComponent.family = newFamilyName;
      }

      if (matchedToComponent) {
        matchedToComponent.to = entry['newID'];
      }

      if (matchedResponseMsg) {
        matchedResponseMsg.value = entry['newID'];
      }

      if (matchedParentComponents.length > 0) {
        matchedParentComponents.forEach(component => {
          component.parent = entry['newID'];
        });
      }
    });

    return this.createJourney(journeyDoc);
  }

  promoteJourney(journeyId: string, journey: Journey): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;

    const url = `${environment.messagingURLBase}/client/${clientId}/journey/${journeyId}/promote`;

    const request = {draft_version: journey.draft_version};

    return this.http.post(url, request);
  }

  archiveJourney(journey: Journey): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;

    const url = `${environment.messagingURLBase}/client/${clientId}/journey/${journey.id}`;

    return this.http.delete(url, {}, true);
  }

  restoreJourney(journey: Journey): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;

    const url = `${environment.messagingURLBase}/client/${clientId}/journey/${journey.id}/restore`;

    return this.http.post(url, {}, null, true);
  }

  updateJourney(journeyId: string, journey: Journey, supressMask?: boolean) {
    const clientId = this.sessionService.currentUser.client.id;

    const url = `${environment.messagingURLBase}/client/${clientId}/journey/${journeyId}`;

    const request = {
      draft_version: journey.draft_version,
      body: journey.draft.serialize()
    };

    return this.http.put(url, request, null, supressMask);
  }

  previewJourney(journey: Journey) {
    window.open(`/preview/${journey.id}`);
  }

  pdfPreview(journey: Journey) {
    window.open(`/pdf-preview/${journey.id}`);
  }

  journeyPreviewable(journey: Journey) {
    let previewable = false;
    if (journey && journey.latestContent) {
      const invalidComponents = journey.latestContent.components.filter(c => (c.type === 'MessageSender' || c.type === 'MessageResponse') && c.isNew);
      previewable = invalidComponents.length === 0;
    }
    return previewable;
  }
}
