import { catchError, flatMap } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProductGroup } from '@app/core/models/client';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { Background } from '@app/core/utils/background';
import { PdfService } from '@app/core/services/pdf.service';
import { WirePreviewService } from '@app/core/services/wire-preview.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ControlTagsService } from '@app/core/services/control-tags.service';
import * as _ from 'lodash';
import { MessageClass } from '@app/core/models/message';
import { Action } from '@app/core/models/action';

/* 
  Pdf Preview Rules 
  - can have 1, 2, or 3 pages, for a max of 3 "phone" components
  - "messageSender" types take up 2 spaces (1 for the sms, 1 for the message)
      - they always start a new row
      - they can only be on the same page as 1 other non-"MessageSender" type
*/

class PdfPage {
  title: string;
  messagePreviews: (MessageClass | Action)[];

  constructor(
    title: string = '',
    messagePreviews: (MessageClass | Action)[] = [],
  ) {
    this.title = title;
    this.messagePreviews = messagePreviews;
  }

  public containsMessageSender() {
    return (
      _.filter(this.messagePreviews, (messagePreview) => {
        return messagePreview.type === 'MessageSender';
      }).length > 0
    );
  }

  public isFull() {
    return (
      (this.containsMessageSender() && this.messagePreviews.length === 2) ||
      this.messagePreviews.length === 3
    );
  }

  public addMessagePreview(messagePreview: MessageClass | Action) {
    this.messagePreviews.push(messagePreview);
  }
}

@Component({
  selector: 'app-journey-pdf-preview',
  templateUrl: './journey-pdf-preview.component.html',
  styleUrls: ['./journey-pdf-preview.component.scss'],
})
export class JourneyPdfPreviewComponent implements OnInit {
  @ViewChild('messageDialogError', { static: true })
  errorDialog: MessageDialogComponent;
  @ViewChild('messageDialogProgress', { static: true })
  progressDialog: MessageDialogComponent;
  @Input() messagePreviews: MessageClass[];
  @Input() productGroup: ProductGroup;
  @Input() journey: Journey;
  @Input() journeyContent: JourneyContent;
  _pages: PdfPage[];
  hasIWCU: boolean;
  editMode: boolean = false;
  pdfBrandColor: string;
  twoWayDescText$: { [key: string]: Observable<string> } = {};

  constructor(
    private route: ActivatedRoute,
    private WirePreviewService: WirePreviewService,
    private pdfService: PdfService,
    private controlTagsService: ControlTagsService,
  ) {}

  ngOnInit() {
    Background.previewOn();
    const journeyId = this.route.snapshot.params['journeyId'];

    this.WirePreviewService.getDataFromJourneyId(journeyId).subscribe(
      (data) => {
        this.journey = data.journey;
        this.productGroup = data.productGroup;
        this.hasIWCU = data.hasIWCU;
        this.journeyContent = this.journey.latestContent;
        this.messagePreviews = _.get(
          this.journey,
          'latestContent.components',
          [],
        );
        this._pages = this.generatePages();
        this.pdfBrandColor = this.productGroup.branding.color;
      },
      (error) => {
        this.errorDialog.showMessage(
          'Oops...there was an error getting the experience.',
        );
      },
    );
  }

  requestPdf() {
    this.progressDialog.showMessage(
      'Generating your pdf.  This could take up to a minute.  Please wait...',
    );

    // the design system components use an "implicit" form of styling where
    // the sytles are not inserted into the DOM but modified in memory with
    // `insertRule`—we can retrieve these styles from memory with the following
    // code to then manually insert them into the DOM before we ship it over to
    // the PDF generation backend
    let styles = '';
    for (let i = 0; i < document.styleSheets.length; i++) {
      const styleSheet = document.styleSheets[i];

      // Will ONLY skip stylesheet that come from 3rd party service such as Bootstrap, fontawesome
      // and keep any inhouse styling such as app.scss(will be combine into styles.css file), styles.css
      if (styleSheet.href != null && !styleSheet.href.match(/\/styles.css/g)) {
        continue;
      }

      try {
        for (let j = 0; j < styleSheet.cssRules.length; j++) {
          const cssRule = styleSheet.cssRules[j];
          // only inject the design system styles (all of which start with `.ds-`)
          if (cssRule.cssText.match(/\.ds-/g)) {
            styles += `${cssRule.cssText}\n`;
          }
        }
      } catch (error) {
        console.info(error);
      }
    }
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    const pdfPages = document.getElementsByClassName('page');
    this.pdfService.requestPdf(pdfPages, this.journey.latestContent.name);

    this.pdfService.status.subscribe((status) => {
      if (status === 'idle') {
        this.progressDialog.cancelAction();
      }

      if (status === 'error') {
        this.progressDialog.cancelAction();
        this.errorDialog.showMessage(
          'Oops...there was an error generating your PDF.',
        );
      }
    });
  }

  getNewPageTitle(messagePreview) {
    const journeyTitle = this.journeyContent.name;
    if (
      messagePreview.type === 'APITrigger' &&
      (messagePreview.nickname || messagePreview.client_trigger_id)
    ) {
      return `${journeyTitle} - ${
        messagePreview.nickname || messagePreview.client_trigger_id
      }`;
    }
    if (messagePreview.type === 'WorkflowDelayer') {
      return `${journeyTitle} - ${messagePreview.interval_value} ${
        messagePreview.interval_type
      }${messagePreview.interval_value > 1 ? 's' : ''} later`;
    }
    return journeyTitle;
  }

  actionDetailText(action): string | void {
    if (action.type === 'email') {
      return `"${action.label}" sends an email to ${action.value}`;
    }
    if (action.type === 'call') {
      return `"${action.label}" places a call to ${action.value}`;
    }
    if (action.type === 'hyperlink') {
      return `"${action.label}" sends user to <a href="${action.value}">${action.value}</a>`;
    }
    if (action.type === 'message') {
      return `"${action.label}" sends user to message ${this.matchingMessage(
        action,
      )}`;
    }
    if (action.type === 'consent_upgrade') {
      return `"${action.label}" is an in wire consent upgrade form.`;
    }
    if (action.type === 'collapsible') {
      return `"${action.label}" is a collapsible content area.`;
    }
    if (action.type === 'form_info_capture') {
      return `"${action.label}" opens this form: <a href="/powerupsform/${action.value}">${action.value}</a>`;
    }
    if (action.type === 'disclaimer' && action.show_disclaimer) {
      return 'Disclaimer message.';
    }
  }

  twoWayActionDetailText(action): Observable<string> {
    if (!this.twoWayDescText$[action.id]) {
      this.twoWayDescText$[action.id] = this.controlTagsService
        .getTag(action.value)
        .pipe(
          flatMap((tag) => {
            return of(
              `"${action.label}" initiates a conversation tagged "${tag.tag_name}"`,
            );
          }),
        )
        .pipe(
          catchError((err) => {
            return of(`"${action.label}" initiates a conversation`);
          }),
        );
    }

    return this.twoWayDescText$[action.id];
  }

  matchingMessage(action) {
    const matchingMessage = this.journeyContent.components.filter(
      (component) => {
        return component.name === action.value;
      },
    )[0];

    return matchingMessage ? matchingMessage.step : 'unknown';
  }

  /* 
    All possible message types: 
   'APITrigger', 'WorkflowDelayer', 'WorkflowEnder', 'MessageSender', 'MessageResponse'
  */
  isDisplayableMessageComponent(message: MessageClass | Action) {
    if (message instanceof Action && message.type === 'form_info_capture') {
      return true;
    } else if (message instanceof MessageClass) {
      return (
        (message.type === 'MessageSender' ||
          message.type === 'MessageResponse') &&
        (message.sms.is_shown === 'true' || message.wire.is_shown === 'true')
      );
    }
  }

  isDisplayableWireMessage(message: MessageClass | Action) {
    if (message instanceof Action && message.type === 'form_info_capture') {
      return true;
    } else if (message instanceof MessageClass) {
      return (
        (message.type === 'MessageSender' ||
          message.type === 'MessageResponse') &&
        message.wire.is_shown === 'true'
      );
    }
  }

  isSmsMessage(message: MessageClass) {
    return message.type === 'MessageSender' && message.sms.is_shown === 'true';
  }

  get pages(): PdfPage[] {
    return this._pages;
  }

  generatePages(): PdfPage[] {
    if (_.isEmpty(this.journey)) {
      return [];
    }

    const pdfPages: PdfPage[] = [];
    let pageNumber = 0;
    let pageTitle = '';

    for (let i = 0; i < this.messagePreviews.length; i++) {
      const currentMessagePreview = this.messagePreviews[i];
      const messageActions =
        (currentMessagePreview.formActions &&
          currentMessagePreview.formActions()) ||
        [];

      const allPhones = [
        currentMessagePreview,
        ...messageActions.map((item) => new Action(item)),
      ];

      let currentPage = pdfPages[pageNumber];

      if (!this.isDisplayableMessageComponent(currentMessagePreview)) {
        pageTitle = this.getNewPageTitle(currentMessagePreview);
      }

      allPhones.forEach((item) => {
        if (this.isDisplayableMessageComponent(item)) {
          if (
            pdfPages.length > 0 &&
            (currentPage.isFull() || item.type === 'MessageSender')
          ) {
            pageNumber += 1;
          }

          if (!pdfPages[pageNumber]) {
            currentPage = pdfPages[pageNumber] = new PdfPage(pageTitle);
          }
          pdfPages[pageNumber].addMessagePreview(item);
        }
      });
    }

    return pdfPages;
  }

  /*
    1. came to this via trial and error - without it you get a blank page at the end
   */
  documentHeight() {
    if (!this._pages) {
      return;
    }
    const pageHeight = 8 * 1.5;
    return `${this._pages.length * pageHeight}in`;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      document.designMode = 'on';
    } else {
      document.designMode = 'off';
    }
  }

  close() {
    window.close();
  }

  closeDialog() {
    this.errorDialog.cancelAction();
  }
}
