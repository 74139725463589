import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import React from 'react';
import ReactDOM from 'react-dom';
import { TwoWayMessagingContainer } from '@app/two-way/relay-messenger/components/relay-messenger-container/RelayMessengerContainer';
import { ActivatedRoute } from '@angular/router';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

export type RelayMessengerFeatureFlag = {
  flagName: string;
  flagValue: boolean;
};

@Component({
  selector: 'app-two-way-messaging',
  templateUrl: './relay-messenger.component.html',
  styleUrls: ['./relay-messenger.component.scss'],
})
export class RelayMessengerComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  clientId: string = '';
  userId: string = '';
  userRoleIds: string[] = [];
  reactWrapperId: string = '';
  relayMessengerR2FeatureFlag: boolean = false;
  featureFlags = FeatureFlags;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    public featureService: FeatureService,
  ) {}

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnInit(): void {
    this.titleService.activate('Relay Messenger');
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.userId = this.sessionService.currentUser.id;
    this.userRoleIds = this.sessionService.currentUser.role_id;
    this.reactWrapperId = 'relay-messenger-wrapper-id';
    this.relayMessengerR2FeatureFlag = this.featureService.checkFlag(
      this.featureFlags.relay_messenger_r2,
    );
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
    this.titleService.deactivate();
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  protected getRootDomNode(): HTMLElement {
    return document.getElementById(this.reactWrapperId);
  }

  protected render() {
    // remove when featureFlags are not needed
    const featureFlags: RelayMessengerFeatureFlag[] = [
      {
        flagName: 'relay_messenger_r2',
        flagValue: this.relayMessengerR2FeatureFlag,
      },
    ];
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(TwoWayMessagingContainer, {
          clientId: this.clientId,
          userId: this.userId,
          userRoleIds: this.userRoleIds,
          channelSid: this.route.snapshot.queryParamMap.get('chat') ?? null,
          featureFlags,
        }),
        this.getRootDomNode(),
      );
    }
  }
}
