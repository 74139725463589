<div class="content">
  <h1>Relay Network Terms of Service and User Agreement</h1>
  <p>
    By using the services of Relay Network LLC, you are concluding a legally
    binding agreement on the terms set out in this Relay Network Terms of
    Service and User Agreement (&quot;User Agreement&quot;).
  </p>
  <h2>1. INTRODUCTION</h2>
  <p>
    Relay Network LLC (&quot;Relay,&quot; &quot;we&quot; or &quot;us&quot;),
    located at 201 King of Prussia Road, Suite 161, Radnor, Pennsylvania, 19087,
    USA, operates the Relay Network (&quot;Relay Network&quot;), a system that
    enables mobile phone users to efficiently, effectively and conveniently
    manage their various business relationships, by means of their mobile
    devices and online, with companies that are partners with the Relay Network
    and with which those users have an ongoing business relationship
    (&quot;Relay Partners&quot;). Relay Partners include but are not limited to
    credit card issuers, utility companies, retail companies, telecommunications
    and cable companies, and insurance companies. To offer our products and help
    you manage your electronic communications, we make services available
    through our Web-based communications platform (the &quot;Relay Wire&quot; or
    &quot;Wire&quot;), mobile applications, email, Relay Partner channels and
    other commercially available communications channels (the
    &quot;Services&quot;).
  </p>
  <p>
    As used in this User Agreement, the terms "Relay Website" or "Relay
    Websites" include the website from which the Services are provided, the
    websites located at
    <a target="_blank" href="https://relaynetwork.com">relaynetwork.com</a> and
    <a target="_blank" href="https://my.relayit.com">my.relayit.com</a>, and any
    other website that is owned or operated by Relay.
  </p>
  <h2>2. WHAT YOU'RE AGREEING TO</h2>
  <p>
    By using the Relay Services, or by using our website, our mobile
    applications, or other services provided by the Relay Network, you are
    entering a binding agreement with Relay based on the terms of this User
    Agreement and the Relay Network Privacy Policy, which is hereby incorporated
    into this User Agreement by reference. As a Relay Network user
    (&quot;User&quot;), you are agreeing to be provided the Services offered by
    the Relay Network, including receipt of Partner Communications via the Relay
    Wire. &quot;Partner Communications,&quot; as used in this User Agreement,
    shall mean any communication between you and a Relay Partner through the
    Relay platform or use of the Services.
  </p>
  <p>
    Relay is not responsible for and does not endorse any Partner
    Communications, features, content, advertising, products, or other materials
    made available to you via the Services from Relay Partners. Your use of the
    Services, on the Wire, at the Relay website or by other means, enables Relay
    Partners to send you Partner Communications and text messages via the Relay
    Network&#39;s Services. You are agreeing that your contractual or other
    relationship with any Relay Partner is separate and apart from this User
    Agreement.
  </p>
  <p>
    By becoming a User, you acknowledge that you have read and understood the
    terms and conditions of this User Agreement and that you agree to be bound
    by all of its provisions. If you do not want to become a User, do not
    conclude the User Agreement, do not use the Services, and do not access,
    view, download, or otherwise use any Relay Network Website or information.
    If you are using the Relay Services on behalf of a company or other legal
    entity, such entity may have a separate agreement with us, but you are
    nevertheless individually bound by this User Agreement.
  </p>
  <h2>3. YOUR OBLIGATIONS</h2>
  <ol type="A">
    <li>
      <p>
        Applicable Laws and this User Agreement. You must comply with all
        applicable laws and with the User Agreement, as this User Agreement may
        be amended from time to time with or without advance notice, and the
        policies and processes explained in the subsequent sections of this User
        Agreement. You also must comply with the DOs and DONTs set out below at
        section 11 of this User Agreement, and with Relay&#39;s Privacy Policy
        at
        <a target="_blank" href="https://my.relayit.com/privacy-policy"
          >https://my.relayit.com/privacy-policy</a
        >.
      </p>
    </li>
    <li>
      <p>
        License and Warranty for Your Submissions to Relay Network. While you
        may at any time request the deletion of information you provided to
        Relay, your provision of information grants Relay a nonexclusive,
        irrevocable, worldwide, perpetual, unlimited, assignable, sublicensable,
        fully paid up and royalty-free right to use any information you provide,
        directly or indirectly, to Relay, solely for the purpose of performing
        Services for the Relay Partner related to your information to the extent
        permitted under this user agreement, applicable law and contracts
        between Relay and its partners.
      </p>
      <p>
        By providing information to us, you represent and warrant that you are
        entitled to submit the information and that the information is accurate,
        not confidential, and not in violation of any contractual restrictions
        or other third party rights. It is your responsibility to keep your
        Relay Network profile information accurate and updated.
      </p>
    </li>
    <li>
      <p>
        Service Eligibility. To be eligible to use the Service, you must meet
        the following criteria and represent and warrant that you: (1) are 18
        years of age or older; (2) are not currently restricted from the
        Services, or not otherwise prohibited from having a Relay Network
        account; (3) are not a competitor of Relay and are not using the
        Services for reasons that are in competition with Relay Network; (4)
        have full power and authority to enter into this User Agreement and
        doing so will not violate any other agreement to which you are a party;
        (5) will not violate any rights of Relay, including intellectual
        property rights such as copyright and trademark rights; and (6) agree to
        provide at your cost all equipment, software and Internet access
        necessary to use the Services.
      </p>
    </li>
    <li>
      <p>
        Sign-In Credentials. You agree to: (1) keep your password secure and
        confidential; (2) not permit others to use your account except as
        authorized by contract; (3) refrain from using other Users&#39;
        accounts; (4) refrain from selling, trading, or otherwise transferring
        your Relay Network account to another party; and (5) refrain from
        charging anyone for access to any portion of Relay Network, or any
        information therein. Further, you are responsible for your activity in
        your account until you close down your account including failure to keep
        your password confidential or any access permitted by you to your
        account. To close your account, you may click here:
        <a href="mailto:support@relayit.com">support@relayit.com</a>.
      </p>
    </li>
    <li>
      <p>
        Indemnification. You indemnify us and hold us harmless for all damages,
        losses, and costs (including, but not limited to, reasonable
        attorneys&#39; fees and costs) related to all third party claims,
        charges, and investigations caused by: (1) your failure to comply with
        this User Agreement, including, without limitation, your submission of
        content that violates third party rights or applicable laws; (2) any
        content you submit to the Services that is in violation of Relay Network
        policy or this user agreement and ; and (3) any impermissible activity
        in which you engage on or through Relay Network; provided that the third
        party fees and costs are not result of Relay’s breach of its obligations
        under applicable law or its agreement with you or the Relay Network
        Partner.
      </p>
    </li>
    <li>
      <p>
        Notification of Acts Contrary to the User Agreement. If you believe that
        you are entitled or obligated to act contrary to this User Agreement
        under any mandatory law, you agree to provide us with detailed and
        substantiated explanation of your reasons in writing at least 30 days
        before you act contrary to the User Agreement, to allow us to assess
        whether we may, at our sole discretion, provide an alternative remedy
        for the situation, although we are under no obligation to do so.
      </p>
    </li>
    <li>
      <p>
        Notifications and Service Messages. For purposes of service messages and
        notices about the Services to you, notice shall consist of any short
        message service (&quot;SMS&quot;) text message from Relay Network to the
        mobile phone number associated with your account, even if we have other
        contact information. You also agree that Relay may communicate with you
        through your Relay Network account or through other means including
        email, mobile number (via voice or text message), telephone, or delivery
        services including the U.S. Postal Service about your Relay Network
        account or services associated with Relay Network solely in association
        with Relay Network’s provision of Services to you. You acknowledge and
        agree that we shall have no liability associated with or arising from
        your failure to maintain accurate contact or other information,
        including, but not limited to, your failure to receive critical
        information about the Service due to your provision of inaccurate
        contact information.
      </p>
    </li>
    <li>
      <p>
        Mobile Services. Relay may offer the Services through mobile
        applications created by Relay or third party developers (&quot;Platform
        Developers&quot;). If you use the Services through a mobile device, you
        agree that information about your use of the Services through your
        mobile device and carrier may be communicated to us, including but not
        limited to your mobile carrier, your mobile device, or your physical
        location. In addition, use of the Services through a mobile device may
        cause data to be displayed on and through your mobile device. By
        accessing the Services through a mobile device, you represent that to
        the extent you import any of your Relay Network data to your mobile
        device, you have authority to share the transferred data with your
        mobile carrier or other access provider. In the event you change or
        deactivate your mobile account, you must promptly update your Relay
        Network account information to ensure that your messages are not sent to
        the person that acquires your old number, and failure to do so is your
        responsibility. You acknowledge that you are responsible for all charges
        and necessary permission related to accessing Relay Network through your
        mobile access provider. Therefore, you should check with your provider
        to find out if the Services are available, and to familiarize yourself
        with the terms of service for your specific mobile devices.
      </p>
    </li>
    <li>
      <p>
        Third Parties. Relay may include on a Relay Website links to third party
        web sites (&quot;Third Party Sites&quot;), such as websites operated by
        Relay Partners. You are responsible for evaluating whether you want to
        access or use a Third Party Site. You should review any applicable terms
        and/or privacy policy of a Third Party Site before using it or sharing
        any information with it, because those terms or policies might permit
        the operator of a Third Party Site to use your data in ways we would
        not.
      </p>
    </li>
    <li>
      <p>
        Privacy. You should carefully read our full Privacy Policy (available at
        <a target="_blank" href="https://my.relayit.com/privacy-policy"
          >https://my.relayit.com/privacy-policy</a
        >) before deciding to become a User. The Privacy Policy governs our
        treatment of any information, including personally identifiable
        information, you submit to us. Please note that certain information,
        statements, data and content (such as photographs) that you may submit
        to Relay Network might reveal your gender, ethnic origin, nationality,
        age, religion, sexual orientation or other personal information about
        you. You acknowledge that your submission of any information,
        statements, data, and content to us is voluntary on your part.
      </p>
    </li>
    <li>
      <p>
        Export Control. Your use of Relay Network services, including our
        software, is subject to export and re-export control laws and
        regulations, including the Export Administration Regulations maintained
        by the Department of Commerce and sanctions programs maintained by the
        Treasury Department&#39;s Office of Foreign Assets Control. You shall
        not — directly or indirectly — sell, export, re-export, transfer,
        divert, or otherwise dispose of any software or service to any end user
        without obtaining the required authorizations from the appropriate
        government authorities. You also warrant that you are not prohibited
        from receiving U.S. origin products, including services or software.
      </p>
    </li>
  </ol>
  <h2>4. YOUR RIGHTS</h2>
  <p>
    On the condition that you comply with your obligations under the User
    Agreement, we grant you a limited, revocable, nonexclusive, non-assignable,
    non-sublicensable right to use the Services that we provide on Relay Website
    web pages, as well as to access (through a generally available web browser,
    mobile device or application) and view information, all in accordance with
    this User Agreement. You are not permitted to access any Relay service by
    means of scraping, crawling or other technologies used to access data
    without the express written consent of Relay. Any other use of Relay Network
    facilities or services contrary to a legitimate purpose (such as seeking to
    use information gathered from Relay Network commercially unless expressly
    authorized by Relay) is strictly prohibited and a violation of this User
    Agreement. We reserve all rights not expressly granted in this User
    Agreement, including, without limitation, title, ownership, intellectual
    property rights, and all other rights and interests in Relay Network and all
    related items.
  </p>
  <h2>5. OUR RIGHTS AND OBLIGATIONS</h2>
  <ol type="A">
    <li>
      <p>
        Services Availability. We allow you to access Relay Network as it may
        exist and be available on any given day and have no other obligations,
        except as expressly stated in this User Agreement. We may modify,
        replace, refuse access to, suspend or discontinue Relay Network,
        partially or entirely, or change and modify prices for all or part of
        the Services in our sole discretion. All of these changes shall be
        effective upon their posting on a Relay Website or by direct
        communication to you unless otherwise noted. Relay further reserves the
        right to withhold, remove, and/or discard any content available as part
        of your Relay Network account, with or without notice, if deemed by
        Relay to be contrary to this User Agreement. For avoidance of doubt,
        Relay has no obligation to store, maintain, or provide you with a copy
        of any content that your or other Users provide when using the Services.
      </p>
    </li>
    <li>
      <p>
        Disclosure of User Information. You acknowledge, consent and agree that
        we many access, preserve and disclose your registration and any other
        information you provide if required to do so by law (confidential
        information is maintained for a minimum of six years) or in a good faith
        belief that such access, preservation or disclosure is reasonably
        necessary in our opinion to : (1) provide Services under the Relay
        Network; (2) comply with legal process, including but not limited to
        civil and criminal subpoenas, court orders or other compulsory
        procedures; (3) enforce this User Agreement; (4) respond to claims of a
        violation of the rights of third parties, whether or not the third party
        is a User, individual or government agency; (5) respond to customer
        service inquiries by you; or (6) protect the rights, property, or
        personal safety of Relay, our Users or the public.
      </p>
    </li>
  </ol>
  <h2>6. DISCLAIMER</h2>
  <p>
    WE PROVIDE THE PLATFORM FOR RELAY NETWORK AND ALL INFORMATION AND SERVICES
    ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. WE DO NOT
    PROVIDE ANY EXPRESS WARRANTIES OR REPRESENTATIONS.
  </p>
  <p>
    TO THE FULLEST EXTENT PERMISSIBLE UNDER LAW, WE DISCLAIM ANY AND ALL IMPLIED
    WARRANTIES AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, ANY
    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
    NONINFRINGEMENT. IF YOU ARE DISSATISFIED OR HARMED BY THE RELAY NETWORK OR
    ANYTHING RELATED TO THE RELAY NETWORK, YOU MAY CLOSE YOUR RELAY NETWORK
    ACCOUNT AND TERMINATE THIS USER AGREEMENT IN ACCORDANCE WITH SECTION 8
    (&quot;TERMINATION&quot;) AND SUCH TERMINATION SHALL BE YOUR SOLE AND
    EXCLUSIVE REMEDY.
  </p>
  <p>
    RELAY IS RESPONSIBLE FOR SENDING MESSAGES TO YOU AS DIRECTED BY OUR RELAY
    PARTNER. WE CANNOT GUARANTEE ALL MESSAGES WE SEND WILL BE RECEIVED BY YOU
    AND WE ARE NOT LIABLE FOR THIRD PARTY SYSTEMS INCLUDING INTERNET AND
    CELLULAR SERVICES REQUIRED FOR RECEIPT OF MESSAGES OR FOR THE FAILURE OF
    YOUR DEVICE. -. IN ADDITION, WE NEITHER WARRANT NOR REPRESENT THAT YOUR USE
    OF THE SERVICE WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES. ANY MATERIAL,
    SERVICE OR TECHNOLOGY DESCRIBED OR USED ON A RELAY WEBSITE OR OTHER
    COMMUNICATION CHANNEL OPERATED BY RELAY MAY BE SUBJECT TO INTELLECTUAL
    PROPERTY RIGHTS HELD BY THIRD PARTIES WHO HAVE LICENSED SUCH MATERIAL TO US.
  </p>
  <p>
    RELAY DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS
    USING THE RELAY NETWORK OR SUBSCRIBING TO ITS SERVICES, NOR DOES RELAY HAVE
    ANY OBLIGATION TO MONITOR THE USE OF ITS SERVICES BY USERS OR OTHERS. RELAY
    THEREFORE DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER MISUSE OF
    YOUR IDENTITY OR INFORMATION UNLESS IT IS THE FAULT OF RELAY.
  </p>
  <p>
    RELAY DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT
    INTERRUPTION OR ERROR. IN PARTICULAR, THE SERVICES MAY BE INTERRUPTED BY
    MAINTENANCE, UPDATES, OR SYSTEM OR NETWORK FAILURES. RELAY DISCLAIMS ALL
    LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN
    FUNCTIONING. FURTHERMORE, RELAY DISCLAIMS ALL LIABILITY FOR INABILITY TO
    ACCESS, OR POOR USE CONDITIONS OF THE RELAY WEBSITES OR SERVICES DUE TO
    INAPPRORIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS
    OR OTHER COMMUNICATION SERVICE PROVIDERS, NETWORK SATURATION, AND FOR ANY
    OTHER REASON.
  </p>
  <h2>7. LIMITATION OF LIABILITY</h2>
  <p>
    Neither Relay nor any of our subsidiaries, affiliated companies, employees,
    shareholders or directors (collectively &quot;Relay Affiliates&quot;) shall
    be liable for: (a) any damages in excess of the total of all fees paid by
    you to Relay, if any, or US $10,000, whichever amount is greater; or (b) any
    special, incidental, punitive or consequential damages or loss of use,
    profit, revenue or data to you or any third party arising from your use of
    the Service, any platform applications or any of the content or other
    materials on, accessed through or downloaded from Relay Network. This
    limitation of liability shall:
  </p>
  <ol type="A">
    <li>
      <p>
        Apply regardless of whether (1) you base your claim upon contract, tort,
        statute, or any other legal theory; (2) we knew or should have known
        about the possibility of such damages; or (3) the limited remedies
        provided in this section fail of their essential purpose.; and
      </p>
    </li>
    <li>
      <p>
        Not apply to any damage that Relay Network may cause you intentionally
        or knowingly in violation of this User Agreement or applicable law, or
        otherwise mandated by applicable law, that cannot lawfully be disclaimed
        from this User Agreement.
      </p>
    </li>
  </ol>
  <h2>8. TERMINATION</h2>
  <ol type="A">
    <li>
      <p>
        Mutual Rights of Termination. You may terminate this User Agreement, for
        any or no reason, at any time, with notice to Relay. This notice will be
        effective upon Relay&#39;s processing of your notice. Relay may
        terminate the User Agreement for any or no reason, at any time, with or
        without notice. Such termination shall be effective immediately or as
        specified in the notice. Termination of your Relay Network account
        includes disabling your access to the Relay Network and also may include
        barring you from any future use of the Relay Network.
      </p>
    </li>
    <li>
      <p>
        Misuse of the Services. Relay may restrict, suspend or terminate the
        account of any User who abuses or misuses the Services. Misuse of the
        Services includes inviting other Users you do not know to connect;
        abusing the Relay Network messaging services; creating multiple or false
        profiles; using the Services commercially without Relay&#39;s
        authorization; infringing intellectual property rights; or any other
        behavior that Relay, in its sole discretion, deems contrary to its
        purpose. In addition, and without limiting the foregoing, Relay has
        adopted a policy of terminating Users who, in Relay&#39;s sole judgment,
        are deemed to be repeat infringers under the United States Copyright
        Act.
      </p>
    </li>
    <li>
      <p>
        Effect of Termination. Upon termination of your Relay Network account,
        you will lose access to the Services. In addition, Relay may block
        access to the Services from an IP address or range of IP addresses
        associated with those of terminated Users. The terms of this User
        Agreement shall survive any termination, except Sections 3 (&quot;Your
        Rights&quot;) and 5 (&quot;Our Rights and Obligations&quot;) hereof.
      </p>
    </li>
  </ol>
  <h2>9. PENNSYLVANIA LAW AND ARBITRATION</h2>
  <ol type="A">
    <li>
      <p>
        Choice of Law. Except for any disputes related to intellectual property
        rights, obligations or any infringement claims, any disputes with Relay
        Network arising out of or relating to the User Agreement
        (&quot;Disputes&quot;) shall be governed by Pennsylvania law regardless
        of your country of origin or where you access Relay Network, and
        notwithstanding any conflicts of law principles and the United Nations
        Convention for the International Sale of Goods.
      </p>
    </li>
    <li>
      <p>
        Agreement to Arbitrate and Pay Attorneys&#39; Fees. Any Disputes shall
        be resolved by final and binding arbitration under the rules and
        auspices of the American Arbitration Association, to be held in Delaware
        County, Pennsylvania, in English, with a written decision stating
        findings and legal reasoning issued by the arbitrator(s) at either
        party&#39;s request, and with arbitration costs and reasonable
        documented attorneys&#39; fees of both parties to be borne by the party
        against whom the ultimate decision issues.
      </p>
    </li>
    <li>
      <p>
        Exception from Arbitration Agreement. Either party may obtain injunctive
        relief (preliminary or permanent) and orders to compel arbitration or
        enforce arbitral awards in any court of competent jurisdiction.
      </p>
    </li>
    <li>
      <p>
        Refundable Fee Advances for Consumers. If you are involved in a Dispute
        as a consumer without any commercial interests related to the Dispute,
        we will agree to conduct the arbitration proceeding in a major city in
        your state if travel to Pennsylvania would constitute an undue burden
        for you, and we will advance any arbitration fees that exceed the amount
        you would be required to pay for court proceedings (if you substantiate
        and represent to us in a written statement the cost of such court
        proceedings) provided that you shall refund such amounts if we
        ultimately prevail in the arbitration.
      </p>
    </li>
  </ol>
  <h2>10. GENERAL TERMS</h2>
  <ol type="A">
    <li>
      <p>
        Severability. If any provision of this User Agreement is found by a
        court of competent jurisdiction or arbitrator to be illegal, void, or
        unenforceable, the unenforceable provision will be modified so as to
        render it enforceable and effective to the maximum extent possible in
        order to effect the intent of the provision; and if a court or
        arbitrator finds the modified provision invalid, illegal, void or
        unenforceable, the validity, legality and enforceability of the
        remaining provisions of this User Agreement will not be affected in any
        way.
      </p>
    </li>
    <li>
      <p>
        Language. Where Relay has provided you with a translation of the English
        language version of the User Agreement, the Privacy Policy and/or any
        other documentation, you agree that the translation is provided for your
        convenience only and that the English language version of the
        documentation will govern your relationship with Relay.
      </p>
    </li>
    <li>
      <p>
        Notices and Service of Process. In addition to Section 2.G
        (&quot;Notifications and Service Messages&quot;), we may notify you via
        postings to the Relay Wire, or at the Relay Website at
        <a target="_blank" href="https://my.relayit.com"
          >https://my.relayit.com</a
        >. You may contact us via email at
        <a href="mailto:support@relayit.com">support@relayit.com</a> or via mail
        or courier at: Relay Network LLC, ATTN Legal Department, 201 King of
        Prussia Road, Suite 161, Radnor, PA, 19087, USA. Additionally, Relay
        accepts service of process at the above address. Any notices that you
        provide that do not comply with this section on Notices and Service of
        Process shall have no legal effect.
      </p>
    </li>
    <li>
      <p>
        Entire Agreement. You agree that this User Agreement constitutes the
        entire, complete and exclusive agreement between you and us regarding
        the Services and supersedes all prior agreements and understandings,
        whether written or oral, or whether established by custom, practice,
        policy or precedent, with respect to the subject matter of this User
        Agreement. You also may be subject to additional terms and conditions
        that may apply when you use or purchase other Relay Network services,
        third-party content or third-party software.
      </p>
    </li>
    <li>
      <p>
        Amendments to the User Agreement. We reserve the right to modify,
        supplement or replace the terms of the User Agreement, effective upon
        posting at
        <a target="_blank" href="https://my.relayit.com"
          >https://my.relayit.com</a
        >
        or upon other notification to you provided that such changes will not
        materially affect your rights unless we give you notice. If you do not
        want to agree to changes to the User Agreement, you can terminate the
        User Agreement at any time per Section 8 (&quot;Termination&quot;).
      </p>
    </li>
    <li>
      <p>
        No Informal Waivers, Agreements or Representations. Our failure to act
        with respect to a breach of this User Agreement by you or others does
        not waive our right to act with respect to that breach or subsequent
        similar or other breaches. Except as expressly and specifically
        contemplated by the User Agreement, no representations, statements,
        consent, waivers or other acts or omissions by Relay or any Relay
        Affiliate shall be deemed legally binding on any Relay Affiliate, unless
        documented in a physical writing hand signed by a duly appointed officer
        of Relay.
      </p>
    </li>
    <li>
      <p>
        No Injunctive Relief. In no event may you seek or be entitled to
        rescission, injunctive or other equitable relief, or to enjoin or
        restrain the operation of the Services, exploitation of any advertising
        or other materials issued in conjunction therewith, or exploitation of
        the Services or any content or other material used or displayed through
        the Services.
      </p>
    </li>
    <li>
      <p>
        Assignment and Delegation. You may not assign or delegate any rights or
        obligations under the Service Agreement. Any purported assignment or
        delegation shall be ineffective. We may freely assign or delegate all
        rights and obligations under the User Agreement, fully or partially,
        without notice to you. We may also substitute, by way of unilateral
        novation, effective upon notice to you, Relay Network LLC for any third
        party that assumes our rights and obligations under this User Agreement.
      </p>
    </li>
    <li data-list-text="I.">
      <p>
        Message Notifications sent via SMS. Message Frequency Varies. Message
        and Data Rates May Apply. For assistance, you may contact us via email
        at
        <a href="mailto:support@relayit.com"> support@relayit.com </a>
        . You may Opt-Out by replying STOP to any SMS Notification received.
        Relay Network supports the following carriers: Verizon Wireless;
        T-Mobile; Metro PCS; Sprint; AT&amp;T; Virgin Mobile; US Cellular;
        Interop; nTelos; C-Spire; Carolina West Wireless; Cellcom; ClearSky; and
        Google Voice. Carriers are not liable for delayed or undelivered
        messages.
      </p>
    </li>
  </ol>
  <h2>11. RELAY NETWORK "DOs AND DON'Ts"</h2>
  <p>
    As a condition of your access to the Relay Network, you agree to this User
    Agreement and to strictly observe the following Dos and Don&#39;ts:
  </p>
  <ol type="A">
    <li>
      <p>
        Do the following: (1) comply with all applicable laws, including,
        without limitation, privacy laws, intellectual property laws, export
        control laws, tax laws and regulatory requirements; (2) provide accurate
        information to us and update it as necessary; (3) review and comply with
        our Privacy Policy; and (4) review and comply with notices sent by Relay
        concerning the Services.
      </p>
    </li>
    <li>
      <p>
        Don&#39;t do the following: (1) Act dishonestly or unprofessionally by
        engaging in unprofessional behavior by posting inappropriate,
        inaccurate, or objectional content to the Relay Network. (2) Duplicate,
        license, sublicense, publish, broadcast, transmit, distribute, perform,
        display, sell, rebrand, or otherwise transfer information found on the
        Relay Network (excluding content posted by you) except as permitted in
        this User Agreement or as expressly authorized by Relay. (3) Reverse
        engineer, decompile, disassemble, decipher or otherwise attempt to
        derive the source code for any underlying intellectual property used to
        provide the Services, or any part thereof. (4) Utilize information,
        content or any data you view on and/or obtain from the Relay Network to
        provide any service that is competitive, as determined in Relay&#39;s
        sole judgment and discretion, with the Relay Network. (5) Imply or
        state, directly or indirectly, that you are affiliated with the Relay
        Network or endorsed by Relay unless you have entered into a written
        agreement to that effect with Relay. (6) Adapt, modify or create
        derivative works based on the Relay Network or technology underlying the
        Services, or other Users&#39; content, in whole or in part, except as
        permitted under Relay&#39;s Developer Program. (7) Rent, lease, lend,
        trade, sell/re-sell access to the Relay Network or any information
        therein, or the equivalent, in whole or in part. (8) Deep-link to a
        Relay Website for any purpose, including a link to a Relay Website
        webpage other than the Relay Website&#39;s homepage, unless expressly
        authorized in writing by Relay. (9) Use manual or automated software,
        devices, scripts, robots, or other means or process to access,
        &quot;scrape,&quot; &quot;crawl&quot; or &quot;spider&quot; any web
        pages or other services contained in a Relay Website. (10) Access the
        Relay Network, via automated or manual means or processes, for purposes
        of monitoring its availability, performance or functionality or for any
        competitive purpose. (11) Engage in &quot;framing,&quot;
        &quot;mirroring,&quot; or otherwise simulating the appearance and/or
        function of a Relay Website. (12) Access or attempt to access the Relay
        Network by any means other than through the interfaces provided by the
        Relay Network for that purpose. (13) Override or attempt to override any
        security component included in or underlying the Relay Network. (14)
        Engage in any action that directly or indirectly interferes with the
        proper working of or places an unreasonable load on our infrastructure,
        including but not limited to unsolicited communications to other Users
        or Relay Network personnel, attempts to gain unauthorized access, or
        transmission or activation of computer viruses. (15) Remove any
        copyright, trademark, or other proprietary rights notices contained in
        or on the Relay Network, including those of both Relay and any of its
        licensors or licensees. (16) Remove, cover or otherwise obscure any form
        of advertisement included on the Relay Network. (17) Harass, abuse or
        harm another person, including sending unwanted communications to others
        using the Relay Network. (18) Collect, use or transfer any information,
        including but not limited to personally identifiable information
        obtained from Relay except as expressly permitted by this User Agreement
        or as the owner of such information may expressly permit. (19) Interfere
        with or disrupt the Relay Network, including but not limited to any
        server or networks connected to the Relay Network. (20) Use or attempt
        to use another&#39;s account without authorization from Relay, or create
        a false identity on the Relay Network. (21) Infringe or use Relay&#39;s
        brand, logos and/or trademarks, including, without limitation, using the
        words &quot;Relay Network&quot; in any business name, email, or URL, or
        including trademarks and logos used by the Relay Network except as
        expressly permitted by Relay. (22) Participate, directly or indirectly,
        in the setting up or development of a network that seeks to implement
        practices that are similar to sales by network or the recruitment of
        independent home salespeople for the purposes of creating a pyramid
        scheme or other similar practices.
      </p>
    </li>
  </ol>
  <p>Relay Network LLC, Radnor, Pennsylvania, USA.</p>
  <p>Last modified December 23, 2023.</p>
</div>
