import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { Client, Contact } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { FormatUtils } from '@app/core/utils/format-utils';
import { TitleService } from '@app/core/services/title.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureService } from '@app/core/services/feature.service';
import { CompanyType, Industry } from '@app/core/models/categorization-types';
import { SessionService } from '@app/security/session.service';
import {
  CategorizationService,
  CategoryAncestryDetails,
} from '@app/core/services/categorization.service';
import { forkJoin } from 'rxjs';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-client-edit-info',
  templateUrl: './client-info-edit.component.html',
  styleUrls: ['./client-info-edit.component.scss'],
})
export class ClientInfoEditComponent implements OnDestroy, OnInit {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @ViewChild('toggleOnFeedWarning', { static: true })
  toggleOnFeedWarningDialog: MessageDialogComponent;
  @ViewChild('toggleOffFeedWarning', { static: true })
  toggleOffFeedWarningDialog: MessageDialogComponent;

  currentClient: Client;
  client: Client;
  clientInfoValidGroup: UntypedFormGroup;
  headerGroups = { clientID: true, companyInformation: true };
  industries: Industry[] = [];
  allCompanyTypes: CompanyType[] = [];
  relevantCompanyTypes: CompanyType[] = [];
  featureFlags = FeatureFlags;

  constructor(
    private clientService: ClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private featureService: FeatureService,
    private sessionService: SessionService,
    private categorizationService: CategorizationService,
  ) {
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  get primaryContactGroup(): UntypedFormGroup {
    return this.clientInfoValidGroup.controls['primary_contact'] as UntypedFormGroup;
  }

  get industryCtl(): UntypedFormControl {
    return <UntypedFormControl>this.clientInfoValidGroup.controls['industry'];
  }

  get companyTypeCtl(): UntypedFormControl {
    return <UntypedFormControl>this.clientInfoValidGroup.controls['company_type'];
  }

  ngOnInit() {
    this.getClient();
  }

  initForm() {
    this.clientInfoValidGroup = new UntypedFormGroup({
      company_name: new UntypedFormControl(
        this.client.company_name,
        Validators.required,
      ),
      primary_contact: new UntypedFormGroup({
        first_name: new UntypedFormControl(
          this.client.primary_contact.first_name,
          Validators.required,
        ),
        last_name: new UntypedFormControl(
          this.client.primary_contact.last_name,
          Validators.required,
        ),
        email: new UntypedFormControl(this.client.primary_contact.email, [
          Validators.required,
          CustomValidators.emailToBeTrimmed,
        ]),
        mobile_number: new UntypedFormControl(
          this.client.primary_contact.mobile_number,
          CustomValidators.phoneNumber,
        ),
      }),
    });

    if (
      this.featureService.checkFlag(this.featureFlags.experience_library) ===
      true
    ) {
      this.clientInfoValidGroup.addControl(
        'industry',
        new UntypedFormControl(this.client.industry || ''),
      );
      this.clientInfoValidGroup.addControl(
        'company_type',
        new UntypedFormControl(this.client.company_type || ''),
      );

      this.industryCtl.valueChanges.subscribe((newIndustryId) => {
        this.setCompanyTypeOptions(newIndustryId);
        this.companyTypeCtl.setValue('');
      });
    }
  }

  /**
   * Return true if the feature flag is on for experience_library, and there is a
   * form Control set up for both industry and company type
   * @returns boolean
   */
  showIndustrySelect(): boolean {
    return (
      this.featureService.checkFlag(this.featureFlags.experience_library) ===
        true &&
      this.clientInfoValidGroup.controls['industry'] !== undefined &&
      this.clientInfoValidGroup.controls['company_type'] !== undefined
    );
  }

  /**
   * Hydrates the cannonical lists of industries and company types from the API.
   */
  initCategoryOptions(): void {
    forkJoin([
      this.categorizationService.getIndustries(this.currentClient.id),
      this.categorizationService.getCompanyTypes(this.currentClient.id),
    ]).subscribe(
      (responses) => {
        this.industries = responses[0].data;
        this.allCompanyTypes = responses[1].data;

        if (this.client.industry) {
          this.setCompanyTypeOptions(this.client.industry);
        }
      },
      () => {
        this.messageDialog.showMessage('Oops...Could not edit client');
      },
    );
  }

  /**
   * Update the list of company types to show to the user in a dropdown.
   *
   * @param industryId the ID of the industry that the company types must belong
   * to
   */
  setCompanyTypeOptions(industryId: string): void {
    if (industryId === '') {
      this.relevantCompanyTypes = [];
    } else {
      this.relevantCompanyTypes =
        this.categorizationService.getCompanyTypesForIndustryFullAncestorPath(
          this.allCompanyTypes,
          new CategoryAncestryDetails({ industryId }),
        );
    }
  }

  trimEmail() {
    const emailFormCtrl = this.primaryContactGroup.controls['email'];
    emailFormCtrl.setValue(emailFormCtrl.value.trim());
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  /**
   *
   * @param {string} headerGroup
   */
  toggleHeader(headerGroup: string) {
    if (this.headerGroups[headerGroup] !== undefined) {
      this.headerGroups[headerGroup] = !this.headerGroups[headerGroup];
    }
  }

  /**
   * Checks the phone number validation
   * formats phone number
   */
  formatPhoneNumber() {
    const mobileNumberCtl = this.primaryContactGroup.controls['mobile_number'];
    if (!mobileNumberCtl || !mobileNumberCtl.value) {
      return;
    }

    if (
      mobileNumberCtl.value.match(
        /^.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d?.*$/,
      )
    ) {
      mobileNumberCtl.setValue(
        FormatUtils.formatPhoneNumber(mobileNumberCtl.value),
      );
      this.changeDetectorRef.detectChanges();
    }
    CustomValidators.markAsUntouchedOnEmpty(mobileNumberCtl);
  }

  saveAndContinue(): void {
    // update client object with form data
    const formData = this.clientInfoValidGroup.getRawValue();
    this.client.company_name = formData.company_name;
    this.client.primary_contact = formData.primary_contact as Contact;
    this.client.industry = formData.industry;
    this.client.company_type = formData.company_type;
    
    this.clientService.updateClient(this.client).subscribe(
      (res) => this.onSuccessfulSave(res),
      (err: HttpErrorResponse) => this.onFailedSaved(err),
    );
  }

  /**
   * Returns the client's 'New Feed' setting
   * Defaults to 'false' if not previously set
   */
  get newFeedEnabled(): boolean {
    return this.client.feed_enabled;
  }

  /**
   * Called when client clicks the 'New Feed' switch or when user click "Cancel" on pop-up warning
   * Toggles 'off' to 'on' and vice versa
   * Case 1 "User clicks toggle" : The toggle switches and a pop-up warning appears to confirm if user want to update
   * Case 2 "User click Cancel on pop-up": the toggle switches back to its prior state before the user clicks the toggle.
   */
  toggleNewFeedEnabled(backToPriorState: boolean = false): void {
    if (backToPriorState) {
      this.client.feed_enabled = !this.client.feed_enabled;
    } else {
      this.client.feed_enabled = !this.client.feed_enabled;
      this.displayWarningToggleNewFeed(this.client.feed_enabled);
    }
  }

  /**
   * Called when user click Enable/Disable on toggle-feed pop-up
   */
  closeToggleFeedDialog() {
    this.toggleOffFeedWarningDialog.close();
    this.toggleOnFeedWarningDialog.close();
  }

  displayWarningToggleNewFeed(isFeedTurningOn: boolean): void {
    if (isFeedTurningOn) {
      this.toggleOnFeedWarningDialog.showMessage();
    } else {
      this.toggleOffFeedWarningDialog.showMessage();
    }
  }
  /**
   * Determines if the "New Feed" toggle is visible to the user
   */
  get newFeedFeatureFlagOn(): boolean {
    return this.featureService.checkFlag(this.featureFlags.new_feed);
  }

  /**
   * Fetch the client config for the client that's being edited.
   */
  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientService.getClient(params['clientId']).subscribe(
        (client) => {
          this.client = client;
          this.initForm();
          if (
            this.featureService.checkFlag(this.featureFlags.experience_library)
          ) {
            this.initCategoryOptions();
          }
        },
        () => {
          this.client = new Client();
          this.messageDialog.showMessage(
            `Oops...Could not load client: ${params['clientId']}`,
          );
        },
        () => {
          this.setPrimaryTitle();
        },
      );
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Edit Client - ' + this.client.company_name
        : 'Edit Client',
    );
  }

  private onSuccessfulSave(res: Client): void {
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/branding`);
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(
      `Oops...could not save client${serverMessage}`,
    );
  }
}
