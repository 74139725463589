import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Client, ProductGroup } from '@app/core/models/client';
import { RadioBtnComponent } from '@app/shared/radio-btn/radio-btn.component';
import { VerificationField } from '@app/core/models/validation-verification';
import { URLUtils } from '@app/core/utils/url-utils';
import { JourneyService } from '@app/core/services/journey.service';
import { FallbackJourneyInfo } from '@app/core/models/fallback-journey-info';
import * as _ from 'lodash';
import { CharacterLimits } from '@app/core/utils/characterLimits';

enum CustomerTypes {
  one = "1",
  two = "2",
  three = "3",
  four = "4",
}
@Component({
  selector: 'app-new-product-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class ProductOnboardingComponent implements OnInit {

  @Input() client: Client;
  @Input() productGroup: ProductGroup;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  validationFields: VerificationField[] = [];

  @ViewChild(RadioBtnComponent) radioBtn;

  headerGroups = { onboardingChannels: true };
  signUpGroup: UntypedFormGroup;
  isJourneySelect: boolean = false;
  showSetJourneyButton: boolean = true;
  journeyInfo: FallbackJourneyInfo;
  signUpSubmitButtonDefaultText: string = "Submit";
  termsAndConditionsDefaultText: string = "I accept the terms and conditions.";

  constructor(private formBuilder: UntypedFormBuilder, private journeyService: JourneyService) { }

  ngOnInit() {
    this.signUpGroup = this.client.feed_enabled
      ? this.formBuilder.group({
        'message_text': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.message_text,
          [Validators.required]
        ),
        'ccid_input_label': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.ccid_input_label,
          [Validators.required]
        ),
        'mobile_input_label': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.mobile_input_label,
          [Validators.required]
        ),
        'success_text': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.success_text,
          [Validators.required, Validators.maxLength(CharacterLimits.SignUpPageSuccessCopy)]
        ),
        'failure_text': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.failure_text,
          [Validators.required, Validators.maxLength(CharacterLimits.SignUpPageFailureCopy)]
        ),
        'submit_button_label': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.submit_button_label ||
          this.signUpSubmitButtonDefaultText,
          [Validators.required, Validators.maxLength(CharacterLimits.SignUpPageSubmitButtonLabel)]
        ),
        'terms_and_conditions_text': new UntypedFormControl(
          this.productGroup.onboarding.sign_up_page.terms_and_conditions_text || this.termsAndConditionsDefaultText,
          [Validators.required, Validators.maxLength(CharacterLimits.SignUpPageTermsAndConditions)]
        )
      })
      : this.formBuilder.group({
        'message_text': [this.productGroup.onboarding.sign_up_page.message_text, Validators.required],
        'ccid_input_label': [this.productGroup.onboarding.sign_up_page.ccid_input_label, Validators.required],
        'mobile_input_label': [this.productGroup.onboarding.sign_up_page.mobile_input_label, Validators.required],
        'success_text': [this.productGroup.onboarding.sign_up_page.success_text, Validators.required],
        'failure_text': [this.productGroup.onboarding.sign_up_page.failure_text, Validators.required],
        'submit_button_label': [this.productGroup.onboarding.sign_up_page.submit_button_label || this.signUpSubmitButtonDefaultText, Validators.required],
        'terms_and_conditions_text': [this.productGroup.onboarding.sign_up_page.terms_and_conditions_text || this.termsAndConditionsDefaultText, Validators.required]
      });

    _.each(this.client.validation.fields, (field: VerificationField) => {
      this.signUpGroup.addControl(field.field, new UntypedFormControl(field.prompt, [Validators.required]));
    });

    // trigger an initial 'isValid' check so subscribers get the initial value
    this.isValid.emit(this.signUpGroup.valid);

    this.signUpGroup.valueChanges.subscribe(() => this.bindFormValues());

    this.initFallbackJourneySelectionContent();
  }

  /**
   * 
   * @returns true if the "Customer type" for Mobile message verification is set to 4 - all other customer types should be 
   * disabled from allowing generic connections and we should hide that option accordingly
   * 
   */

  showGenericConnectionsSetting(): boolean {
    return this.client.verification.customer_type === CustomerTypes.four;
  }

  /**
   * returns true if the client has validation switched on.  Determines whether to show/hide the sign up page config fields
   */
  validationIsOn(): boolean {
    return this.client.validation.enabled === "true";
  }

  urlToEditValidation() {
    return `/client/${this.client.id}/edit/validation`;
  }

  getGeneratedURL(): string {
    const path = `/c/${this.client.id}/p/${this.productGroup.id}/sign-up`;
    if (this.productGroup.is_vanity_url_enabled) {
      return this.productGroup.vanity_url + path;
    } else {
      return URLUtils.getWireURLDefaultToProd() + path;
    }
  }

  getFormWrapperClass() {
    return this.validationIsOn() ? 'signup-form-wrapper' : 'signup-form-wrapper--hidden';
  }

  getSignupPageTipText(): string {
    if (this.validationIsOn()) {
      return 'The sign‑up page is a webform where customers can onboard to the Relay platform. In order to use the sign‑up page, validation is required.';
    } else {
      return `Validation must be switched on to configure this feature.  To change validation settings, go to "${this.urlToEditValidation()}"`;
    }
  }

  onFallbackJourneySelection(journeyInfo: FallbackJourneyInfo) {
    this.isJourneySelect = false;
    this.journeyInfo = journeyInfo;
    this.productGroup.welcome_trigger_id = null;
    if (journeyInfo) {
      this.showSetJourneyButton = false;
      this.productGroup.welcome_trigger_id = this.journeyInfo.triggerId;
    }
  }

  removeTriggerId() {
    this.journeyInfo = new FallbackJourneyInfo();
    this.showSetJourneyButton = true;
    this.productGroup.welcome_trigger_id = null;
  }

  /**
   * When component loads, show the current fallback trigger id and
   * associated journey name, if the trigger id was previously saved.
   */
  private initFallbackJourneySelectionContent() {
    if (this.productGroup.welcome_trigger_id) {
      this.showSetJourneyButton = false;
      this.journeyInfo = new FallbackJourneyInfo();
      this.journeyInfo.triggerId = this.productGroup.welcome_trigger_id;

      this.journeyService.getJourneyByTriggerId(this.journeyInfo.triggerId, this.client.id).subscribe(
        journey => {
          this.journeyInfo.id = journey.id;
          this.journeyInfo.name = journey.latestContent.name;
        }
      );
    }
  }

  /**
   * This component is a child of a larger view.
   * The parent component uses the product group and client objects
   * to determine validity state. In order to avoid refactoring
   * we can rebind the values of the form.
   *
   * The component itself will be responsible for
   * marking fields valid/invalid
   *
   * THe parent component is responsible for allowing the entire page to submit.
   */
  private bindFormValues(): void {
    _.each(this.client.validation.fields, (field: VerificationField, i: number) => {
      if (this.signUpGroup.controls[field.field]) {
        this.client.validation.fields[i].prompt = this.signUpGroup.controls[field.field].value;
        this.validationFields = [...this.client.validation.fields]
      }
    });

    this.productGroup.onboarding.sign_up_page.message_text = this.signUpGroup.controls['message_text'].value;
    this.productGroup.onboarding.sign_up_page.ccid_input_label = this.signUpGroup.controls['ccid_input_label'].value;
    this.productGroup.onboarding.sign_up_page.mobile_input_label = this.signUpGroup.controls['mobile_input_label'].value;
    this.productGroup.onboarding.sign_up_page.success_text = this.signUpGroup.controls['success_text'].value;
    this.productGroup.onboarding.sign_up_page.failure_text = this.signUpGroup.controls['failure_text'].value;
    this.productGroup.onboarding.sign_up_page.submit_button_label = this.signUpGroup.controls['submit_button_label'].value;
    this.productGroup.onboarding.sign_up_page.terms_and_conditions_text = this.signUpGroup.controls['terms_and_conditions_text'].value;

    // this is legacy
    // the parent code uses this for validation
    this.isValid.emit(this.signUpGroup.valid);
  }

  getCharacterLimit(form: string) {
    switch (form) {
      case 'signUpSuccessCopy':
        return CharacterLimits.SignUpPageSuccessCopy;
      case 'signUpFailureCopy':
        return CharacterLimits.SignUpPageFailureCopy;
      case 'signUpPageSubmitButtonLabel':
        return CharacterLimits.SignUpPageSubmitButtonLabel;
      case 'signUpPageTermsAndConditions':
        return CharacterLimits.SignUpPageTermsAndConditions;
    }
  }
}
