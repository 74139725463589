import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormatUtils } from '@app/core/utils/format-utils';
import { Client } from '@app/core/models/client';
import { VerificationField } from '@app/core/models/validation-verification';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { TitleService } from '@app/core/services/title.service';
import { LoggerService } from '@app/core/services/logger.service';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { RotationIntervals } from '@app/core/utils/password-rotation-interval';

@Component({
  selector: 'app-client-validation-and-verification',
  templateUrl: './client-validation-and-verification.component.html',
  styleUrls: ['./client-validation-and-verification.component.scss'],
})
export class ClientValidationAndVerificationComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  client: Client;
  inputFields: VerificationField[] = [];
  headerGroups = {
    validation: true,
    verification: true,
    passwordSettings: true, //only available with new Feed
  };
  rotationIntervalOptions = Object.values(RotationIntervals);

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private titleService: TitleService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getClient();
    this.getInputFields();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  private getInputFields(): void {
    this.clientService.getValidationFields().subscribe(
      (fields) => {
        _.forEach(fields, (field) => {
          const newVerificationField = new VerificationField(
            field,
            FormatUtils.formatInputField(field),
          );
          this.inputFields.push(newVerificationField);
        });
      },
      (error) => {
        this.messageDialog.showMessage(
          `Oops...could not load input fields: ${error}`,
        );
        LoggerService.log(
          'ClientValidationAndVerificationComponent',
          `getInputFields() error: ${error}`,
        );
      },
    );
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientService.getClient(params['clientId']).subscribe(
        (client) => {
          this.client = client;
        },
        (error) => {
          this.messageDialog.showMessage(
            `Oops...could not load client data: ${error}`,
          );
          LoggerService.log(
            'ClientValidationAndVerificationComponent',
            `getClient() error: ${error}`,
          );
        },
        () => {
          this.setPrimaryTitle();
        },
      );
    });
  }

  onValidationMaxAttemptChange(event): void {
    const input = <HTMLInputElement>(
      document.getElementById('max-attempts-field')
    );
    input.value = parseInt(event.target.value) > 99 ? 99 : event.target.value;
    input.value = parseInt(event.target.value) < 0 ? 0 : event.target.value;
    this.client.validation.max_attempts = parseInt(input.value);
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Client - ' + this.client.company_name
        : 'Configure Client',
    );
  }

  checkDisabled(): any {
    if (
      this.client &&
      this.client.verification.enabled === 'true' &&
      this.client.verification.fields.length > 0
    ) {
      return null;
    }

    return this.client && this.client.verification.enabled === 'true';
  }

  previousStep(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/edit/branding`);
  }

  saveAndContinue(): void {
    this.clientService.updateClient(this.client).subscribe(
      (res) => this.onSuccessfulSave(res),
      (err: HttpErrorResponse) => this.onFailedSaved(err),
    );
  }

  informationFieldsListChanged(newList): void {
    this.client.validation.information_fields = newList;
  }

  validationFieldsListChanged(newList): void {
    this.client.validation.fields = newList;
  }

  private onSuccessfulSave(res: Client): void {
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/inputs`);
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(
      `Oops...could not save client: ${serverMessage}`,
    );
  }

  toggleEnforcePasswordRotation(): void {
    this.client.password_configuration.rotation_enabled =
      !this.client.password_configuration.rotation_enabled;
  }

  changeRotationFrequency(event: Event): void {
    this.client.password_configuration.rotation_interval = Number(
      (event.target as HTMLInputElement).value,
    );
  }
}
