import { useCallback, useEffect, useState } from 'react';
import { useClientInfo } from '../../context/ClientInfoContext';
import { ControlGroup, TwoWayService } from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { RelayMessengerAgent } from '../agent-modal/AgentModal';
import { useUserInfo } from '../../context/UserInfoContext';
import React from 'react';
import { useStyletron } from 'baseui';
import { ListItem } from 'baseui/list';
import { Badge } from '../badge/Badge';
import { COLOR } from 'baseui/badge';
import { SearchInput } from '../search/SearchInput';
import { ParagraphSmall } from 'baseui/typography';
import { Button } from '@relaynetwork/design-system';
import { SortingToast } from '../filter-sorting/SortingToastMessage';
import {
  AgentFilterSortingPopover,
  AgentStatus,
} from '../filter-sorting/AgentFilterSortingPopover';
import { AgentNameAscendingDescendingMethods } from '../filter-sorting/SortAgentName';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';

export interface RelayMessengerAgentsProps {
  agentConversationsClicked: boolean;
  onAgentSelected: (agent: RelayMessengerAgent) => void;
}

export const RelayMessengerAgents = ({
  agentConversationsClicked,
  onAgentSelected,
}: RelayMessengerAgentsProps) => {
  const [{ clientId }] = useClientInfo();
  const [{ userId }] = useUserInfo();
  const [agents, setAgents] = useState<RelayMessengerAgent[]>([]);
  const [actualAgents, setActualAgents] = useState<RelayMessengerAgent[]>([]);
  const [agentIds, setAgentIds] = useState<string[]>([]);
  const [agentStatuses, setAgentStatuses] = useState([]);
  const [css] = useStyletron();
  const [agentSearchOptions, setAgentSearchOptions] = useState<string[]>([]);
  const [isAgentSearchInputOpen, setIsAgentSearchInputOpen] = useState(false);
  const [isAgentFilterSortingPopoverOpen, setIsAgentFilterSortingPopoverOpen] =
    useState(false);
  const [filterByOption, setFilterByOption] = useState<
    AgentStatus.Active | AgentStatus.Inactive
  >(null);
  const [sortingName, setSortingName] = React.useState<
    | AgentNameAscendingDescendingMethods.Ascending
    | AgentNameAscendingDescendingMethods.Descending
  >(null);
  const [showClearAllButton, setShowClearAllButton] = useState(false);
  const { setAssignedAgents } = useAssignedAgents();
  const [groups, setGroups] = useState<ControlGroup[]>([]);

  const filterAgentNames = (
    agents: RelayMessengerAgent[],
    searchOptions: string[],
  ): RelayMessengerAgent[] => {
    return agents.filter(
      (agent: RelayMessengerAgent) =>
        agent &&
        ((agent.firstName &&
          searchOptions.includes(agent.firstName.toLowerCase())) ||
          (agent.lastName &&
            searchOptions.includes(agent.lastName.toLowerCase()))),
    );
  };

  const filterStatus = (
    agents: RelayMessengerAgent[],
    filterOption: string,
  ): RelayMessengerAgent[] => {
    return agents.filter((agent: RelayMessengerAgent) => {
      if (agent && agent.id) {
        const status = agentStatuses.find(
          (agentStatus) =>
            agentStatus &&
            agentStatus.userId &&
            agentStatus.userId === agent.id,
        );
        if (status && status.status === filterOption) {
          return agent;
        }
      }
    });
  };

  const filterOnGroupName = (
    agents: RelayMessengerAgent[],
    filterOption: string,
  ): RelayMessengerAgent[] => {
    const groupToFilterOn = groups.find((group) => group.group_name === filterOption);
    return agents.filter((agent: RelayMessengerAgent) => {
      if (agent && agent.id && agent.groupId) {
        if (agent.groupId === groupToFilterOn.id) {
          return agent;
        }
      }
    });
  };

  const sortAgent = (
    agents: RelayMessengerAgent[],
    sortingOption: AgentNameAscendingDescendingMethods.Ascending | AgentNameAscendingDescendingMethods.Descending
    ): RelayMessengerAgent[] => {
    return agents.sort((a, b) => {
      if (sortingOption === AgentNameAscendingDescendingMethods.Ascending) {
        return a.firstName.localeCompare(b.fullName)
      } else if (sortingName === AgentNameAscendingDescendingMethods.Descending) {
        return b.firstName.localeCompare(a.firstName)
      }
    })
  }

  useEffect(() => {
    if (agentSearchOptions.length || filterByOption || sortingName) {
      let modifiedArray: RelayMessengerAgent[] = [];
      if (agentSearchOptions.length > 0) {
        modifiedArray = filterAgentNames(actualAgents, agentSearchOptions);
      }
      if (filterByOption) {
        let filterOption = '';
        switch (filterByOption) {
          case AgentStatus.Active:
            filterOption = 'active';
            break;
          case AgentStatus.Inactive:
            filterOption = 'inactive';
            break;
          default:
            filterOption = filterByOption;
            break;
        }
        if (filterOption === 'active' || filterOption === 'inactive') {
          modifiedArray = filterStatus(
            agentSearchOptions.length > 0 ? modifiedArray : actualAgents,
            filterOption,
          );
        } else {
          modifiedArray = filterOnGroupName(agentSearchOptions.length > 0 ? modifiedArray : actualAgents, filterOption);
        }

      }
      if (sortingName) {
        modifiedArray = sortAgent (
          (agentSearchOptions.length > 0 || filterByOption) ? modifiedArray : actualAgents,
          sortingName
        )
      }
      setAgents(modifiedArray);
      setShowClearAllButton(true);
    } else {
      setAgents(actualAgents);
      setShowClearAllButton(false);
    }
  }, [agentSearchOptions, filterByOption, sortingName]);

  const fetchAgents = useCallback(async () => {
    // perform search when invite modal is open
    try {
      const agents = await TwoWayService.getAgentsList(clientId);
      // exclude the current user from the list of agents if they are in the list
      const updatedAgents = agents
        .map((el) => {
          el.isSelected = false;
          return el;
        })
        .filter((agent) => agent.id !== userId);
      setActualAgents(updatedAgents);
      setAgents(updatedAgents);
      const updatedAgentsIds = updatedAgents.map((user) => user.id);
      setAgentIds(updatedAgentsIds);
      
    } catch (error) {
      console.error('Error fetching agents', error);
    }
  }, [clientId, userId]);

  const fetchGroups = useCallback(async () => {
    try {
      const clientGroups = await TwoWayService.getGroups(clientId);
      setGroups(clientGroups);
    } catch (error) {
    }
  }, [clientId]);

  useEffect(() => {
    if (!agentConversationsClicked) {
      if (agents && agents.length) {
        const updatedAgents = agents.map((agent, i) => {
          return {
            ...agent,
            isSelected: false,
          };
        });
        setAgents(updatedAgents);
      }
    }
  }, [agentConversationsClicked]);

  useEffect(() => {
        fetchAgents();

  }, []);

  useEffect(() => {
    fetchGroups();

}, []);

  const fetchAgentsStatuses = useCallback(async () => {
    try {
      const agentStatuses = await TwoWayService.getAgentStatuses(
        clientId,
        agentIds,
      );
      setAgentStatuses(agentStatuses);
    } catch (error) {
      console.error('Error fetching two-way user statuses', error);
    }
  }, [agentIds]);

  const updatIsSelected = (agent, index) => {
    onAgentSelected(agent);
    if (agents && agents.length) {
      const updatedAgents = agents.map((agent, i) => {
        if (i === index) {
          return {
            ...agent,
            isSelected: true,
          };
        }
        return {
          ...agent,
          isSelected: false,
        };
      });
      setAgents(updatedAgents);
    }
  };

  useEffect(() => {
    // get agents statuses
    if (agentIds.length) {
      fetchAgentsStatuses();
    }
  }, [agentIds]);

  const mapAgentNames = (agents: RelayMessengerAgent[]) => {
    const agentNames = [];
    if (agents) {
      agents.forEach((agent) => {
        if (agent?.firstName) {
          agentNames.push({ label: agent.firstName, id: agent.firstName });
        }
        if (agent?.lastName) {
          agentNames.push({ label: agent.lastName, id: agent.lastName });
        }
      });
    }
    return agentNames;
  };

  const resetFilterSorting = () => {
    setAgentSearchOptions([]);
    setFilterByOption(null);
    setSortingName(null);
  };
  const removeSearchOpton = (option) => {
    const newSearchOptions = agentSearchOptions.filter(
      (value) => value !== option,
    );
    setAgentSearchOptions(newSearchOptions);
  };
  const removefilterSorting = (isFilter: boolean) => {
    if (isFilter) {
      setFilterByOption(null);
    } else {
      setSortingName(null);
    }
  };
  const clearFilterState = () => {
    // This is to unselect filtering (that does not have any options) when the sorting option is selected.
    if (filterByOption) {
      setFilterByOption(null);
    }
  };

  const clearSortingSelection = () => {
    setSortingName(null);
  };

  return (
        <div
          className={css({
            width: '100%',
            marginTop: '2rem',
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <div
              className={css({
                display: 'flex',
              })}
            >
              {!isAgentSearchInputOpen && (
                <p
                  className={css({
                    color: '#37474F',
                    fontFamily: 'Source Sans Pro',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                  })}
                  id='assigned-to-agent'
                >
                  ASSIGNED TO AN AGENT
                </p>
              )}
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                })}
              >
                <SearchInput
                  isSearchInputOpen={isAgentSearchInputOpen}
                  setIsSearchInputOpen={setIsAgentSearchInputOpen}
                  tagsAndCustomersList={mapAgentNames(agents)}
                  searchOptions={agentSearchOptions}
                  setSearchOptions={setAgentSearchOptions}
                  isAgent={true}
                />
                <AgentFilterSortingPopover
                  filterByOption={filterByOption}
                  setFilterByOption={setFilterByOption}
                  isAgentFilterSortingPopoverOpen={
                    isAgentFilterSortingPopoverOpen
                  }
                  setIsAgentFilterSortingPopoverOpen={
                    setIsAgentFilterSortingPopoverOpen
                  }
                  sortingName={sortingName}
                  setSortingName={setSortingName}
                  clearFilterState={clearFilterState}
                  clearSortingSelection={clearSortingSelection}
                  groups={groups}
                ></AgentFilterSortingPopover>
              </div>
            </div>
            <div>
              <hr className={css({ border: '1', margin: '0px' })} />
              {showClearAllButton && (
                <>
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    })}
                  >
                    <ParagraphSmall
                      className={css({
                        color: 'rgba(55, 71, 79, 0.25) !important',
                        fontFamily: 'Source Sans Pro !important',
                        fontSize: '12px !important',
                        fontStyle: 'normal !important',
                        fontWeight: '600 !important',
                        lineHeight: 'normal !important',
                        marginBottom: '10px',
                        marginTop: '10px',
                      })}
                      data-testid="filter-by-text"
                    >
                      FILTER BY
                    </ParagraphSmall>
                    {showClearAllButton && (
                      <Button
                        variant="empty"
                        onClick={resetFilterSorting}
                        size="small"
                        data-testid="clear-all-button"
                      >
                        Clear all
                      </Button>
                    )}
                  </div>
                  <div
                    className={css({
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'start',
                    })}
                  >
                    {agentSearchOptions &&
                      agentSearchOptions.length > 0 &&
                      agentSearchOptions.map((option) => {
                        return (
                          <SortingToast
                            onActionClick={() => removeSearchOpton(option)}
                            text={option}
                            dataTestId={`toast-filter-option-` + option}
                          />
                        );
                      })}
                    {sortingName && (
                      <SortingToast
                        onActionClick={() => removefilterSorting(false)}
                        text={sortingName}
                        dataTestId={`toast-sorting-option-` + sortingName}
                      />
                    )}
                    {filterByOption && (
                      <SortingToast
                        onActionClick={() => removefilterSorting(true)}
                        text={filterByOption}
                        dataTestId={`toast-filter-option-` + filterByOption}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <ul
            className={css({
              paddingLeft: '0',
              paddingRight: '0',
              overflowY: 'auto',
              maxHeight: '40vh'
            })}
            id='agents-list'
          >
            {agents.map((agent, index) => {
              const agentStat = agentStatuses.find(
                (status) => status.userId === agent.id,
              );
              const status = agentStat ? agentStat.status : 'inactive';
              return (
                <ListItem
                  key={agent.id}
                  overrides={{
                    Root: {
                      style: {
                        outline: 'none',
                        backgroundColor: 'none',
                      },
                    },
                    Content: {
                      style: {
                        paddingLeft: '10px',
                      },
                    },
                  }}
                >
                  <div
                    onClick={() => {
                      setAssignedAgents([]);
                      updatIsSelected(agent, index);
                    }}
                    className={css({
                      fontWeight: agent && agent?.isSelected ? `700` : `400`,
                    })}
                    id='agent-conversations'
                  >
                    <Badge
                      color={
                        status === 'active' ? COLOR.positive : COLOR.negative
                      }
                      position="left"
                      status={status}
                    >
                    {agent.fullName}
                    </Badge>
                  </div>
                </ListItem>
              );
            })}
          </ul>
        </div>
  );
};
