import { RelaySharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExperienceLibraryAdminComponent } from './components/experience-library-admin/experience-library-admin.component';
import { ExperienceLibraryRoutingModule } from './experience-library-routing.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IndustryListComponent } from './components/industry/industry-list/industry-list.component';
import { CompanyTypeListComponent } from './components/company-type/company-type-list/company-type-list.component';
import { CompanyTypeListItemComponent } from './components/company-type/company-type-list-item/company-type-list-item.component';
import { CompanyTypeAddEditComponent } from './components/company-type/company-type-add-edit/company-type-add-edit.component';
import { ExperienceTypeAddEditComponent } from './components/experience-type/experience-type-add-edit/experience-type-add-edit.component';
import { ExperienceTypeListComponent } from './components/experience-type/experience-type-list/experience-type-list.component';
import { ExperienceTypeListItemComponent } from './components/experience-type/experience-type-list-item/experience-type-list-item.component';
import { ExperienceLibraryComponent } from './components/experience-library/experience-library.component';
import { JourneyListModule } from '../journey-list/journey-list.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IndustryAddEditComponent } from './components/industry/industry-add-edit/industry-add-edit.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { OutcomeListComponent } from './components/outcome/outcome-list/outcome-list.component';
import { OutcomeListItemComponent } from './components/outcome/outcome-list-item/outcome-list-item.component';
import { OutcomeAddEditComponent } from './components/outcome/outcome-add-edit/outcome-add-edit.component';

@NgModule({
  imports: [
    CommonModule,
    RelaySharedModule,
    ExperienceLibraryRoutingModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    JourneyListModule,
    MatTooltipModule,
    MatSelectModule,
  ],
  declarations: [
    ExperienceLibraryAdminComponent,
    IndustryListComponent,
    CompanyTypeListComponent,
    CompanyTypeListItemComponent,
    CompanyTypeAddEditComponent,
    IndustryAddEditComponent,
    ExperienceTypeAddEditComponent,
    ExperienceTypeListComponent,
    ExperienceTypeListItemComponent,
    ExperienceLibraryComponent,
    OutcomeListComponent,
    OutcomeListItemComponent,
    OutcomeAddEditComponent,
  ],
  exports: [ExperienceLibraryAdminComponent, ExperienceLibraryComponent],
})
export class ExperienceLibraryModule {}
