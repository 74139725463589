import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from '@app/core/services/feature.service';
import { TitleService } from '@app/core/services/title.service';
import { Location } from '@angular/common';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-api-mgmt',
  templateUrl: './api-mgmt.component.html',
  styleUrls: ['./api-mgmt.component.scss'],
})
export class ApiMgmtComponent implements OnInit {
  links: { text: string; path: string }[];
  featureFlags = FeatureFlags;

  tabs = [
    {id: 'apikey-mgmt', label: 'API Key Management'}
  ];
  currentTabId: string = 'apikey-mgmt';
  isApiKeyManagementTabDisplayed: boolean;
  isPushApiTabDisplayed: boolean;
  isPushApiEnabled: boolean;

  constructor(
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private featureService: FeatureService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.titleService.activate('API Management');
    this.isPushApiEnabled = this.featureService.checkFlag(FeatureFlags.push_api_client);
    this.addPushApiTab(this.featureService.checkFlag(FeatureFlags.push_api_client));
    this.currentTabId = this.currentTabId = this.activatedRoute.snapshot.queryParams['tab'] ?? 'apikey-mgmt';
    this.onTabChange(this.currentTabId);
  }

  onTabChange(newTabId: string) {
    if (newTabId === 'apikey-mgmt') {
      this.configureTabDisplay(true, false);
    } else if (newTabId === 'push-api') {
      this.configureTabDisplay(false, true);
    } else {
      this.configureTabDisplay(false, false);
    }

    this.location.go(`/api-mgmt/${newTabId}`);
  }

  configureTabDisplay(isApiKeyManagementTabDisplayed: boolean, isPushApiTabDisplayed: boolean) {
    this.isApiKeyManagementTabDisplayed = isApiKeyManagementTabDisplayed;
    this.isPushApiTabDisplayed = isPushApiTabDisplayed;
  }

  addPushApiTab(isPushApiEnabled: boolean) {
    if (isPushApiEnabled) {
      this.tabs.push({id: 'push-api', label: 'Push API Integration'})
    }
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }
}
