import { AuthGuardService } from '../security/auth-guard.service';
import { Permissions } from '../core/services/permission.service';
import { MediaListComponent } from './media-list/media-list.component';
import { MediaLibraryContainer } from './media-library-container/media-library-container.component';
import { BrandingList } from './branding-list/branding-list.component';
import { FeatureFlags } from '@app/core/models/feature-flags';

export const MediaLibraryRoutes = [
  {
    path: 'media-branding-library',
    component: MediaLibraryContainer,
    data: { perm: Permissions.ui_media_asset },
    children: [
      {
        path: 'media-library',
        component: MediaListComponent,
        canActivate: [AuthGuardService],
        data: { perm: Permissions.ui_media_asset, step: 'media-library' },
      },
      {
        path: 'branding-library',
        component: BrandingList,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_media_asset,
          step: 'branding-library',
          flag: FeatureFlags.feed2_message_level_branding,
        },
      },
    ],
  },
];
