<div class="rn-page">
  <div class="help-text-container">
    <p *ngIf="currentClient.feed_enabled">All CXB Users that will participate in Relay Messenger need to have a messenger role added to their user account.  You can edit current users with the pencil icon, or add new users.</p>
    <p *ngIf="!currentClient.feed_enabled">All CXB Users that will participate in Two Way Messaging need to have a Two Way role added to their User Account.  You can edit current users with the pencil icon, or add new users.</p>
    <p *ngIf="currentClient.feed_enabled">If you are using control groups to control the flow of incoming messages, make sure your users are assigned to a control group.</p>
    <p *ngIf="!currentClient.feed_enabled">If you are using Control Groups to control the flow of incoming Two Way Messages, make sure your users are assigned to a Control Group.</p>
  </div>
  <div class="rn-page__section mask-agent-display" *ngIf="this.currentClient.feed_enabled && twoWayAgentMaskingForm  && isRelayMessengerR2Enabled">
    <app-radio-btn
      [btnLabel]="'Mask Agent Display Name'"
      [btnWidth]="'300px'"
      [btnName]="'mask-agent-display-name'"
      [btnModel]="this.currentClient.two_way_config.mask_agent_display_name"
      (btnModelChange)="toggleMaskAgentDisplayName()"
    ></app-radio-btn>
    <span class="helper-text">(Turn OFF to display respective agent names to your customers.)</span>
       <br>
       <br>
       <ng-container *ngIf="twoWayAgentMaskingForm && this.twoWayConfig.mask_agent_display_name">
        <input class="mask-agent-display-name" [inputLabel]="'Agent Display Name'" [formControl]="twoWayAgentMaskingForm.controls['agent_display_name']"
                      [placeholder]="'Type the name to be displayed to the customers'">
        <p *ngIf="!twoWayAgentMaskingForm.controls['agent_display_name'].valid" id="max-length">
          <i class="fa fa-exclamation-circle"></i>
          <span id="max-length-msg" i18n="Agent display name max length message">Agent display name must be 30 characters or less.</span>
        </p>
       </ng-container>
  </div>
  <div class="rn-page__section">

    <div class="rn-page--busy" *ngIf="isLoading(); else main_content">
      <app-spinner></app-spinner>
    </div>

    <ng-template #main_content>

      <app-user-list [fixedHeader]="false" (editUser)="editUser($event)"></app-user-list>

      <message-dialog #editUserDialog [showCancel]='false' [showAffirm]='false' [showClose]='true' (close)="editUserClosed()">
        <ng-template>
          <app-user-edit *ngIf="userIDToEdit" [isNew]="false" [userID]="userIDToEdit" (close)="editUserClosed()"></app-user-edit>
          <p *ngIf="!userIDToEdit">We're sorry, there was an error fetching the user.</p>
        </ng-template>  
      </message-dialog>
      <message-dialog #twoWayMaskingDialog [showCancel]='false' [showAffirm]='false' [showClose]='true'>
      </message-dialog>
    </ng-template>

  </div>

  <div class="button-container">
    <button class="btn btn-primary" id="next-btn" (click)="saveAndContinue()" [class.inactive]="false" [disabled]="false">
    <span i18n="Label for Next button on new client configuration" id="save-and-next-button">
      Save and Next
    </span>
      <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
    </button>
  </div>

</div>
