import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { TitleService } from '@app/core/services/title.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-client-images-new',
  templateUrl: './client-images-new.component.html',
  styleUrls: ['./client-images-new.component.scss']
})
export class ClientImagesNewComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  client: Client;
  headerGroups = {portalImages: true};

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private titleService: TitleService,
              private router: Router) {
    this.client = new Client();
    this.getClient();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  toggleHeader(headerGroup: string) {
    if (this.headerGroups[headerGroup] !== undefined) {
      this.headerGroups[headerGroup] = !this.headerGroups[headerGroup];
    }
  }

  saveAndContinue(): void {
    this.clientService.updateClient(this.client)
      .subscribe(
        (res) => this.onSuccessfulSave(res),
        (err: HttpErrorResponse) => this.onFailedSaved(err)
      );
  }

  private onSuccessfulSave(res: Client): void {
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/validation`);
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(`Oops...could not save client: ${serverMessage}`);
  }

  previousStep(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/edit`);
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientService.getClient(params['clientId']).subscribe(
        client => {
          this.client = Client.deserialize(client);
        },
        error => {
          this.messageDialog.showMessage(`Oops...Could not load client: ${params['clientId']}`);
        },
        () => {
          this.setPrimaryTitle();
        });
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Client - ' + this.client.company_name
        : 'Configure Client');
  }
}
