import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MessageClass,
  MessageDelayerClass,
  WorkflowClass,
} from '@app/core/models/message';
import { ProductGroup } from '@app/core/models/client';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { Action } from '@app/core/models/action';
import * as _ from 'lodash';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { BrandingData } from '@relaynetwork/design-system';

@Component({
  selector: 'phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})

/**
 * There are 6 places where this is still used:
 * - QuickLaunchContentComponent (CSR Onboarding)
 * - TriggerSelectorComponent (journey test launcher)
 * - MessageBuilderComponent
 * - JourneyPreviewComponent
 * - JourneyPdfPreviewComponent
 * - Recursively rendered PhoneComponent
 *
 * All remaining instances use the WirePreviewComponent
 * All other instances have been replaced by the simpler GenericPhoneComponent
 *
 * 1. only gets passed to/used in WirePreview.
 * 2. If true, phone will stretch vertically to contain all content instead of scrolling.  Used in JourneyPreview
 * 3. If true, phone component will recursively create phone components for response messages.  Used in JourneyPreview.
 * 4. self-explanatory.  passed in by journeyPdfPreview.  used by this component
 * 5. used to show a particular arrow in JourneyPreview. passed to/used in wirePreview.
 * 6. if true, will show numbers next to actions.  passed in by JourneyPdfPreview. passed to/used in wirePreview.
 * 7. productGroup is optional because of SMS messages, which don't need one.
 * 8. wire or sms are self-explanatory.  form indicates the form preview.
 * 9. for FormPreview only.
 */
export class PhoneComponent implements AfterViewInit {
  @Input() message?: MessageClass; /* [1] */
  @Input() productGroup?: ProductGroup; /* [7] */
  @Input() screen: 'wire' | 'sms' | 'form'; /* [8] */
  @Input() stretch: boolean = false; /* [2] */
  @Input() recursive: boolean = false; /* [3] */
  @Input() journeyContent: JourneyContent; /* [1] */
  @Input() hasIWCU?: boolean; /* [1] */
  @Input() large?: boolean = false; /* [4] */
  @Input() isJourneyPreview?: boolean = false; /* [5] */
  @Input() showPdfMarkup?: boolean = false; /* [6] */
  @Input() formLabel: string; /* [9] */
  @Input() formId: number; /* [9] */
  @Input() components?: Journey['live']['components'];
  @Output() toggleCollapsible: EventEmitter<void> = new EventEmitter<void>();

  placeholder: string = `https://${window.location.host}/assets/form-placeholder.png`;
  viewMenu: boolean = false;
  headerTitle: string;
  generations = [];

  constructor(public newFeed: NewFeedTransitionService) {}

  ngAfterViewInit() {
    if (this.message && this.message.type === 'MessageSender') {
      this.getDepth(this.message, 0);
    }
  }

  getOrigin(): string {
    return window.location.origin;
  }

  wrapperId(): string | undefined {
    return this.message ? `phone-${this.message.name}` : undefined;
  }

  calculateTop() {
    this.generations.forEach((generation, i) => {
      let top = 175;
      const x = 24;
      generation.forEach((message, depth) => {
        const id = `response-messages-${message.name}`;
        const responsesDOM = document.getElementById(id);
        if (responsesDOM && depth > 0) {
          responsesDOM.setAttribute('style', `top:${top}px`);
        }
        top += responsesDOM ? responsesDOM.clientHeight : 0;
        //calculate arrow height
        const actionbtn = document.getElementById(
          `step-action-${message.name}`,
        );
        const phone = document.getElementById(`response-msg-${message.name}`);
        const height = 62;

        if (actionbtn && phone) {
          const ah = phone.getClientRects()[0].top;
          const bh = actionbtn.getClientRects()[0].top;
          let diff = ah - bh;
          if (diff < 0) {
            diff = 0;
          }

          if (!document.querySelector(`#step-action-${message.name} svg`)) {
            return;
          }

          document
            .querySelector(`#step-action-${message.name} svg`)
            .setAttribute('height', (diff + 40).toString());
          document
            .querySelector(`#step-action-${message.name} g`)
            .setAttribute('transform', `translate(35,${diff + 4})`);
          document
            .querySelector(`#step-action-${message.name} line.down-line`)
            .setAttribute('y1', (diff + 16).toString());
          document
            .querySelector(`#step-action-${message.name} line.down-line`)
            .setAttribute('x1', (x - depth * 3).toString());
          document
            .querySelector(`#step-action-${message.name} line.down-line`)
            .setAttribute('x2', (x - depth * 3).toString());
          document
            .querySelector(`#step-action-${message.name} line.top-line`)
            .setAttribute('x1', (x - depth * 3 + 1).toString());
          document
            .querySelector(`#step-action-${message.name} line.bottom-line`)
            .setAttribute('y1', (diff + 16).toString());
          document
            .querySelector(`#step-action-${message.name} line.bottom-line`)
            .setAttribute('y2', (diff + 16).toString());
          document
            .querySelector(`#step-action-${message.name} line.bottom-line`)
            .setAttribute('x1', (x - 1 - depth * 3).toString());
          document
            .querySelector(`#step-action-${message.name} line.bottom-line`)
            .setAttribute('x2', (40).toString());
        }
      });
    });
  }

  /**
   * Returns array of form actions
   */
  getFormActions(): Action[] {
    return this.message.wire.actions.filter((action) =>
      _.includes(['form_info_capture'], action.type),
    );
  }

  getDepth(msg, depth: number) {
    if (!this.generations[depth]) {
      this.generations[depth] = [];
    }
    const responses = this.getResponseMessages(msg);

    this.generations[depth] = this.generations[depth].concat(responses);
    responses.forEach((r) => {
      this.getDepth(r, depth + 1);
    });
  }

  /**
   * Gets all Components in the components array that have the
   * current Message for this Phone component as a parent
   */
  getAllResponseMessages() {
    if (this.journeyContent && this.journeyContent.components) {
      const comps = this.journeyContent.components;
      const msgs = comps.filter((c) => c.parent === this.message.name);
      return msgs;
    } else {
      return [];
    }
  }

  /**
   * Gets all Components in the components array that have the
   * passed message as a parent
   */
  getResponseMessages(parent) {
    if (this.journeyContent && this.journeyContent.components) {
      const comps = this.journeyContent.components;
      const msgs = comps.filter((f) => f.parent === parent.name);
      return msgs;
    } else {
      return [];
    }
  }

  get headerColor() {
    return this.productGroup && this.productGroup.branding.color
      ? this.productGroup.branding.color
      : '#231F20';
  }

  get logoUrl() {
    return this.productGroup && this.productGroup.branding.icon_s3_url
      ? this.productGroup.branding.icon_s3_url
      : '';
  }

  get disclaimerConfig(): BrandingData['disclaimer'] {
    return this.productGroup
      ? {
          feedMenuEnabled:
            this.productGroup.disclaimer.feed_menu_enabled === 'true'
              ? true
              : false,
          feedViewEnabled:
            this.productGroup.disclaimer.feed_view_enabled === 'true'
              ? true
              : false,
          messageDetailsEnabled:
            this.productGroup.disclaimer.message_details_enabled === 'true'
              ? true
              : false,
          messageLevelEnabled:
            this.productGroup.disclaimer.message_level_enabled === 'true'
              ? true
              : false,
          text: this.productGroup.disclaimer.text,
        }
      : {
          feedMenuEnabled: false,
          feedViewEnabled: false,
          messageDetailsEnabled: false,
          messageLevelEnabled: false,
          text: '',
        };
  }
  get messageId() {
    return this.message ? this.message.name : '';
  }

  get powerUpPlaceholderUrl() {
    return `${this.getOrigin()}/assets/power-up-placeholder.png`;
  }
}
