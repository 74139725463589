import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { User } from '@app/core/models/user';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { Ordering } from '@app/core/utils/ordering';
import { SortableComponent } from '@app/core/sortable-component';
import { PageManager } from '@app/core/utils/page-manager';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SearchableField } from '@app/shared/search-bar/search-bar.component';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SessionService } from '@app/security/session.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import { ControlGroup, ControlTagsService } from '@app/core/services/control-tags.service';
import { Client } from '@app/core/models/client';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends SortableComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @Input() fixedHeader: boolean = false;
  @Output() editUser: EventEmitter<string> = new EventEmitter();
  users: User[] = [];
  sso: boolean = false;
  loaded = false;
  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  offset = 0;
  ordering: Ordering;
  searchCriteria: SearchCriteria;
  showModal: boolean = false;
  searchableFields = [
    new SearchableField('Name', 'full_name'),
    new SearchableField('Email', 'email_address'),
    new SearchableField('Role', 'role_id'),
    // new SearchableField('Control Group', 'group_name')
  ];
  pageManager: PageManager = new PageManager();
  currentUserId: string;
  currentClient: Client;

  groups: ControlGroup[];

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    public controlTagsService: ControlTagsService,
    public twoWayService: TwoWaySharedService,
  ) {
    super();
  }

  ngOnInit() {
    this.currentUserId = this.sessionService.currentUser.id;
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.reloadUsers();
    this.sso = !!this.sessionService.currentUser.client.sso_config?.role_id_value_map[0];
    if (this.twoWayService.showTwoWay()) {
      this.getControlGroups();
    }
  }

  deleteUsersConfig() {
    const selectedUsers = this.users.filter((u) => u.selected);
    if (selectedUsers.length === 1) {
      return {
        title: 'Delete User',
        icon: 'icon-trash',
        msg: `Are you sure you want to delete ${selectedUsers[0].email_address}? This cannot be undone.`,
        btns: [
          { name: 'Cancel', icon: 'fa-times' },
          { name: 'Delete user', class: 'btn btn-warning', icon: 'fa-trash' },
        ],
      };
    }
    return {
      title: 'Delete Users',
      icon: 'icon-trash',
      msg: `Are you sure you want to delete ${selectedUsers.length} users? This cannot be undone.`,
      btns: [
        { name: 'Cancel', icon: 'fa-times' },
        { name: 'Delete users', class: 'btn btn-warning', icon: 'fa-trash' },
      ],
    };
  }

  openModal() {
    if (!!this.users.find((u) => u.selected)) {
      this.showModal = true;
    } else {
      this.messageDialog.showMessage('No users selected.');
    }
  }

  closeModal(decision) {
    this.showModal = false;
    if (decision === 'Delete user' || decision === 'Delete users') {
      this.deleteUsers();
    }
  }

  onRequestSearch(searchCriteria: SearchCriteria) {
    this.searchCriteria = searchCriteria;
    if (this.searchCriteria.searchFields.length === 0) {
      this.pageManager = new PageManager();
      this.users = [];
      this.offset = 0;
    } else {
      this.reloadUsers();
    }
  }

  onClearSearch() {
    this.searchCriteria = undefined;
    this.reloadUsers();
  }

  reloadUsers() {
    this.loaded = false;
    this.pageManager = new PageManager();
    this.users = [];
    this.offset = 0;
    this.getUsers();
  }

  selectUser(user, isSelected) {
    user.selected = isSelected;
  }

  editUserClicked(id: string) {
    this.editUser.emit(id);
  }

  deleteUsers() {
    const deleteUser = this.users.find((u) => u.selected);
    const feedEnabled = this.sessionService.currentUser.client.feed_enabled;
    const twoWayEnabled =
      this.sessionService.currentUser.client.two_way_config.enabled;
    if (deleteUser) {
      if (feedEnabled && twoWayEnabled) {
        forkJoin([
          this.userService.deletePortalUser(deleteUser.id),
          this.userService.unassignPortalAgent(deleteUser.id),
        ]).subscribe(
          (responses) => {
            this.pageManager.forEachPage((p) => {
              const index = this.users.findIndex((u) => u.id === deleteUser.id);
              if (index >= 0) {
                p.splice(index, 1);
              }
            });
            this.users = this.pageManager.flattenPages();
            this.deleteUsers();
          },
          (e) => {
            this.messageDialog.showMessage(
              'Something went wrong. please try again',
            );
          },
        );
      } else {
        this.userService.deletePortalUser(deleteUser.id).subscribe(
          (r) => {
            this.pageManager.forEachPage((p) => {
              const index = this.users.findIndex((u) => u.id === deleteUser.id);
              if (index >= 0) {
                p.splice(index, 1);
              }
            });
            this.users = this.pageManager.flattenPages();
            this.deleteUsers();
          },
          (e) => {
            this.messageDialog.showMessage(
              'Something went wrong. please try again',
            );
          },
        );
      }
    }
  }

  getUsers() {
    const offset = this.offset;
    this.userService
      .getUsers(
        this.limit,
        this.offset,
        this.searchCriteria,
        this.ordering,
        this.offset !== 0,
      )
      .subscribe(
        (users) => {
          this.pageManager.addPage(offset, users);
          this.users = this.pageManager.flattenPages();
        },
        (error) => {
          this.messageDialog.showMessage('Ooops...Could not load user list.');
        },
        () => {
          this.loaded = true;
        },
      );
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getUsers();
  }

  fetchData() {
    this.pageManager = new PageManager();
    this.users = [];
    this.loaded = false;
    this.offset = 0;
    this.getUsers();
  }

  getControlGroups(): void {
    this.controlTagsService.getGroups().subscribe(
      (groups) => {
        this.groups = groups;
      },
      (error) => {
        this.messageDialog.showMessage(
          'Ooops...There was a problem loading group data.  Groups for some users may be missing.  Please refresh to try again.',
        );
      },
    );
  }

  getGroupForUser(user): string {
    const matchingGroup = _.find(this.groups, (group) => {
      return group.group_id === user.group_id;
    });

    return matchingGroup ? matchingGroup.group_name : '';
  }
}
