import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProductGroup } from '@app/core/models/client';
import { Journey } from '@app/core/models/journey';
import { ClientService } from '@app/core/services/client.service';
import { JourneyService } from '@app/core/services/journey.service';
import { SessionService } from '@app/security/session.service';
import { Ordering } from '@app/core/utils/ordering';
import { flatMap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()

export class WirePreviewService {
  journey: Journey;
  productGroup: ProductGroup;
  hasIWCU: boolean;

  constructor(private journeyService: JourneyService,
              private clientService: ClientService,
              private sessionService: SessionService) { }

  // For journeyPreview and journeyPDFPreview, both of which need a journey, and to check consent
  getDataFromJourneyId(journeyId): Observable<{journey: Journey, productGroup: ProductGroup, hasIWCU: boolean}> {
    return this.sessionService.getCurrentUser().pipe(
      flatMap((user): Observable<Journey> => { 
        return this.journeyService.getJourneyById(journeyId); 
      }),
      flatMap((journey: Journey): Observable<ProductGroup> => {
        this.journey = journey;
        if (journey.latestContent.product_group !== 'all') {
          return this.clientService.getProductGroupForCurrentUser(journey.latestContent.product_group);
        } else {
          return of(ProductGroup.allProductGroupPreview());
        }
      }),
      flatMap((productGroup: ProductGroup) => {
        this.productGroup = productGroup;
        this.checkConsents(this.journey);
        return of({
          journey: this.journey,
          productGroup: this.productGroup,
          hasIWCU: this.hasIWCU
        });
      })
    );
  }

  getDataFromIds(pgId, clientId): Observable<{productGroup: ProductGroup}> {
    if (pgId === 'all') {
      return of({
        productGroup: ProductGroup.allProductGroupPreview()
      }); 
    } else {
      return this.clientService.getProductGroup(clientId, pgId).pipe(
        flatMap((productGroup: ProductGroup) => {
          return of({
            productGroup: productGroup
          });
        })
      )
    }
  }

  getDataFromJourney(journey: Journey): Observable<{productGroup: ProductGroup}> {
    return this.sessionService.getCurrentUser().pipe(
      flatMap((user): Observable<ProductGroup> => {
        if (journey.latestContent.product_group !== 'all') {
          return this.clientService.getProductGroupForCurrentUser(journey.latestContent.product_group);
        } else {
          return of(ProductGroup.allProductGroupPreview());
        }
      }),
      flatMap((productGroup: ProductGroup) => {
        this.productGroup = productGroup;
        return of({
          productGroup: this.productGroup,
        });
      })
    );
  }

  private checkConsents(journey): void {
    let journeyVersion;
    !!this.journey.live ? journeyVersion = this.journey.live : journeyVersion = this.journey.draft;
    if (journeyVersion.product_group !== 'all') {
      this.hasIWCU = this.productGroup.consent.in_wire_upgrade.enabled;
    } else {
      this.checkAllConsents(journey);
    }
  }

  private checkAllConsents(journey): void {
    this.clientService.getProductGroupsByClient(this.sessionService.currentUser.client.id, 100, 0, new Ordering('created_at'), true)
      .subscribe(
        (productGs: ProductGroup[]) => {
          _.each(productGs, (pg: ProductGroup) => {
            if (pg.consent.in_wire_upgrade.enabled) {
              this.hasIWCU = true;
            }
          });
        }
      );
  }
}
