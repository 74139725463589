import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import React from 'react';
import ReactDOM from 'react-dom';
import SetPasswordPagePreview from './SetPasswordPagePreview';
import type { ProductGroup } from '@app/core/models/client';

@Component({
  selector: 'set-password-page-preview-react',
  templateUrl: './set-password-page-preview-react-container.component.html',
  styleUrls: ['./set-password-page-preview-react-container.component.scss'],
})
export class SetPasswordPagePreviewReactContainerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  constructor() {}

  reactWrapperId: string;
  @Input() pswMessageText: ProductGroup['password']['label'];
  @Input() pswButtonLabel: ProductGroup['password']['label'];
  @Input() pswTCS: ProductGroup['password']['tcs'];
  @Input() pswTCMandatory: ProductGroup['password']['tc_mandatory'];
  @Input() logoUrl: ProductGroup['branding']['icon_s3_url'];
  @Input() color: ProductGroup['branding']['color'];
  @Input() productGroupName: ProductGroup['name'];
  @Input() showProductGroup: ProductGroup['hide_pg_wire_display_name'];
  @Input() productGroupDescription: ProductGroup['description'];

  ngOnInit() {
    this.reactWrapperId = 'set-password-page-preview';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(SetPasswordPagePreview, {
          pswMessageText: this.pswMessageText,
          pswButtonLabel: this.pswButtonLabel,
          pswTCS: this.pswTCS,
          pswTCMandatory: this.pswTCMandatory,
          logoUrl: this.logoUrl,
          color: this.color,
          showProductGroup: this.showProductGroup,
          productGroupName: this.productGroupName,
          productGroupDescription: this.productGroupDescription,
        }),
        this.getRootDomNode(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.render();
  }
}
