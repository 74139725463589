import {
  Header,
  StyleProvider,
  defaultBrandingData,
} from '@relaynetwork/design-system';
import React, { useMemo } from 'react';

type ProductGroupPreviewProps = {
  showProductGroup: boolean;
  productGroupName: string;
  productGroupDescription?: string;
  bannerUrl?: string;
  logoUrl: string;
  color: string;
  isPreview?: boolean;
};
export const FeedHeader = ({
  productGroupDescription = '',
  showProductGroup,
  productGroupName,
  bannerUrl = '',
  logoUrl,
  color,
  isPreview,
}: ProductGroupPreviewProps) => {
  const brandingData = useMemo(
    () => ({
      ...defaultBrandingData,
      color: color,
    }),
    [color],
  );

  return (
    <StyleProvider brandingData={brandingData}>
      <Header
        productGroupName={productGroupName}
        productGroupDescription={productGroupDescription}
        bannerUrl={bannerUrl}
        logoUrl={logoUrl}
        showLogo
        showBanner={!!bannerUrl}
        showShortNavBar={showProductGroup}
        showRegularNavBar
        isPreview={isPreview}
        isMobile
      />
    </StyleProvider>
  );
};
