<ng-container *ngIf="!!actionInput">
  <div
    class="action-link row"
    *ngIf="!(actionInput.type === 'two_way' && !twoWayService.showTwoWay())"
  >
    <!-- Disable move up/down or remove for disclaimer action -->
    <div class="col-12" *ngIf="actionInput.type !== 'disclaimer'">
      <label>{{ labelHeader }}</label>
      <div class="pull-right">
        <i
          *ngIf="canMoveUp"
          class="fa fa-arrow-circle-up message-action"
          aria-hidden="true"
          (click)="moveActionUp()"
          id="move-action-up-{{ i }}"
        ></i>
        <i
          *ngIf="canMoveDown"
          class="fa fa-arrow-circle-down message-action"
          aria-hidden="true"
          (click)="moveActionDown()"
          id="move-action-down-{{ i }}"
        ></i>
        <i
          class="fa fa-minus-circle message-action"
          aria-hidden="true"
          (click)="deleteAction()"
          id="delete-action-{{ i }}"
        ></i>
        <i
          *ngIf="
            actionInput.type === 'plaid' &&
            featureService.checkFlag(featureFlags.plaid_integration)
          "
          class="fa fa-money"
        ></i>
      </div>
    </div>

    <div class="col-sm-2 actions" *ngIf="actionInput.type !== 'disclaimer'">
      <!-- .btn-inactive will disable all kinds of events on this div -->
      <div class="btn btn-lg btn-primary btn-inactive">
        <i
          *ngIf="actionInput.type === 'hyperlink'"
          class="fa fa-link"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="actionInput.type === 'collapsible'"
          class="fa re-collapsible"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="actionInput.type === 'call'"
          class="fa fa-phone"
          aria-hidden="true"
        ></i>
        <img
          *ngIf="actionInput.type === 'email'"
          src="/assets/icons/icon-EmailAction.png"
        />
        <i
          *ngIf="actionInput.type === 'message'"
          class="fa fa-code-fork turn90"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="actionInput.type === 'consent_upgrade'"
          class="fa fa-angle-double-up"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="actionInput.type === 'two_way' && twoWayService.showTwoWay()"
          class="fa fa-comments"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="
            actionInput.type === 'plaid' &&
            featureService.checkFlag(featureFlags.plaid_integration)
          "
          class="fa fa-money"
        ></i>
        <i
          *ngIf="
            actionInput.type === 'apple_chat' &&
            featureService.checkFlag(featureFlags.apple_chat)
          "
          class="fa fa-apple"
        ></i>
        <i
          *ngIf="actionInput.type === 'email_data_capture'"
          class="fa-solid fa-at"
        ></i>
        <i
          *ngIf="
            actionInput.type === 'form_info_capture' &&
            featureService.checkFlag(featureFlags.jotform_info_capture)
          "
          class="fa fa-wpforms"
        ></i>
      </div>
    </div>

    <ng-container *ngIf="actionInput.type === 'hyperlink'">
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(this.formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-link-label-{{ i }}"
              class="form-control action-link-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label">URL</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['value'].valid &&
                formReference.controls['value'].touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['link_value_' + i]?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-link-value-{{ i }}"
              class="form-control action-link-value"
              type="url"
              (blur)="formatUrl(formReference.controls['value'])"
              [formControl]="formReference.controls['value']"
              spellcheck="false"
            />
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-sm-9 offset-3 checkbox-container">
            <rn-checkbox
              [inputName]="'action-link-open-in-new-tab-' + i"
              [checkboxModel]="openInNewTabFrmCtl.value"
              (checkboxModelChange)="
                openInNewTabFrmCtl.patchValue(!openInNewTabFrmCtl.value)
              "
            >
            </rn-checkbox>
            <label class="form-label">Open URL in new tab</label>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'collapsible'">
      <div class="col-sm-10">
        <div class="row action-collapsible">
          <label class="col-sm-3 form-label">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              mentionInput
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-collapsible-label-{{ i }}"
              class="form-control action-collapsible-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-sm-12"
        [ngClass]="{
          'has-error':
            !formReference.controls['value']?.valid &&
            formReference.controls['value']?.touched
        }"
      >
        <label style="visibility: hidden">Collapsible Text</label>
        <span
          class="error-msg"
          *ngIf="
            !formReference.controls['value']?.valid &&
            formReference.controls['value']?.touched
          "
          tooltip="{{ getTooltipData(formReference.controls['value'].errors) }}"
          placement="bottom"
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </span>
        <!-- TODO: use reactive or template forms - not both. See: https://angular.io/api/forms/FormControlDirective#use-with-ngmodel -->
        <app-tiny-editor
          [attr.name]="'action-collapsible-value-' + i"
          class="tiny-editor-mentions action-collapsible-value"
          [elementId]="'tiny-editor-action-collapsible-value-' + i"
          [formControl]="formReference.controls['value']"
          [config]="tinyEditorConfig"
        >
        </app-tiny-editor>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'call'">
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              disableAutoComplete
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-call-label-{{ i }}"
              class="form-control action-call-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label">Phone #</label>
          <div
            class="col-sm-9"
            [class.has-error]="
              !formReference.controls['value']?.valid &&
              formReference.controls['value']?.touched
            "
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['value']?.errors)
              }}"
              placement="left"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              disableAutoComplete
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-call-value-{{ i }}"
              class="form-control action-call-value"
              type="tel"
              (blur)="formatPhoneNumber(formReference.controls['value'])"
              [formControl]="formReference.controls['value']"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'email'">
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              disableAutoComplete
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-email-label-{{ i }}"
              class="form-control action-email-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label">Email</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['value']?.errors)
              }}"
              placement="left"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              disableAutoComplete
              [mention]="getItems(actionInput.value)"
              [mentionConfig]="mentionConfig"
              name="action-email-value-{{ i }}"
              class="form-control action-email-value"
              type="text"
              [formControl]="formReference.controls['value']"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'message'">
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              name="action-message-label-{{ i }}"
              class="form-control action-message-label"
              type="text"
              (blur)="bindNickname(actionInput)"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="actionInput.type === 'consent_upgrade' && productGroup"
    >
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              mentionInput
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="iwcu-label-{{ i }}"
              class="form-control action-iwcu-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <p *ngIf="newFeed.enabled && !actionInput.primary">NOTE: Consent upgrade will be embedded within the message by default. If you wish to open it in a separate window instead, please check the box below.</p>
        <p *ngIf="newFeed.enabled && actionInput.primary">NOTE: Consent upgrade will open in a separate window</p>
      </div>
      <div class="col-sm-10">
          <div class="row clearfix" *ngIf="newFeed.enabled && !actionInput.primary">
            <div class="col-sm-10 offset-4 checkbox-container">
              <rn-checkbox
                [inputName]="'action-consent-upgrade-within-card-' + i"
                [checkboxModel]="openConsentTCsFrmCtl.value"
                (checkboxModelChange)="
                  openConsentTCsFrmCtl.patchValue(
                    !openConsentTCsFrmCtl.value
                  )
                "
                matTooltip="Only recommended for forms that contain four or fewer questions per page"
                matTooltipPosition="left"
              >
              </rn-checkbox>
              <label class="form-label">Open consent upgrade in a separate window</label>
            </div>
          </div>
      </div>
          <div class="row" *ngIf="journey.draft.product_group !== 'all'">
        <div
          class="col-sm-12"
          [ngClass]="{
            'has-error':
              !formReference.controls['value']?.valid &&
              formReference.controls['value']?.touched
          }"
        >
          <label>{{
            newFeed.enabled
              ? 'Consent Upgrade Ts&Cs'
              : 'In Wire Consent Upgrade Ts&Cs'
          }}</label>
          <span
            class="error-msg"
            *ngIf="
              !formReference.controls['value']?.valid &&
              formReference.controls['value']?.touched
            "
            tooltip="{{
              getTooltipData(formReference.controls['value'].errors)
            }}"
            placement="bottom"
          >
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </span>
          <div>
            <p
              [innerHtml]="
                productGroup.consent.in_wire_upgrade.ts_cs | safeHtml
              "
            ></p>
            <span
              >Terms & Conditions text is set globally within client
              configuration</span
            >
          </div>
        </div>
      </div>

      <div class="row" *ngIf="journey.draft.product_group === 'all'">
        <div class="col-sm-12">
          <label
            >Terms and Conditions will show for the user's default product
            group</label
          >
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="actionInput.type === 'two_way' && twoWayService.showTwoWay()"
    >
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              name="action-message-label-{{ i }}"
              class="form-control action-two-way-label"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action">Tag Value</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['value']?.valid &&
                formReference.controls['value']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['value']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <app-spinner *ngIf="!twoWayTags"></app-spinner>

            <select
              *ngIf="twoWayTags"
              class="form-control"
              name="action-message-value-{{ i }}"
              [formControl]="formReference.controls['value']"
            >
              <option value="">Select</option>
              <option *ngFor="let tag of twoWayTags" [ngValue]="tag.tag_id">
                {{ tag.tag_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'plaid'">
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-plaid-label-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label action">Header</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['disclaimer_header']?.valid &&
                formReference.controls['disclaimer_header']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['disclaimer_header']?.valid &&
                formReference.controls['disclaimer_header']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['disclaimer_header']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-plaid-header-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['disclaimer_header']"
            />
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label action">Body</label>
          <div class="col-sm-9">
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['disclaimer_body']?.valid &&
                formReference.controls['disclaimer_body']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['disclaimer_body']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <textarea
              name="action-plaid-body-{{ i }}"
              cols="30"
              rows="10"
              class="form-control"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['disclaimer_body']"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label action">Accept Label</label>
          <div class="col-sm-9">
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['disclaimer_check_label_' + i]?.valid &&
                formReference.controls['disclaimer_check_label_' + i]?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['disclaimer_check_label_' + i]?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-plaid-accept-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['disclaimer_check_label']"
            />
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 form-label action">Submit Label</label>
          <div class="col-sm-9">
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['disclaimer_submit_label']?.valid &&
                formReference.controls['disclaimer_submit_label']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['disclaimer_submit_label']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-plaid-submit-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['disclaimer_submit_label']"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionInput.type === 'email_data_capture'">
      <div class="col-sm-10">
        <div class="row">
          <action-text-input
            fieldLabel="Label"
            name="action-email-data-capture-label-{{ i }}"
            [control]="formReference.controls['label']"
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
          <p *ngIf='newFeed.enabled' align='right' class="col-12">
            <span
            ><b>{{
              getPrimarySecondaryCharLimit(actionInput) -
              actionInput.label.length
              }}</b>
            </span>
            of <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b> characters
            remaining
          </p>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Header"
            name="action-email-data-capture-header-{{ i }}"
            [control]="formReference.controls['header']"
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <label
            for="action-email-data-capture-body-{{ i }}"
            class="col-sm-3 form-label action"
            >Body</label
          >
          <div class="col-sm-9">
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['body']?.valid &&
                formReference.controls['body']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['body']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <textarea
              id="action-email-data-capture-body-{{ i }}"
              name="action-email-data-capture-body-{{ i }}"
              cols="30"
              rows="10"
              class="form-control"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['body']"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Email ID"
            name="action-email-data-capture-email-id-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[0].controls['dc_id']
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Email Label"
            name="action-email-data-capture-email-label-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[0].controls['dc_label']
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Verify Label"
            name="action-email-data-capture-verify-label-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[0].controls[
                'dc_verify_label'
              ]
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-9">
            <label
              class="form-label"
              tooltip="activating enables a double entry of the field to ensure the data is correct"
            >
              <input
                name="action-email-data-capture-enable-double-verification-{{
                  i
                }}"
                type="checkbox"
                [formControl]="
                  formReference.controls['dc'].controls[0].controls['dc_verify']
                "
              />
              Enable double field verification
            </label>
          </div>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Confirmation ID"
            name="action-email-data-capture-confirmation-id-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[1].controls['dc_id']
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Accept Label"
            name="action-email-data-capture-accept-label-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[1].controls[
                'dc_accept_label'
              ]
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Success Text"
            name="action-email-data-capture-success-text-{{ i }}"
            [control]="formReference.controls['success_text']"
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Failure Text"
            name="action-email-data-capture-failure-text-{{ i }}"
            [control]="formReference.controls['failure_text']"
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
        <div class="row">
          <action-text-input
            fieldLabel="Decline Label"
            name="action-email-data-capture-decline-label-{{ i }}"
            [control]="
              formReference.controls['dc'].controls[1].controls[
                'dc_decline_label'
              ]
            "
            [actionMention]="dynamicInputs"
            [actionMentionConfig]="mentionConfig"
            [i]="i"
            class="w-100"
          >
          </action-text-input>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        actionInput.type === 'apple_chat' &&
        featureService.checkFlag(featureFlags.apple_chat)
      "
    >
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label action">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-label-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action">Phone</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['apple_chat_phone']?.valid &&
                formReference.controls['apple_chat_phone']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['apple_chat_phone']?.valid &&
                formReference.controls['apple_chat_phone']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['apple_chat_phone']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-apple_chat_phone-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              (blur)="
                formatPhoneNumber(formReference.controls['apple_chat_phone'])
              "
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['apple_chat_phone']"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action"
            >Business ID (optional)</label
          >
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['apple_chat_business_id']?.valid &&
                formReference.controls['apple_chat_business_id']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['apple_chat_business_id']?.valid &&
                formReference.controls['apple_chat_business_id']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['apple_chat_business_id']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              name="action-apple_chat_business_id-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['apple_chat_business_id']"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action"
            >Business Intent (optional)</label
          >
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['apple_chat_biz_intent_id']?.valid &&
                formReference.controls['apple_chat_biz_intent_id']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['apple_chat_biz_intent_id']?.valid &&
                formReference.controls['apple_chat_biz_intent_id']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['apple_chat_biz_intent_id']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              name="action-apple_chat_biz_intent_id-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['apple_chat_biz_intent_id']"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action"
            >Business Group (optional)</label
          >
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['apple_chat_biz_group_id']?.valid &&
                formReference.controls['apple_chat_biz_group_id']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['apple_chat_biz_group_id']?.valid &&
                formReference.controls['apple_chat_biz_group_id']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['apple_chat_biz_group_id']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              name="action-apple_chat_biz_group_id-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['apple_chat_biz_group_id']"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 form-label action">Body (optional)</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['apple_chat_body']?.valid &&
                formReference.controls['apple_chat_body']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['apple_chat_body']?.valid &&
                formReference.controls['apple_chat_body']?.touched
              "
              tooltip="{{
                getTooltipData(
                  formReference.controls['apple_chat_body']?.errors
                )
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>

            <input
              name="action-apple_chat_body-{{ i }}"
              class="form-control"
              type="text"
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              [formControl]="formReference.controls['apple_chat_body']"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        actionInput.type === 'form_info_capture' &&
        featureService.checkFlag(featureFlags.jotform_info_capture)
      "
    >
      <div class="col-sm-10">
        <div class="row">
          <label class="col-sm-3 form-label">Label</label>
          <div
            class="col-sm-9"
            [ngClass]="{
              'has-error':
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
            }"
          >
            <span
              class="error-msg"
              *ngIf="
                !formReference.controls['label']?.valid &&
                formReference.controls['label']?.touched
              "
              tooltip="{{
                getTooltipData(this.formReference.controls['label']?.errors)
              }}"
              placement="bottom"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <input
              [mention]="dynamicInputs"
              [mentionConfig]="mentionConfig"
              name="action-form-info-capture-label-{{ i }}"
              class="form-control action-form-info-capture-label"
              type="text"
              [formControl]="formReference.controls['label']"
            />
            <p *ngIf='newFeed.enabled' align='right'>
              <span
              ><b>{{
                getPrimarySecondaryCharLimit(actionInput) -
                actionInput.label.length
                }}</b>
              </span>
              of
              <b>{{ getPrimarySecondaryCharLimit(actionInput) }}</b>
              characters remaining
            </p>
          </div>
        </div>
        <div class="row clearfix" *ngIf="newFeed.enabled">
          <div class="col-sm-9 offset-3 checkbox-container">
            <rn-checkbox
              [inputName]="'action-powerups-open-within-card-' + i"
              [checkboxModel]="openWithinMessageFrmCtl.value"
              (checkboxModelChange)="
                openWithinMessageFrmCtl.patchValue(
                  !openWithinMessageFrmCtl.value
                )
              "
              matTooltip="Only recommended for forms that contain four or fewer questions per page"
              matTooltipPosition="left"
            >
            </rn-checkbox>
            <label class="form-label">Embed Power-Up form within message</label>
          </div>
        </div>
        <div class="row">
          <rn-power-ups-action
            [formReference]="formReference"
          ></rn-power-ups-action>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="actionInput.type === 'disclaimer'">
      <div
        [ngClass]="{
          'has-error':
            !formReference.controls['value']?.valid &&
            formReference.controls['value']?.touched
        }"
        class="col-12"
      >
        <span
          class="error-msg"
          *ngIf="
            !formReference.controls['value']?.valid &&
            formReference.controls['value']?.touched
          "
          tooltip="{{ getTooltipData(formReference.controls['value'].errors) }}"
          placement="bottom"
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </span>
        <app-tiny-editor
          [attr.name]="'action-disclaimer-value-' + i"
          class="tiny-editor-mentions action-disclaimer-value"
          [elementId]="'tiny-editor-action-disclaimer-value-' + i"
          [formControl]="formReference.controls['value']"
        >
        </app-tiny-editor>
      </div>
    </ng-container>
  </div>
</ng-container>
