import { AuthGuardService } from '../security/auth-guard.service';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientInfoNewComponent } from './client-form/client-info/client-info-new.component';
import { ClientConfigContainerComponent } from './client-form/client-config/client-config-container.component';
import { ClientInfoEditComponent } from './client-form/client-info/client-info-edit.component';
import { ClientImagesNewComponent } from './client-form/client-images/client-images-new.component';
import { ClientInputsNewComponent } from './client-form/client-inputs/client-inputs-new.component';
import { ClientOnboardingAndFeatureComponent } from './client-form/client-onboarding-and-feature/client-onboarding-and-feature.component';
import { ProductGroupsListComponent } from './product-group/product-groups-list/product-groups-list.component';
import { ProductGroupsEditComponent } from './product-group/edit/edit.component';
import { ProductReportComponent } from './report/product-report/product-report.component';
import { ProductLegalComponent } from 'app/client-config/product-group/legal/legal.component';
import { ClientValidationAndVerificationComponent } from 'app/client-config/client-form/client-validation-and-verification/client-validation-and-verification.component';
import { Permissions } from '../core/services/permission.service';
import { ClientConfigReportComponent } from './report/client-config-report/client-config-report.component';
import { ProductGroupOnboardingComponent } from '@app/client-config/product-group/onboarding/onboarding.component';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { ClientPowerUpsConfigComponent } from './client-form/client-power-ups-config/client-power-ups-config.component';

export const ClientConfigRoutes = [
  {
    path: 'client',
    component: ClientConfigContainerComponent,
    data: {perm: Permissions.ui_client_list},
    children: [
      {
        path: '',
        component: ClientListComponent,
        canActivate: [AuthGuardService],
        data: {perm: Permissions.ui_client_list}
      },
      {
        path: 'new',
        component: ClientInfoNewComponent,
        canActivate: [AuthGuardService],
        data: {step: 'client-info', perm: Permissions.client_create}
      },
      {
        path: ':clientId/edit',
        component: ClientInfoEditComponent,
        canActivate: [AuthGuardService],
        data: { step: 'client-info', perm: Permissions.client_edit}
      },
      {
        path: ':clientId/edit/branding',
        component: ClientImagesNewComponent,
        canActivate: [AuthGuardService],
        data: { step: 'client-info', perm: Permissions.client_edit}
      },
      {
        path: ':clientId/edit/inputs',
        component: ClientInputsNewComponent,
        canActivate: [AuthGuardService],
        data: { step: 'client-info', perm: Permissions.client_edit}
      },
      {
        path: ':clientId/edit/validation',
        component: ClientValidationAndVerificationComponent,
        canActivate: [AuthGuardService],
        data: { step: 'client-info', perm: Permissions.client_edit}
      },
      {
        path: ':clientId/edit/onboarding',
        component: ClientOnboardingAndFeatureComponent,
        canActivate: [AuthGuardService],
        data: { step: 'client-info', perm: Permissions.client_edit}
      },
      {
        path: ':clientId/edit/power-ups-config',
        component: ClientPowerUpsConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          step: 'client-info', 
          perm: Permissions.client_edit,
          flag: FeatureFlags.power_ups_form_config
        }
      },
      {
        path: ':clientId/product-group',
        component: ProductGroupsListComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_edit}
      },
      {
        path: ':clientId/product-group/new',
        component: ProductGroupsEditComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_create}
      },
      {
        path: ':clientId/product-group/:productId',
        component: ProductGroupsEditComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_edit}
      },
      {
        path: ':clientId/product-group/:productId/legal',
        component: ProductLegalComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_edit}
      },
      {
        path: ':clientId/product-group/:productId/onboarding',
        component: ProductGroupOnboardingComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_edit}
      },
      {
        path: ':clientId/product-group/:productId/report',
        component: ProductReportComponent,
        canActivate: [AuthGuardService],
        data: { step: 'product-group', perm: Permissions.product_group_edit}
      },
      {
        path: ':clientId/report',
        component: ClientConfigReportComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.client
        }
      }
    ]
  }
];
