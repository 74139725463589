import { Routes } from '@angular/router';
import { ApiMgmtComponent } from '@app/api-mgmt/components/api-mgmt/api-mgmt.component';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { Permissions } from '@app/core/services/permission.service';
import { AuthGuardService } from '@app/security/auth-guard.service';
import { ApikeyMgmtComponent } from '@app/api-mgmt/components/apikey-mgmt/apikey-mgmt.component';
import { PushApiComponent } from './components/push-api/components/push-api.component';

export const ApiManagementRoutes: Routes = [
  { 
    path: 'api-mgmt', 
    component: ApiMgmtComponent, 
    data: {perm: Permissions.ui_api_mgmt},
    children: [
      {
        path: '',
        component: ApiMgmtComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.apikey_all
        }
      },
      {
        path: 'apikey-mgmt',
        component: ApikeyMgmtComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.apikey_all
        }
      },
      {
        path: 'push-api',
        component: PushApiComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.apikey_all,
          flag: FeatureFlags.push_api_client
        }
      }
    ]
  }];

