import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SecureHttp } from '@app/security/secure-http';
import { LoggerService } from '@app/core/services/logger.service';
import * as _ from 'lodash';
import { SessionService } from '@app/security/session.service';
import { switchMap } from 'rxjs/operators';

export type Status = 'idle' | 'busy' | 'error';
@Injectable()
export class PdfService {
  status: Subject<Status> = new Subject();

  constructor(
    private http: SecureHttp,
    private sessionService: SessionService
  ) {
    this.status.next('idle');
  }

  /**
   * convert a Unicode string to a string in which each 16-bit unit occupies only one byte
   * @param string
   * @returns string
   */
  toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(Number('0x' + p1));
      })
    );
  }

  requestPdf(pages: HTMLCollectionOf<Element>, title: string) {
    this.status.next('busy');
    const documentHTML = new XMLSerializer().serializeToString(document);

    const convertedHtml = this.b64EncodeUnicode(documentHTML);

    this.sessionService.selectedClient
      .pipe(
        switchMap((clientId) => {
          return this.http.post(
            `/platform/v3/gw/pdf/client/${clientId}/generate`,
            {
              html: convertedHtml,
              title: this.parameterize(title),
            }
          );
        })
      )
      .subscribe(
        (response) => {
          this.status.next('idle');
          window.open(response['pdf']);
        },
        (error) => {
          this.status.next('error');
          LoggerService.log(
            'PdfService',
            `getPdf error: ${JSON.stringify(error)}`
          );
        }
      );
  }

  private parameterize(str: string): string {
    return `${str.replace(/ /g, '_').toLowerCase()}`;
  }
}
