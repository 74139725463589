<div class="user-list-content">

    <div class="list-header-container">
      <div *ngIf='this.sso'>
        <br />
        <div>
          <p style='color: #f93030; margin: 0 0 0 25px;'>
            <b
            >If your organization uses Single
              Sign-On, creation and deletion of Relay CXB users is managed by that
              method. <br />
              If you attempt to create or delete users here, you may experience
              inconsistent results. Please contact your SSO administrator for
              assistance.</b
            >
          </p>
        </div>
      </div>
      <div class="list-header">
        <app-search-bar [isFixed]="fixedHeader"
                        [searchableFields]="searchableFields"
                        (onRequestSearch)="onRequestSearch($event)"
                        (onClearSearch)="onClearSearch()">
          <div class="delete-action">
            <div class="btn btn-primary" (click)="openModal()"><span class="icon icon-left"><i class="fa fa-trash"></i></span><span class="button-text">Delete Users</span></div>
          </div>
        </app-search-bar>
      </div>
    </div>
    <div class="container-fluid user-list"
         [class.user-list--fixed]="fixedHeader"
         data-infinite-scroll
         debounce
         [infiniteScrollDistance]="scrollDistance"
         [infiniteScrollThrottle]="throttle"
         (scrolled)="onScrollDown()">

      <div class="row colhdrs">
        <div class="colw colw-3 column-heading">
          &nbsp;
        </div>
        <div id="user-name" class="colw colw-20 column-heading no-text-selection" (click)="onColumnHeadingClick('data.first_name')">
          <span i18n="User name column heading">Name</span>
          <app-column-sort-icon id="sort-icon-name" [ordering]="ordering" [fieldName]="'data.first_name'"></app-column-sort-icon>
        </div>
        <div id="user-email" class="colw colw-20 column-heading no-text-selection" (click)="onColumnHeadingClick('data.email_address')">
          <span i18n="User email column heading">Email Address</span>
          <app-column-sort-icon id="sort-icon-email" [ordering]="ordering" [fieldName]="'data.email_address'"></app-column-sort-icon>
        </div>
        <div id="role" class="colw colw-14 column-heading no-text-selection" (click)="onColumnHeadingClick('data.role_id')">
          <span i18n="User modified at column heading">Role</span>
          <app-column-sort-icon id="sort-icon-role" [ordering]="ordering" [fieldName]="'data.role_id'"></app-column-sort-icon>
        </div>
        <div id="control-group" class="colw colw-12 column-heading no-text-selection" *ngIf="twoWayService.showTwoWay()" (click)="onColumnHeadingClick('group_name')">
          <span>Control Group</span>
          <!--<app-column-sort-icon id="sort-icon-control-group" [ordering]="ordering" [fieldName]="group_name"></app-column-sort-icon>-->
        </div>
        <div id="user-created-at" class="colw colw-10 column-heading no-text-selection" (click)="onColumnHeadingClick('created_at')">
          <span i18n="User created at column heading">Date Created</span>
          <app-column-sort-icon id="sort-icon-created-at" [ordering]="ordering" [fieldName]="'created_at'"></app-column-sort-icon>
        </div>
        <div id="user-last-login" class="colw colw-10 column-heading no-text-selection" (click)="onColumnHeadingClick('data.last_login')">
          <span i18n="User last login column heading">Last Login</span>
          <app-column-sort-icon id="sort-icon-last-login" [ordering]="ordering" [fieldName]="'data.last_login'"></app-column-sort-icon>
        </div>
      </div>

      <ng-container *ngFor="let user of users">
        <div class="row user-row">
          <div class="colw colw-3">
            <div *ngIf="user.id !== currentUserId">
              <rn-checkbox [inputName]="'user-checkbox-'+user.id" [checkboxModel]='user.selected'
                           (checkboxModelChange)='selectUser(user, $event)'></rn-checkbox>
            </div>
          </div>
          <div class="colw colw-20 strong break-word">
            <span class="full-name">{{user.first_name}} {{user.last_name}}</span>
          </div>
          <div class="colw colw-20 break-word">
            {{user.email_address}}
          </div>

          <div class="colw colw-14">
            <div *ngFor="let role_id of user.role_id">
              <span *ngIf="role_id === 'two_way_admin' && currentClient.feed_enabled">Messenger Admin </span>
              <span *ngIf="role_id === 'two_way_user' && currentClient.feed_enabled">Messenger User </span>
              <span *ngIf="role_id === 'two_way_admin' && !currentClient.feed_enabled">Two Way Admin </span>
              <span *ngIf="role_id === 'two_way_user' && !currentClient.feed_enabled">Two Way User </span>
              <span *ngIf="role_id !== 'two_way_admin' && role_id !== 'two_way_user'" >  {{role_id | inputField}} </span>
            </div>
          </div>

          <div *ngIf="twoWayService.showTwoWay()" class="colw colw-12">
            <app-spinner *ngIf="!groups" [small]="true" ></app-spinner>
            <ng-container *ngIf="groups">
              {{getGroupForUser(user)}}
            </ng-container>
          </div>

          <div class="colw colw-10">
            {{user.created_at | date:'shortDate'}}
          </div>
          <div class="colw colw-10">
            {{user.last_login | date:'shortDate'}}
          </div>
          <div class="colw colw-10" (click)="editUserClicked(user.id)">
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="loaded && users.length == 0" class="not-found" i18n="Message that the user gets when there are no users to show in the list">There were no users found.</div>
  </div>

<div class="modal" *ngIf="showModal" style="display: block;">
    <app-user-reset [config]="deleteUsersConfig()" (closeWindow)="closeModal($event)"></app-user-reset>
  </div>
  <message-dialog></message-dialog>
