<ng-container *ngIf="haveEligibleTriggers && !hasOnlyArchived">
  <h3>Capture the user's information</h3>
  <p>When the caller's main issue is resolved, end the call by selecting an experience and confirming their information
    to enroll them into secure mobile messaging.</p>
  <div class="script-content">
    <div class="journey-list-phone csr-step">
      <div>
        <div class="number">1.</div>
        <div class="csr-script">Select the experience</div>
      </div>
      <div class="journey-list">
        <ng-container *ngFor="let journey of journeys;let i = index">
          <app-journey-icon class="icon-wrapper" [isPreview]="true" [class.selected]="journey.id === selectedJourneyId"
                            [selected]="journey.id === selectedJourneyId"
                            (click)="selectJourney(journey.id)" [journeyId]="journey.id"
                            [backgroundColor]="colors[(i % colors.length)]" [clientId]="client.id"></app-journey-icon>
        </ng-container>
      </div>
      <div class="journey-phone">
        <phone *ngIf="selectedJourneyId" [screen]="screen" [message]="getMessage" [components]="getComponents" [productGroup]="productGroup"></phone>
      </div>
    </div>
    <br/>
    <div class="csr-step">
      <div>
        <div class="number">2.</div>
        <div class="csr-script csr-script--text">{{client.onboarding.csr.capture_written_consent==='true' ?
          client.onboarding.csr.written_consent_language:
          client.onboarding.csr.express_consent_language}}</div>
      </div>
      <br/>
      <div class="form">
        <div class="input-control"
              [ngClass]="{'has-error': !validationGroup.controls['phone_number'].valid && validationGroup.controls['phone_number'].touched}">
          <label>Mobile number </label>
          <span class="error-msg"
                *ngIf="!validationGroup.controls['phone_number'].valid && validationGroup.controls['phone_number'].touched"
                placement="bottom">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </span>
          <input class="form-control" name="phone"
                  [formControl]="validationGroup.controls['phone_number']"
                  [ngClass]="{'has-error':!validationGroup.controls['phone_number'].valid && validationGroup.controls['phone_number'].dirty}"
                  (keyup)="checkPhoneNumber()" [readonly]="preview"/>
        </div>
      </div>
    </div>
    <div class="csr-step">
      <div>
        <div class="number">3.</div>
        <div class="csr-script" [innerHtml]="client.onboarding.csr.validation_language"></div>
      </div>
      <br/>
      <div class="form">
        <div class="input-control"
             [ngClass]="{'has-error': !validationGroup.controls['ccid'].valid && validationGroup.controls['ccid'].touched}">
          <label>Account ID (no spaces)</label>
          <span class="error-msg"
                *ngIf="!validationGroup.controls['ccid'].valid && validationGroup.controls['ccid'].touched"
                placement="bottom">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          <input class="form-control" name="ccid" [formControl]="validationGroup.controls['ccid']"
                 [readonly]="preview"
                 [ngClass]="{'has-error':!validationGroup.controls['ccid'].valid && validationGroup.controls['ccid'].touched}"/>
        </div>

        <ng-container *ngFor="let val of client.validation.fields;let i = index">
          <ng-container *ngIf="!bypassValidation && shouldShowField(val.field, client.validation.fields)">
            <div class="input-control"
                [ngClass]="{'has-error':!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched}">
              <label>{{val.prompt}}</label>
              <span class="error-msg"
                    *ngIf="!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched"
                    placement="bottom">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span>
              <input *ngIf="val.field === 'ssn_last4'" name="{{val.field}}" maxlength="4"
                    [formControl]="validationGroup.controls[val.field]"
                    class="form-control" type="text" [readonly]="preview"
                    [ngClass]="{'has-error':!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched}"/>
              <input *ngIf="val.field === 'postal_code'" name="{{val.field}}" maxlength="5"
                    [formControl]="validationGroup.controls[val.field]" class="form-control" type="text"
                    [readonly]="preview"
                    [ngClass]="{'has-error':!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched}"/>
              <input *ngIf="val.field === 'first_name'  || val.field === 'last_name' || val.field === 'middle_name'"
                    name="{{val.field}}" [readonly]="preview"
                    [formControl]="validationGroup.controls[val.field]" class="form-control" type="text"
                    [ngClass]="{'has-error':!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched}"/>
              <select *ngIf="val.field === 'gender'" name="{{val.field}}"
                      [formControl]="validationGroup.controls[val.field]" class="form-control" [attr.disabled]="preview"
                      [ngClass]="{'has-error':!validationGroup.controls[val.field].valid && validationGroup.controls[val.field].touched}">
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
              <ng-container *ngIf="val.field === 'date_of_birth'">
                <input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [formControl]="validationGroup.controls[val.field]" placeholder="MM/DD/YYYY" class="form-control" name="{{val.field}}">
                <owl-date-time #dt1
                    id="schedule-time-picker"
                    [pickerType]="'calendar'"
                    [disabled]="preview">
                </owl-date-time>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let val of allValidationModel">
          <ng-container *ngIf="bypassValidation && shouldShowField(val.field, client.validation.information_fields)">
            <div class="input-control"
                [ngClass]="{'has-error':!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched}">
              <label>{{val.prompt}}</label>
              <span class="error-msg"
                    *ngIf="!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched"
                    placement="bottom">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span>
              <input *ngIf="val.field === 'ssn_last4'" name="{{val.field}}" maxlength="4"
                    [formControl]="allValidationGroup.controls[val.field]"
                    class="form-control" type="text"
                    [ngClass]="{'has-error':!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched}"/>
              <input *ngIf="val.field === 'postal_code'" name="{{val.field}}" maxlength="5"
                    [formControl]="allValidationGroup.controls[val.field]" class="form-control" type="text"
                    [readonly]="preview"
                    [ngClass]="{'has-error':!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched}"/>
              <input *ngIf="val.field === 'first_name'  || val.field === 'last_name' || val.field === 'middle_name'"
                    name="{{val.field}}"[readonly]="preview"
                    [formControl]="allValidationGroup.controls[val.field]" class="form-control" type="text"
                    [ngClass]="{'has-error':!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched}"/>
              <select *ngIf="val.field === 'gender'" name="{{val.field}}"
                      [formControl]="allValidationGroup.controls[val.field]" class="form-control" [attr.disabled]="preview"
                      [ngClass]="{'has-error':!allValidationGroup.controls[val.field].valid && allValidationGroup.controls[val.field].touched}">
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
              <ng-container *ngIf="val.field === 'date_of_birth'">
                <input id="schedule-time-picker" class="form-control" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" placeholder="MM/DD/YYYY" [formControl]="allValidationGroup.controls[val.field]" name="{{val.field}}">
                <owl-date-time #dt2
                      [pickerType]="'calendar'"
                      [disabled]="preview">
                </owl-date-time>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <hr/>
  <div class="pull-right next-btn" *ngIf="!preview">
    <button class="btn btn-primary" (click)="locateCustomer()" [disabled]="!isValid()">
      <span *ngIf="expressWrittenConsent === 'true' || !expressWrittenConsent">Next</span>
      <span *ngIf="expressWrittenConsent === 'false'">Submit</span>
      <span class="icon icon-right"><i class="fa fa-caret-right"></i></span>
    </button>
  </div>
</ng-container>


<div *ngIf="!haveEligibleTriggers" class="instructions">
  <h1>Agent onboarding is not currently configured for this client.</h1>
</div>
<div *ngIf="hasOnlyArchived && haveEligibleTriggers" class="instructions">
  <h1>Agent onboarding is currently configured for this client but all eligible triggers are archived.</h1>
</div>
<div *ngIf="error">{{error}}</div>
<message-dialog [showCancel]="false" #validationMessage></message-dialog>
<message-dialog (affirm)="startOver()" #consentMessage></message-dialog>
