import { AwsUtils } from '../utils/aws-utils';
import { Conversions } from '../utils/conversions';
import { ValidationClass, VerificationClass } from './validation-verification';
import { ConsentType } from '@app/core/models/customer';
import { FormatUtils } from '@app/core/utils/format-utils';
import { BlackoutWindow } from '@app/core/models/blackout-window';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import * as _ from 'lodash';
import { SmsResponseTypes } from '@app/core/models/sms-response-types';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { RotationIntervals } from '../utils/password-rotation-interval';
import { URLUtils } from '../utils/url-utils';

export class Contact {
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;

  static deserialize(input: Object): Contact {
    const contact = new Contact();
    contact.first_name = input['first_name'];
    contact.last_name = input['last_name'];
    contact.email = input['email'];
    contact.mobile_number = input['mobile_number'];
    return contact;
  }
}

export class Branding {
  icon_s3_url: string = new URL('rn-public/media-library/ip-10-2-4-56/anexinet/image/af88fe82-c3fd-4921-ad51-6b8949f7a850/Portal-Icon-Default.png', window.location.origin).toString();
  banner_s3_url: string = new URL('rn-public/media-library/ip-10-2-4-56/anexinet/image/66f7666b-7b56-43ba-bd20-4a892d804720/Portal-Banner-Default.png', window.location.origin).toString();
  banner_alt_text: string;
  banner_asset_id: string;
  color: string = '#807f73';

  static deserialize(input: Object): Branding {
    const branding = new Branding();
    branding.icon_s3_url = AwsUtils.addHostName(input['icon_s3_url']);
    branding.banner_s3_url = AwsUtils.addHostName(input['banner_s3_url']);
    branding.banner_alt_text = input['banner_alt_text'];
    branding.banner_asset_id = input['banner_asset_id'];
    branding.color = input['color'];
    return branding;
  }
}

export class Reporting {
  looker_enabled: boolean;
  loop_reports_enabled: boolean;

  static deserialize(input: Object): Reporting {
    const reporting = new Reporting();
    reporting.looker_enabled = input['looker_enabled'];
    reporting.loop_reports_enabled = input['loop_reports_enabled'];
    return reporting;
  }
}

interface PlaidEnrollment {
  public_key: string;
  products: string;
  country_codes: string;
  env: 'sandbox' | 'development' | 'production';
  client_name: string;
}

interface Enrollment {
  plaid: PlaidEnrollment;
}

export class ExternalRegistration {
  url_match_pattern: string;
}

export class ExternalConfig {
  registration?: ExternalRegistration;
  enrollment?: Enrollment;
}

interface PasswordConfiguration {
  rotation_enabled: boolean;
  rotation_interval: number;
}

export class Client {
  blackout_window = new BlackoutWindow();
  branding = new Branding();
  company_name: string;
  created_at: Object;
  ext: Object;
  ext_dynamic_fields: string[] = [];
  gpg_info = new GpgInfo();
  id: string;
  is_active: boolean;
  job_processing = new JobProcessing();
  onboarding: ClientOnboardingSection = new ClientOnboardingSection();
  password_required: string;
  primary_contact = new Contact();
  two_way_config: TwoWayConfig = new TwoWayConfig(); // this is kicking me out of my session for some reason??
  reporting: Reporting;
  updated_at: Object;
  validation: ValidationClass = new ValidationClass();
  password_configuration: PasswordConfiguration = {
    rotation_enabled: true,
    rotation_interval: RotationIntervals._90DAYS.value,
  };
  verification: VerificationClass = new VerificationClass();
  mobile_analysis: MobileAnalysis = new MobileAnalysis();
  external_config: ExternalConfig;
  sso_config: SSOConfig = new SSOConfig();
  fe_upload_enabled: boolean = false;
  sms_keywords: SmsResponseTypes; // leave undefined, if initialized it will not get the default values on clientService.createClient() call
  sms_max_length: number;
  automated_file_processing: boolean;
  industry?: string;
  company_type?: string;
  use_ascii_armor: boolean = false;
  show_sms_by_default: boolean = true;
  feed_enabled: boolean = false;

  static deserialize(input: Object): Client {
    const client = new Client();
    client.id = input['id'];
    client.is_active = input['is_active'];
    client.company_name = input['company_name'];
    client.created_at = new Date(input['created_at']);
    client.updated_at = new Date(input['updated_at']);
    client.password_required = Conversions.toString(input['password_required']);
    client.two_way_config = input['two_way_config']
      ? new TwoWayConfig(input['two_way_config'])
      : new TwoWayConfig();
    client.fe_upload_enabled = input['fe_upload_enabled'] || false;
    client.sms_max_length =
      input['sms_max_length'] || CharacterLimits.SmsTextCharLimit;
    client.automated_file_processing =
      input['automated_file_processing'] === undefined
        ? true
        : input['automated_file_processing'];
    client.industry = input['industry'] ?? '';
    client.company_type = input['company_type'] ?? '';
    client.use_ascii_armor = input['use_ascii_armor'] || false;
    client.show_sms_by_default =
      input['show_sms_by_default'] === undefined
        ? true
        : input['show_sms_by_default'];
    client.feed_enabled = input['feed_enabled'] ?? false;

    if (input['mobile_analysis']) {
      client.mobile_analysis = MobileAnalysis.deserialize(
        input['mobile_analysis'],
      );
    }

    if (input['sso_config']) {
      client.sso_config = SSOConfig.deserialize(input['sso_config']);
    }

    if (input['primary_contact']) {
      client.primary_contact = Contact.deserialize(input['primary_contact']);
    }
    if (input['branding']) {
      client.branding = Branding.deserialize(input['branding']);
    }
    if (input['blackout_window']) {
      client.blackout_window = BlackoutWindow.deserialize(
        input['blackout_window'],
      );
    }
    if (input['reporting']) {
      client.reporting = Reporting.deserialize(input['reporting']);
    }
    client.ext_dynamic_fields = input['ext_dynamic_fields'];
    if (input['ext_dynamic_fields'] === undefined) {
      client.ext_dynamic_fields = [];
    }
    if (input['validation']) {
      client.validation = ValidationClass.deserialize(input['validation']);
    }
    if (input['verification']) {
      client.verification = VerificationClass.deserialize(
        input['verification'],
      );
    }
    if (input['onboarding']) {
      client.onboarding = ClientOnboardingSection.deserialize(
        input['onboarding'],
      );
    }
    if (input['gpg_info']) {
      client.gpg_info = GpgInfo.deserialize(input['gpg_info']);
    }
    if (input['job_processing']) {
      client.job_processing = JobProcessing.deserialize(
        input['job_processing'],
      );
    }
    if (input['sms_keywords']) {
      client.sms_keywords = SmsResponseTypes.deserialize(input['sms_keywords']);
    }
    if (input['password_configuration']) {
      client.password_configuration.rotation_enabled =
        input['password_configuration']['rotation_enabled'] ?? true;
      client.password_configuration.rotation_interval =
        input['password_configuration']['rotation_interval'] ??
        RotationIntervals._90DAYS.value;
    }

    client.external_config = input['external_config'];
    client.ext = input['ext'];
    return client;
  }

  serialize(includeId: boolean): Object {
    const obj = JSON.parse(JSON.stringify(this));

    // serialize onboarding channel booleans
    obj.onboarding.api.enabled = this.onboarding.api.enabled === 'true';
    obj.onboarding.file.enabled = this.onboarding.file.enabled === 'true';
    obj.onboarding.ivr.enabled = this.onboarding.ivr.enabled === 'true';
    obj.onboarding.csr.enabled = this.onboarding.csr.enabled === 'true';
    obj.onboarding.csr.capture_written_consent =
      this.onboarding.csr.capture_written_consent === 'true';
    obj.validation.enabled = this.validation.enabled === 'true';
    obj.verification.enabled = this.verification.enabled === 'true';

    // Remove host branding URLs
    if (obj['branding']) {
      // Add timestamp as pseudo query param to prevent the browser from caching images.
      obj['branding']['icon_s3_url'] =
        AwsUtils.removeHostName(obj['branding']['icon_s3_url']) +
        '?' +
        new Date().getTime();
      obj['branding']['banner_s3_url'] =
        AwsUtils.removeHostName(obj['branding']['banner_s3_url']) +
        '?' +
        new Date().getTime();
      obj['branding']['color'] = undefined;
    }

    obj.password_required = this.password_required === 'true';

    // Exclude these fields to avoid validation error.
    obj['created_at'] = undefined;
    obj['updated_at'] = undefined;
    if (
      obj['gpg_info'] != null &&
      (obj['gpg_info']['gpg_key_name'] == null ||
        obj['gpg_info']['gpg_key_location'] == null ||
        obj['gpg_info']['gpg_key_fingerprint'] == null)
    ) {
      obj['gpg_info'] = undefined;
    }

    if (obj.job_processing) {
      obj.job_processing.client_contact_email =
        obj.job_processing.client_contact_email === ''
          ? undefined
          : obj.job_processing.client_contact_email;
      obj.job_processing.cs_manager_email =
        obj.job_processing.cs_manager_email === ''
          ? undefined
          : obj.job_processing.cs_manager_email;
    }

    // Convert validation field prompts that are empty strings to the value
    // of the validation field name, to avoid error when product group is reloaded.
    if (obj.validation?.fields != null) {
      _.forEach(obj.validation.fields, (validationField) => {
        validationField.prompt =
          validationField.prompt.trim() !== ''
            ? validationField.prompt.trim()
            : FormatUtils.formatInputField(
                Conversions.toString(validationField.field),
              );
      });
    }

    if (!includeId) {
      obj['id'] = undefined;
    }
    return obj;
  }
}

export class SSOConfig {
  role_id_value_map: any;

  static deserialize(input: Object): SSOConfig {
    const ssoConfig = new SSOConfig();
    if (
      input['role_id_value_map'] &&
      input['role_id_value_map'] instanceof Array
    ) {
      ssoConfig.role_id_value_map = input['role_id_value_map'];
    } else {
      ssoConfig.role_id_value_map = [];
    }
    return ssoConfig;
  }
}

export class ContactInfo {
  type: string;
  display_text: string;
  value: string;

  static deserialize(input: Object): ContactInfo {
    const contactInfo = new ContactInfo();
    contactInfo.type = input['type'];
    contactInfo.display_text = input['display_text'];
    contactInfo.value = input['value'];
    return contactInfo;
  }
}

export class OnboardingType {
  enabled: string = 'false';

  static deserialize(input: Object): OnboardingType {
    const onboardingType = new OnboardingType();
    onboardingType.enabled = Conversions.toString(input['enabled']);
    return onboardingType;
  }
}

export class SignUpOnboarding {
  enabled: string = 'false';
  message_text: string;
  terms: string;
  success_text: string;
  failure_text: string;
  submit_button_label: string;
  ccid_input_label: string;
  consent_type: ConsentType;
  mobile_input_label: string;
  terms_and_conditions_text: string;

  static deserialize(input: SignUpOnboarding): SignUpOnboarding {
    const signUpOnboarding = new SignUpOnboarding();
    signUpOnboarding.enabled = Conversions.toString(input['enabled']);
    signUpOnboarding.message_text = input.message_text;
    signUpOnboarding.terms = input.terms;
    signUpOnboarding.success_text = input.success_text;
    signUpOnboarding.failure_text = input.failure_text;
    signUpOnboarding.submit_button_label = input.submit_button_label;
    signUpOnboarding.ccid_input_label = input.ccid_input_label;
    signUpOnboarding.consent_type = input.consent_type;
    signUpOnboarding.mobile_input_label = input.mobile_input_label;
    signUpOnboarding.terms_and_conditions_text = input.terms_and_conditions_text;
    return signUpOnboarding;
  }
}

export class IvrOnboardingType extends OnboardingType {
  customer_minor_cutoff_age_years: number;
  allow_onboarding_minors: boolean;
  data_request_exclusions: string[];
  consent_exclusions: { [key: string]: string }[];
  phone_number_mapping: any;

  static deserialize(input: Object): IvrOnboardingType {
    const ivrOnboard = new IvrOnboardingType();
    ivrOnboard.enabled = Conversions.toString(input['enabled']);
    ivrOnboard.customer_minor_cutoff_age_years =
      input['customer_minor_cutoff_age_years'];
    ivrOnboard.allow_onboarding_minors =
      input['allow_onboarding_minors'] || false;
    ivrOnboard.data_request_exclusions = input['data_request_exclusions'];
    ivrOnboard.consent_exclusions = input['consent_exclusions'];
    ivrOnboard.phone_number_mapping = input['phone_number_mapping'];

    return ivrOnboard;
  }

  constructor() {
    super();
  }
}

export class CsrOnboardingType extends OnboardingType {
  eligible_triggers: string[];
  // TODO: remove empty string when back end updates to makes this field conditionally required.
  validation_language: string = '';
  written_consent_language: string = '';
  express_consent_language: string = '';
  capture_written_consent: string = 'true';

  static deserializeCsr(input: Object): CsrOnboardingType {
    const csrOnboard = new CsrOnboardingType();

    csrOnboard.enabled = Conversions.toString(input['enabled']);
    csrOnboard.eligible_triggers = input['eligible_triggers'] ?? [];
    csrOnboard.validation_language = input['validation_language'];
    csrOnboard.written_consent_language = input['written_consent_language'];
    csrOnboard.express_consent_language = input['express_consent_language'];
    csrOnboard.capture_written_consent = Conversions.toString(
      input['capture_written_consent'],
    );

    return csrOnboard;
  }

  constructor() {
    super();
  }
}

export class ClientOnboardingSection {
  product_group_brand_mapping: Object;
  api = new OnboardingType();
  file = new OnboardingType();
  ivr = new IvrOnboardingType();
  csr = new CsrOnboardingType();

  static deserialize(input: Object): ClientOnboardingSection {
    const onboardingSection = new ClientOnboardingSection();

    if (input['product_group_brand_mapping']) {
      onboardingSection.product_group_brand_mapping =
        input['product_group_brand_mapping'];
    }
    if (input['api']) {
      onboardingSection.api = OnboardingType.deserialize(input['api']);
    }
    if (input['file']) {
      onboardingSection.file = OnboardingType.deserialize(input['file']);
    }
    if (input['ivr']) {
      onboardingSection.ivr = IvrOnboardingType.deserialize(input['ivr']);
    }
    if (input['csr']) {
      onboardingSection.csr = CsrOnboardingType.deserializeCsr(input['csr']);
    }
    return onboardingSection;
  }
}

export class PGVerification {
  header: string;
  body: string;
  button_label: string;

  static deserialize(input: PGVerification): PGVerification {
    const pgVerification = new PGVerification();
    pgVerification.header = input.header;
    pgVerification.body = input.body;
    pgVerification.button_label = input.button_label;
    return pgVerification;
  }
}

export class ProductOnboardingSection {
  text_to_engage = new OnboardingType();
  sign_up_page = new SignUpOnboarding();
  generic_connections: { enabled: boolean };
  verification = new PGVerification();

  static deserialize(input: Object): ProductOnboardingSection {
    const onboardingSection = new ProductOnboardingSection();

    if (input['text_to_engage']) {
      onboardingSection.text_to_engage = OnboardingType.deserialize(
        input['text_to_engage'],
      );
    }
    if (input['sign_up_page']) {
      onboardingSection.sign_up_page = SignUpOnboarding.deserialize(
        input['sign_up_page'],
      );
    }
    if (
      input['generic_connections'] &&
      input['generic_connections']['enabled']
    ) {
      onboardingSection.generic_connections = input['generic_connections'];
    } else {
      onboardingSection.generic_connections = { enabled: false };
    }
    if (input['verification']) {
      onboardingSection.verification = PGVerification.deserialize(
        input['verification'],
      );
    }

    return onboardingSection;
  }
}

export class Disclaimer {
  text: string;
  feed_menu_enabled: string = 'false';
  feed_view_enabled: string = 'false';
  message_details_enabled: string = 'false';
  message_level_enabled: string = 'false';
  wire_feed_enabled: string = 'false';

  static deserialize(input: Object) {
    const disclaimer = new Disclaimer();
    disclaimer.text = input['text'];
    disclaimer.message_details_enabled = Conversions.toString(
      input['message_details_enabled'],
    );
    disclaimer.wire_feed_enabled = Conversions.toString(
      input['wire_feed_enabled'],
    );
    if (input.hasOwnProperty('feed_menu_enabled')) {
      disclaimer.feed_menu_enabled = Conversions.toString(
        input['feed_menu_enabled'],
      );
    }
    if (input.hasOwnProperty('feed_view_enabled')) {
      disclaimer.feed_view_enabled = Conversions.toString(
        input['feed_view_enabled'],
      );
    }
    if (input.hasOwnProperty('message_level_enabled')) {
      disclaimer.message_level_enabled = Conversions.toString(
        input['message_level_enabled'],
      );
    }
    return disclaimer;
  }
}

export class InWireUpgrade {
  enabled: boolean;
  ts_cs: string;
  enabled_on_set_password: boolean;

  static deserialize(input: Object) {
    const inWireUpgrade = new InWireUpgrade();
    inWireUpgrade.enabled = input['enabled'] ?? false;
    inWireUpgrade.ts_cs = input['ts_cs'];
    inWireUpgrade.enabled_on_set_password =
      input['enabled_on_set_password'] ?? false;
    return inWireUpgrade;
  }
}

export class Consent {
  express_consent_ts_cs: string;
  express_written_consent_ts_cs: string;
  in_wire_upgrade = new InWireUpgrade();

  static deserialize(input: Consent) {
    const consent = new Consent();
    consent.express_consent_ts_cs = input['express_consent_ts_cs'];
    consent.express_written_consent_ts_cs =
      input['express_written_consent_ts_cs'];
    if (input['in_wire_upgrade']) {
      consent.in_wire_upgrade = InWireUpgrade.deserialize(
        input['in_wire_upgrade'],
      );
    }
    return consent;
  }
}

export class Password {
  enabled: boolean;
  text: string;
  label: string;
  tcs: string;
  tc_enabled: boolean;
  tc_mandatory: boolean;
  consent_upgrade_enabled: boolean;

  static deserialize(input: Password) {
    const password = new Password();
    password.enabled = input.enabled ?? false;
    password.text = input.text;
    password.label = input.label;
    password.tcs = input.tcs;
    password.tc_enabled = !!input.tc_enabled;
    password.tc_mandatory = !!input.tc_mandatory;
    password.consent_upgrade_enabled = !!input.consent_upgrade_enabled;
    return password;
  }
}

export class ProductGroup {
  id: string;
  name: string;
  internal_name: string;
  hide_pg_wire_display_name: boolean;
  wire_header_name: string;
  description: string;
  contact_us_info: ContactInfo[] = [];
  branding = new Branding();
  onboarding = new ProductOnboardingSection();
  password = new Password();
  disclaimer = new Disclaimer();
  consent = new Consent();
  terms: string;
  privacy_policy: string;
  vanity_url: string;
  is_vanity_url_enabled: boolean;
  welcome_trigger_id: string;
  hide_hamburger: boolean;
  hide_pg_from_hamburger: boolean;
  help_instructions: string;
  help_instructions_spanish: string;
  external_webservice_config: Object;
  show_back_to_feed: boolean;

  static deserialize(input: any): ProductGroup {
    const productGroup = new ProductGroup();
    productGroup.id = input.id;
    productGroup.name = input.name;
    productGroup.internal_name = !input.internal_name
      ? input.name
      : input.internal_name;
    productGroup.hide_pg_wire_display_name = input.hide_pg_wire_display_name;
    productGroup.wire_header_name = input.wire_header_name;
    productGroup.description = input.description;
    productGroup.vanity_url = URLUtils.formatVanityURL(input.vanity_url);
    productGroup.is_vanity_url_enabled = input.is_vanity_url_enabled;
    productGroup.welcome_trigger_id = input.welcome_trigger_id;
    productGroup.hide_hamburger = input.hide_hamburger;
    productGroup.hide_pg_from_hamburger = input.hide_pg_from_hamburger;
    productGroup.help_instructions = !input.help_instructions
      ? 'Go to http://myhelpsite.net/relay for help'
      : input.help_instructions;
    productGroup.help_instructions_spanish = input.help_instructions_spanish;
    productGroup.show_back_to_feed = input.show_back_to_feed;

    if (input.external_webservice_config != null) {
      productGroup.external_webservice_config =
        input.external_webservice_config;
    }

    if (input.branding) {
      productGroup.branding = Branding.deserialize(input.branding);
    }

    if (input.onboarding) {
      productGroup.onboarding = ProductOnboardingSection.deserialize(
        input.onboarding,
      );
    }

    if (input.disclaimer) {
      productGroup.disclaimer = Disclaimer.deserialize(input.disclaimer);
    }

    if (input.consent) {
      productGroup.consent = Consent.deserialize(input.consent);
    }

    productGroup.terms = input.terms;
    productGroup.privacy_policy = input.privacy_policy;

    if (input.password) {
      productGroup.password = Password.deserialize(input.password);
    }

    productGroup.contact_us_info = [];
    if (input.contact_us_info) {
      for (let i = 0; i < input.contact_us_info.length; i++) {
        productGroup.contact_us_info.push(
          ContactInfo.deserialize(input.contact_us_info[i]),
        );
      }
    }

    return productGroup;
  }

  static allProductGroupPreview(): ProductGroup {
    const productGroup = new ProductGroup();
    productGroup.name = 'all';
    productGroup.branding.icon_s3_url = '/assets/icons/asterisk.jpg';
    productGroup.branding.color = '#5b5a59';
    return productGroup;
  }

  static validInWireConsent(pg: ProductGroup): boolean {
    const iwcuTermsAndCondsRequired =
      (pg.password.enabled &&
        pg.consent.in_wire_upgrade.enabled_on_set_password) ||
      pg.consent.in_wire_upgrade.enabled;
    if (iwcuTermsAndCondsRequired) {
      return pg.consent.in_wire_upgrade.ts_cs?.length > 0;
    } else {
      return true;
    }
  }

  static validPasswordRequired(pg: ProductGroup): boolean {
    if (!pg.password.enabled) {
      return true;
    } else {
      return pg.password.label?.length > 0 && pg.password.text?.length > 0;
    }
  }

  serialize(includeId: boolean): Object {
    const obj = JSON.parse(JSON.stringify(this));

    // Convert strings with 'true' or 'false' to actual booleans.
    obj.onboarding.text_to_engage.enabled =
      this.onboarding.text_to_engage.enabled === 'true';
    obj.onboarding.sign_up_page.enabled =
      this.onboarding.sign_up_page.enabled === 'true';
    obj.disclaimer.feed_menu_enabled =
      this.disclaimer.feed_menu_enabled === 'true';
    obj.disclaimer.feed_view_enabled =
      this.disclaimer.feed_view_enabled === 'true';
    obj.disclaimer.message_details_enabled =
      this.disclaimer.message_details_enabled === 'true';
    obj.disclaimer.message_level_enabled =
      this.disclaimer.message_level_enabled === 'true';
    obj.disclaimer.wire_feed_enabled =
      this.disclaimer.wire_feed_enabled === 'true';

    // Convert empty strings to undefined to avoid error on save.
    // Empty strings occur when user enters text and then deletes it.
    if (obj.onboarding && obj.onboarding.sign_up_page) {
      obj.onboarding.sign_up_page.ccid_input_label =
        obj.onboarding.sign_up_page.ccid_input_label === ''
          ? undefined
          : obj.onboarding.sign_up_page.ccid_input_label;
      obj.onboarding.sign_up_page.failure_text =
        obj.onboarding.sign_up_page.failure_text === ''
          ? undefined
          : obj.onboarding.sign_up_page.failure_text;
      obj.onboarding.sign_up_page.message_text =
        obj.onboarding.sign_up_page.message_text === ''
          ? undefined
          : obj.onboarding.sign_up_page.message_text;
      obj.onboarding.sign_up_page.mobile_input_label =
        obj.onboarding.sign_up_page.mobile_input_label === ''
          ? undefined
          : obj.onboarding.sign_up_page.mobile_input_label;
      obj.onboarding.sign_up_page.submit_button_label =
        obj.onboarding.sign_up_page.submit_button_label === ''
          ? undefined
          : obj.onboarding.sign_up_page.submit_button_label;
      obj.onboarding.sign_up_page.success_text =
        obj.onboarding.sign_up_page.success_text === ''
          ? undefined
          : obj.onboarding.sign_up_page.success_text;
    }

    // Remove host branding URLs
    if (obj.branding) {
      obj.branding.icon_s3_url = AwsUtils.removeHostName(
        obj.branding.icon_s3_url,
      );
      obj.branding.banner_s3_url = AwsUtils.removeHostName(
        obj.branding.banner_s3_url,
      );
    }

    // Exclude these fields to avoid validation error.
    obj.created_at = undefined;
    obj.updated_at = undefined;

    if (!includeId) {
      obj.id = undefined;
    }

    return obj;
  }
}

export class GpgInfo {
  gpg_key_name: string;
  gpg_key_location: string;
  gpg_key_fingerprint: string;

  static deserialize(input: GpgInfo) {
    const gpgInfo = new GpgInfo();
    gpgInfo.gpg_key_name = input.gpg_key_name;
    gpgInfo.gpg_key_location = input.gpg_key_location;
    gpgInfo.gpg_key_fingerprint = input.gpg_key_fingerprint;
    return gpgInfo;
  }
}

export class TimeWindow {
  start_time = '01:00:00';
  end_time = '02:00:00';
  timezone = 'EST5EDT';

  static deserialize(input: any): TimeWindow {
    const timeWindow = new TimeWindow();
    timeWindow.start_time = input['start_time'];
    timeWindow.end_time = input['end_time'];
    timeWindow.timezone = input['timezone'];
    return timeWindow;
  }
}

export class JobProcessing {
  client_contact_email: string;
  cs_manager_email: string;

  static deserialize(input: any): JobProcessing {
    const jobProcessing = new JobProcessing();

    jobProcessing.client_contact_email =
      input['client_contact_email'] !== ''
        ? input['client_contact_email']
        : undefined;

    jobProcessing.cs_manager_email =
      input['cs_manager_email'] !== '' ? input['cs_manager_email'] : undefined;

    return jobProcessing;
  }
}

export class MobileAnalysis {
  enabled: boolean;
  should_prevent_onboarding: boolean;

  static deserialize(input: Object): MobileAnalysis {
    const mobileAnalysis: MobileAnalysis = new MobileAnalysis();
    mobileAnalysis.enabled = input['enabled'] ?? false;
    mobileAnalysis.should_prevent_onboarding =
      input['should_prevent_onboarding'] ?? false;
    return mobileAnalysis;
  }
}

export class SMTPCredentials {
  host?: string;
  port?: number;
  secure?: boolean;
  user?: string;
  pass?: string;
}

export interface PushSFTP {
  host?: string;
  ssh_key?: boolean;
  ssh_key_path?: string;
  location?: string;
  reject_files_location?: string;
  password?: string;
  port?: string;
  username?: string;
  is_password_set?: boolean;
}
