<div class="edit-user-wrapper">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div *ngIf="!isLoading" class="edit-user">
    <div class="page-action">
      <div
        *ngIf="!isNew && ps.checkPermissions(permissions.user_reset_password)"
        class="btn btn-primary"
        (click)="resetPassword()"
      >
        Reset Password
        <span class="icon icon-right">
          <i class="fa fa-lock"></i>
        </span>
      </div>
      <div
        *ngIf="!isNew && isMFAResetAllowed"
        class="btn btn-primary"
        (click)="resetMFASecret()"
      >
        Reset MFA Secret
        <span class="icon icon-right">
          <i class="fa fa-key"></i>
        </span>
      </div>
    </div>

    <client-header
      [headerLabel]="'User Information'"
      [headerGroup]="'user-information'"
      (toggleHeader)="showUserInfo = !showUserInfo"
    >
    </client-header>

    <div class="collapse-container user-info-container" *ngIf="showUserInfo">
      <rn-text-input
        [inputLabel]="'Email'"
        [inputControl]="userInfoValidGroup.controls['email']"
        [placeholderText]="'Email'"
        [trimOnBlur]="true"
        [readOnly]="!isNew"
        [newValue]="false"
      >
        <rn-input-info-box
          input-info-box
          [svgHeight]="78"
          [svgPosition]="'8,0 8,33, 2,39 8,45 8,78'"
          [text]="
            'This will be the user\'s primary ID and be used to reset their passwords.  An invitation for them to log into the CX Builder the first time will be sent to this address.'
          "
        ></rn-input-info-box>
      </rn-text-input>

      <rn-text-input
        [inputLabel]="'First Name'"
        [inputControl]="userInfoValidGroup.controls['first_name']"
        [placeholderText]="'First Name'"
      ></rn-text-input>

      <rn-text-input
        [inputLabel]="'Last Name'"
        [inputControl]="userInfoValidGroup.controls['last_name']"
        [placeholderText]="'Last Name'"
      ></rn-text-input>
    </div>

    <br />

    <client-header
      [headerLabel]="'Roles'"
      [headerGroup]="'roles'"
      (toggleHeader)="showRoleInfo = !showRoleInfo"
    >
    </client-header>

    <div
      class="collapse-container user-role-info-container"
      *ngIf="showRoleInfo"
    >
      <label for="admin-role-select">Admin Role</label>
      <div class="input-container">
        <i
          *ngIf="
            !userInfoValidGroup.controls['admin_role'].valid &&
            userInfoValidGroup.controls['admin_role'].touched
          "
          class="fa fa-exclamation-circle"
          aria-hidden="true"
          tooltip="This field is required."
        ></i>
        <select
          name="admin-role-select"
          class="form-control"
          (change)="adminRoleChange()"
          [formControl]="userInfoValidGroup.controls['admin_role']"
          [ngClass]="{
            'has-error':
              !userInfoValidGroup.controls['admin_role'].valid &&
              userInfoValidGroup.controls['admin_role'].touched
          }"
        >
          <option value="" default>Select an Admin Role</option>
          <option
            *ngFor="let adminRoleOption of adminRoleOptions"
            [ngValue]="adminRoleOption.id"
            [selected]="adminRoleOption.id === selectedAdminRole.value"
          >
            {{ adminRoleOption.id | inputField }}
          </option>
        </select>
      </div>

      <ng-container *ngIf="twoWayService.showTwoWay()">
        <label for="two-way-role-select"><span *ngIf="!currentClient.feed_enabled">Two Way Messaging Role</span><span *ngIf="currentClient.feed_enabled">Relay Messenger Role</span></label>
        <div class="input-container">
          <select
            name="two-way-role-select"
            class="form-control"
            (change)="roleChange()"
            [formControl]="userInfoValidGroup.controls['two_way_role']"
            [ngClass]="{
              'has-error':
                !userInfoValidGroup.controls['two_way_role'].valid &&
                userInfoValidGroup.controls['two_way_role'].touched
            }"
          >
            <option value="" default *ngIf="!currentClient.feed_enabled">Select a Two-Way Messaging Role</option>
            <option value="" default *ngIf="currentClient.feed_enabled">Select a Relay Messager Role</option>
            <option
              *ngFor="let twoWayRoleOption of twoWayRoleOptions"
              [ngValue]="twoWayRoleOption.id"
              [selected]="twoWayRoleOption.id === selectedTwoWayRole.value"
            >
            <span *ngIf="twoWayRoleOption.id === 'two_way_admin' && currentClient.feed_enabled">Messenger Admin </span>
            <span *ngIf="twoWayRoleOption.id === 'two_way_user' && currentClient.feed_enabled">Messenger User </span>
            <span *ngIf="twoWayRoleOption.id === 'two_way_admin' && !currentClient.feed_enabled">Two Way Admin </span>
            <span *ngIf="twoWayRoleOption.id === 'two_way_user' && !currentClient.feed_enabled">Two Way User </span>
            <span *ngIf="twoWayRoleOption.id !== 'two_way_admin' && twoWayRoleOption.id !== 'two_way_user'" >  {{ twoWayRoleOption.id | inputField }} </span>
            </option>
          </select>
        </div>

        <label for="two-way-control-group-select">Control Group</label>
        <div class="input-container">
          <select
            name="two-way-group-select"
            class="form-control"
            (change)="controlGroupChanged($event.target.value)"
            [formControl]="userInfoValidGroup.controls['group_id']"
            [ngClass]="{
              'has-error':
                !userInfoValidGroup.controls['group_id'].valid &&
                userInfoValidGroup.controls['group_id'].touched
            }"
          >
            <option value="" disabled>Select a Control Group</option>
            <option [value]="''" default>No Control Group</option>
            <option
              *ngFor="let controlGroup of controlGroups"
              [value]="controlGroup.group_id"
              [selected]="controlGroup.group_id === selectedControlGroupId"
            >
              {{ controlGroup.group_name }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>

    <client-header
      [headerLabel]="'Clients'"
      [headerGroup]="'clients'"
      (toggleHeader)="showClientInfo = !showClientInfo"
    >
    </client-header>
    <div
      class="collapse-container user-role-info-container"
      *ngIf="showClientInfo"
    >
      <div class="user-clients">
        <div class="input-container">
          <label>Primary Client</label>
          <ng-container *ngIf="isNew; else showReadOnlyInput">
            <i
              *ngIf="
                !userInfoValidGroup.controls['primary_client'].valid &&
                userInfoValidGroup.controls['primary_client'].touched
              "
              class="fa fa-exclamation-circle"
              aria-hidden="true"
              tooltip="This field is required."
            ></i>
            <select
              class="form-control"
              [formControl]="userInfoValidGroup.controls['primary_client']"
              [ngClass]="{
                'has-error':
                  !userInfoValidGroup.controls['primary_client'].valid &&
                  userInfoValidGroup.controls['primary_client'].touched
              }"
            >
              <option
                *ngFor="let client of availableClients; let i = index"
                class="available-client-option-{{ i }}"
                [ngValue]="client.id"
                [selected]="client.id === user.client_id"
              >
                {{ client.company_name }}
              </option>
            </select>
          </ng-container>
          <ng-template #showReadOnlyInput>
            <input
              class="form-control"
              [formControl]="userInfoValidGroup.controls['primary_client']"
            />
          </ng-template>
        </div>
        <p
          *ngIf="selectedSecondaryClients.length > 10 && !showAll"
          (click)="showAll = !showAll"
          class="btn-link"
        >
          Show All
        </p>
        <div
          class="add-client-container"
          *ngIf="availableSecondaryClients.length"
        >
          <label>Secondary Clients</label>
          <div class="input-container">
            <!-- selectedSecondaryClient is a temp variable that isn't part of the Reactive form -->
            <select class="form-control" [(ngModel)]="selectedSecondaryClient">
              <option value="">Select</option>
              <option
                *ngFor="let client of availableSecondaryClients; let i = index"
                class="available-client-option-{{ i }}"
                [ngValue]="client"
              >
                {{ client.company_name }}
              </option>
            </select>
            <div class="add-client btn btn-primary" (click)="addClient()">
              Add
            </div>
          </div>
        </div>
        <div class="secondary-client-container">
          <ng-container
            *ngFor="let client of selectedSecondaryClients; let i = index"
          >
            <div *ngIf="i < 10 || showAll" class="secondary-client-{{ i }}">
              <div>
                <span class="company-name">{{
                  getCompanyNameById(client)
                }}</span
                ><span
                  *ngIf="canRemove(client)"
                  class="remove remove-client"
                  (click)="removeClient(client, i)"
                  ><i class="fa fa-minus-circle"></i
                ></span>
              </div>
            </div>
          </ng-container>
        </div>
        <p
          *ngIf="selectedSecondaryClients.length > 10 && !showAll"
          (click)="showAll = !showAll"
          class="show-all btn-link"
        >
          Show All
        </p>
      </div>
    </div>

    <br />
    <hr />
    <div class="button-container">
      <button
        id="prev-btn"
        class="btn btn-primary btn-with-icon"
        (click)="handleClose()"
      >
        <span class="icon icon-left">
          <i class="fa fa-arrow-left"></i>
        </span>
        <span class="btn-label">Cancel</span>
      </button>
      <button id="next-btn" class="btn btn-primary" (click)="onSave()">
        <span i18n="Label for save user button" id="finish-button">{{
          isNew ? 'Create' : 'Save'
        }}</span>
        <span class="icon icon-right">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
  <div class="modal" *ngIf="showReset" style="display: block">
    <app-user-reset
      [config]="resetPasswordConfig()"
      (closeWindow)="handleClose($event)"
    ></app-user-reset>
  </div>
  <message-dialog></message-dialog>
  <message-dialog #resetMfaSucess [showAffirm]="false" [showClose]="true">
    <div class="text-left px-3">
      <h3>MFA Secret has been reset.</h3>
      <p>
        The user <strong>{{ user.email_address }}</strong> will need to re-authenticate on their
        next login.
      </p>
    </div>
  </message-dialog>
  <message-dialog #resetMfaFail [showAffirm]="false" [showClose]="true">
    <div class="text-left px-3">
      <h3>Failed to reset MFA Secret.</h3>
    </div>
  </message-dialog>
</div>
