<div class="intro-line-1" i18n="Introductory for creating a new client - step 2, line 1">Customize your CX Builder</div>

<client-header [headerLabel]="'Portal images'" [headerGroup]="'portalImages'" (openHeader)="toggleHeader($event)"
               (closeHeader)="toggleHeader($event)"></client-header>

<div class="collapse-container portal-images-container" *ngIf="headerGroups.portalImages && client.branding">

  <app-image-input [client]="client" [imgModel]="client" [label]="'Portal icon'" [type]="'icon'"
                   [tipText]="'Must be at least 95px wide by 95px tall and fewer than 1MB.'"></app-image-input>

  <app-image-input [client]="client" [imgModel]="client" [label]="'Portal banner'" [type]="'banner'"
                   [tipText]="'Must be at least 1200px wide by 375px tall and fewer than 1MB.'"></app-image-input>

</div>

<hr>

<div class="button-container">
  <button id="prev-btn" class="btn btn-primary btn-with-icon" (click)="previousStep()">
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button class="btn btn-primary" id="next-btn" (click)="saveAndContinue()">
    <span i18n="Label for Next button on new client configuration" id="save-and-next-button">
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
