import React from 'react';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Filter } from 'baseui/icon';
import { Button, SHAPE, SIZE } from 'baseui/button';
import { Block } from 'baseui/block';
import { ParagraphSmall } from 'baseui/typography';
import {
  DateCreatedOldestNewestMethods,
  SortDateAction,
} from './SortDateCreated';
import {
  LastModifiedOldestNewestMethods,
  SortLastModifiedAction,
} from './SortLastModifiedAction';
import { NameAscendingDescendingMethods, SortNameAction } from './SortName';
import {
  FilterClosedReasonProps,
  FilterDateCreatedProps,
  FilterLastModifiedProps,
  FilterUnreadMessagesProps,
  SortingDateCreatedProps,
  SortingLastModifiedProps,
  SortingNameProps,
} from '../relay-messenger-list/RelayMessenger.list';
import { ConversationsClicked } from '../relay-messenger-conversations/RelayMessengerConversations';
import { FilterDateAction } from './filter-actions/FilterDateCreated';
import { FilterLastModifiedAction } from './filter-actions/FilterLastModified';
import { FilterUnreadAction } from './filter-actions/FilterUnreadAction';
import { FilterClosedReasonAction } from './filter-actions/FilterClosedReasonAction';
import { useClosedReasons } from '../../context/ClosedReasonsContext';

export type RangeDate = {
  rangeDateCreated: Date[];
  setRangeDateCreated: (rangeDateCreated: Date[]) => void;
  rangeLastModified: Date[];
  setRangeLastModified: (rangeLastModified: Date[]) => void;
};
export type FilterSortingPopoverProps = {
  isFilterSortingPopoverOpen: boolean;
  setIsFilterSortingPopoverOpen: (isFilterSortingPopoverOpen: boolean) => void;
  sortingName: SortingNameProps;
  sortDateCreated: SortingDateCreatedProps;
  sortLastModified: SortingLastModifiedProps;
  filterDateCreated: FilterDateCreatedProps;
  filterLastModified: FilterLastModifiedProps;
  filterUnreadMessages: FilterUnreadMessagesProps;
  conversationsClicked: ConversationsClicked;
  rangeDate: RangeDate;
  filterClosedReason?: FilterClosedReasonProps;
};

enum DataIdSortingAction {
  DateCreated = 'date-created',
  LastModified = 'last-modified',
  Name = 'name',
}

export const FilterSortingPopover = ({
  isFilterSortingPopoverOpen,
  setIsFilterSortingPopoverOpen,
  sortingName,
  sortLastModified,
  sortDateCreated,
  filterDateCreated,
  filterLastModified,
  filterUnreadMessages,
  conversationsClicked: {
    isMyConversationsClicked,
    IsMyClosedConversationsClicked,
  },
  rangeDate: {
    rangeDateCreated,
    setRangeDateCreated,
    rangeLastModified,
    setRangeLastModified,
  },
  filterClosedReason,
}: FilterSortingPopoverProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const { isClosedReasonsEnabled } = useClosedReasons();

  const clearFilterState = () => {
    // This is to unselect filtering (that does not have any options) when the sorting option is selected.
    if (
      filterDateCreated.filterOptionDateCreatedClicked &&
      !filterDateCreated.filterByDateCreated
    ) {
      filterDateCreated.setFilterOptionDateCreatedClicked(null);
      filterDateCreated.setFilterByDateCreated(null);
    }
    if (
      filterLastModified.filterOptionLastModifiedClicked &&
      !filterLastModified.filterByLastModified
    ) {
      filterLastModified.setFilterOptionLastModifiedClicked(null);
      filterLastModified.setFilterByLastModified(null);
    }
    if (
      filterClosedReason?.filterOptionClosedReasonClicked &&
      !filterClosedReason.filterByClosedReason
    ) {
      filterClosedReason.setFilterOptionClosedReasonClicked(null);
      filterClosedReason.setFilterByClosedReason(null);
    }
  };

  const clearSortingState = () => {
    // This is to unselect sorting (that does not have any options) when the filtering option is selected.
    if (
      sortingName.sortingOptionNameClicked &&
      !sortingName.sortByAsc &&
      !sortingName.sortByDesc
    ) {
      sortingName.setSortOptionNameClicked(null);
    } else if (
      sortDateCreated.sortingOptionDateCreatedClicked &&
      !sortDateCreated.sortByOldestDateCreated &&
      !sortDateCreated.sortByNewestDateCreated
    ) {
      sortDateCreated.setSortOptionDateCreatedClicked(null);
    } else if (
      sortLastModified.sortingOptionLastModifiedClicked &&
      !sortLastModified.sortByOldestLastModified &&
      !sortLastModified.sortByNewestLastModified
    ) {
      sortLastModified.setSortOptionLastModifiedClicked(null);
    }
  };

  const resetAllSortingOptionSelection = () => {
    sortDateCreated.setSortByOldestDateCreated(null);
    sortDateCreated.setSortByNewestDateCreated(null);
    sortLastModified.setSortByOldestLastModified(null);
    sortLastModified.setSortByNewestLastModified(null);
    sortingName.setSortByAsc(null);
    sortingName.setSortByDesc(null);
  };

  const clearSortingSelection = () => {
    // This is to unselect sorting if no options were selected. By default, we can only select one sorting option.
    if (sortDateCreated.sortingOptionDateCreatedClicked) {
      if (
        sortDateCreated.sortingOptionDateCreatedClicked !==
          DateCreatedOldestNewestMethods.DateCreated &&
        !sortDateCreated.sortByOldestDateCreated &&
        !sortDateCreated.sortByNewestDateCreated
      ) {
        sortDateCreated.setSortOptionDateCreatedClicked(null);
      } else if (
        sortDateCreated.sortingOptionDateCreatedClicked ===
          DateCreatedOldestNewestMethods.DateCreated &&
        (sortLastModified.sortByOldestLastModified ||
          sortLastModified.sortByNewestLastModified ||
          sortingName.sortByAsc ||
          sortingName.sortByDesc)
      ) {
        resetAllSortingOptionSelection();
      }
    } else if (sortLastModified.sortingOptionLastModifiedClicked) {
      if (
        sortLastModified.sortingOptionLastModifiedClicked !==
          LastModifiedOldestNewestMethods.LastModified &&
        !sortLastModified.sortByOldestLastModified &&
        !sortLastModified.sortByNewestLastModified
      ) {
        sortLastModified.setSortOptionLastModifiedClicked(null);
      } else if (
        sortLastModified.sortingOptionLastModifiedClicked ===
          LastModifiedOldestNewestMethods.LastModified &&
        (sortDateCreated.sortByOldestDateCreated ||
          sortDateCreated.sortByNewestDateCreated ||
          sortingName.sortByAsc ||
          sortingName.sortByDesc)
      ) {
        resetAllSortingOptionSelection();
      }
    } else if (sortingName.sortingOptionNameClicked) {
      if (
        sortingName.sortingOptionNameClicked !==
          NameAscendingDescendingMethods.Name &&
        !sortingName.sortByAsc &&
        !sortingName.sortByDesc
      ) {
        sortingName.setSortOptionNameClicked(null);
      } else if (
        sortingName.sortingOptionNameClicked ===
          NameAscendingDescendingMethods.Name &&
        (sortDateCreated.sortByOldestDateCreated ||
          sortDateCreated.sortByNewestDateCreated ||
          sortLastModified.sortByOldestLastModified ||
          sortLastModified.sortByNewestLastModified)
      ) {
        resetAllSortingOptionSelection();
      }
    }
  };

  return (
    <StatefulPopover
      content={({ close }) => (
        <Block padding={'10px'}>
          <ParagraphSmall
            className={css({
              color: 'rgba(55, 71, 79, 0.25) !important',
              fontFamily: 'Source Sans Pro !important',
              fontSize: '10px !important',
              fontStyle: 'normal !important',
              fontWeight: '600 !important',
              lineHeight: 'normal !important',
            })}
          >
            FILTER BY
          </ParagraphSmall>
          <FilterDateAction
            filterDateCreated={filterDateCreated}
            close={close}
            rangeDate={rangeDateCreated}
            setRangeDate={setRangeDateCreated}
            clearFilterState={clearFilterState}
            clearSortingState={clearSortingState}
          />
          <FilterLastModifiedAction
            filterLastModified={filterLastModified}
            close={close}
            rangeDate={rangeLastModified}
            setRangeDate={setRangeLastModified}
            clearFilterState={clearFilterState}
            clearSortingState={clearSortingState}
          />
          {isMyConversationsClicked && (
            <FilterUnreadAction
              filterUnreadMessages={filterUnreadMessages}
              close={close}
            />
          )}
          {IsMyClosedConversationsClicked && isClosedReasonsEnabled && (
            <FilterClosedReasonAction
              filterClosedReason={filterClosedReason}
              close={close}
              clearFilterState={clearFilterState}
              clearSortingState={clearSortingState}
            />
          )}

          <hr className={css({ marginTop: '5px', marginBottom: '5px' })} />

          <ParagraphSmall
            className={css({
              color: 'rgba(55, 71, 79, 0.25) !important',
              fontFamily: 'Source Sans Pro !important',
              fontSize: '10px !important',
              fontStyle: 'normal !important',
              fontWeight: '600 !important',
              lineHeight: 'normal !important',
            })}
          >
            SORT BY
          </ParagraphSmall>
          <SortDateAction
            sortingName={sortingName}
            sortDateCreated={sortDateCreated}
            sortLastModified={sortLastModified}
            close={close}
            dataTestId={DataIdSortingAction.DateCreated}
            clearFilterState={clearFilterState}
            clearSortingSelection={clearSortingSelection}
          />
          <SortLastModifiedAction
            sortingName={sortingName}
            sortDateCreated={sortDateCreated}
            sortLastModified={sortLastModified}
            close={close}
            dataTestId={DataIdSortingAction.LastModified}
            clearFilterState={clearFilterState}
            clearSortingSelection={clearSortingSelection}
          />
          <SortNameAction
            sortingName={sortingName}
            sortDateCreated={sortDateCreated}
            sortLastModified={sortLastModified}
            close={close}
            dataTestId={DataIdSortingAction.Name}
            clearFilterState={clearFilterState}
            clearSortingSelection={clearSortingSelection}
          />
        </Block>
      )}
      placement={PLACEMENT.bottomRight}
      onClose={() => setIsFilterSortingPopoverOpen(false)}
      onOpen={() => {
        // clear state for filtering and sorting if no options were selected
        clearFilterState();
        clearSortingState();
        setIsFilterSortingPopoverOpen(true);
      }}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          props: { 'data-testid': 'sorting-filtering-popover' },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
          },
        },
      }}
    >
      <Button
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isFilterSortingPopoverOpen
                ? '#3693BF'
                : customTheme.colors.white,
              color: isFilterSortingPopoverOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'sort-filtering-actions-btn' },
          },
        }}
      >
        <Filter size={28} />
      </Button>
    </StatefulPopover>
  );
};
