import React, { useCallback, useEffect, useState } from 'react';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { AgentAvatar } from '@app/two-way/relay-messenger/components/agent-avatar/AgentAvatar';
import { Modal } from '../modal/Modal';
import { AgentModal, RelayMessengerAgent } from '../agent-modal/AgentModal';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import { useClientInfo } from '../../context/ClientInfoContext';
import Moment from 'moment';
import {
  AgentStatus,
  MetadataBody,
  TwoWayService,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { ActionsModal } from '../actions-modal/ActionsModal';
import { TwoWayCustomer } from '../relay-messenger-home/RelayMessengerHome';
import { useTwilioClient } from '../../context/TwilioClientContext';
import { useUserInfo } from '../../context/UserInfoContext';
import { SingleAgentView } from './SingleAgentView';
import { MultipleAgentsView } from './MultipleAgentsView';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';
import { AgentConfirmModal } from '../agent-actions-modal/AgentConfirmModal';
import { useAlertMessage } from '../../hooks/useAlertMessage/use-alert-message';
import {
  TwilioConversation,
  TwoWayUnassignedChannel,
  TwoWayClosedChannel,
  ParticipantConversation,
} from '@app/two-way/twilio-conversation.types';
import { useRefreshConversations } from '../../context/RefreshConversationsContext';
import { useFeatureFlags } from '../../context/FeatureFlagsContext';

export type AgentInfo = {
  id: string;
  first_name: string;
  last_name: string;
};

export type TwoWayMessagingAdditionalInfoProps = {
  conversationsClicked: {
    isMyConversationsClicked: boolean;
    IsMyUnassignedConversationClicked: boolean;
    IsMyClosedConversationsClicked: boolean;
    agentConversationsClicked: boolean;
  };
  setIsUnassignedConversationsClicked: (isClicked: boolean) => void;
  setIsMyConversationsClicked: (isClicked: boolean) => void;
  setIsMyClosedConversationsClicked: (isClicked: boolean) => void;
  setConversationClicked: (channelId: string) => void;
  setAgentConversationsClicked: (isClicked: boolean) => void;
  loadAgentConversations: (agentId: string) => void;
  setIsMyUnassignedConversation: (value: boolean) => void;
  setIsAgentJoined: (value: boolean) => void;
  status: AgentStatus;
  setAgentStatus: (value: AgentStatus) => void;
  customer: TwoWayCustomer;
  setCustomer: (customer: TwoWayCustomer) => void;
  allAssignedChats: TwilioConversation[];
  allUnassignedChats: TwoWayUnassignedChannel[];
  closedConversations: TwoWayClosedChannel[];
  isPingTriggered: boolean;
  showCustomerOrAgentInfo: string;
  allAgentsChats: ParticipantConversation[];
  selectedAgentId: string;
  selectedAgentFullName: string;
};

export const TwoWayMessagingAdditionalInfo = ({
  conversationsClicked: {
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
  },
  setIsUnassignedConversationsClicked,
  setIsMyConversationsClicked,
  setConversationClicked,
  setAgentConversationsClicked,
  setIsMyClosedConversationsClicked,
  loadAgentConversations,
  setIsMyUnassignedConversation,
  setIsAgentJoined,
  status = 'inactive',
  setAgentStatus,
  customer,
  setCustomer,
  allAssignedChats,
  isPingTriggered = false,
  showCustomerOrAgentInfo,
  allUnassignedChats,
  closedConversations,
  allAgentsChats,
  selectedAgentId,
  selectedAgentFullName,
}: TwoWayMessagingAdditionalInfoProps) => {
  const featureFlags = useFeatureFlags();
  const { twilioClient } = useTwilioClient();
  const [{ userId, userRoleIds, userFullName }] = useUserInfo();
  const {
    customerInfo: { dateCreated, tagName, channelSid },
    customerInfo,
    setCustomerInfo,
  } = useCustomerInfo();
  const { assignedAgents, fetchAssignedAgents } = useAssignedAgents();
  const { refreshConversations, setRefreshConversations } =
    useRefreshConversations();
  const [css, theme] = useStyletron();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isInviteCheckModalOpen, setIsInviteCheckModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isAssignCheckModalOpen, setIsAssignCheckModalOpen] = useState(false);
  const customTheme = theme as CustomTheme;
  const [{ clientId }] = useClientInfo();
  const [metadata, setMetadata] = useState<MetadataBody>(null);
  const [userLeft, setUserLeft] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<RelayMessengerAgent>(null);
  const [secondAgentInfo, setSecondAgentInfo] = useState<AgentInfo>(null);
  const [firstAgentStatus, setFirstAgentStatus] =
    useState<AgentStatus>('inactive');
  const [secondAgentStatus, setSecondAgentStatus] =
    useState<AgentStatus>('inactive');
  const [agentToRemove, setAgentToRemove] = useState(null);
  const [lastPinged, setLastPinged] = useState(null);
  const [dateUpdated, setDateUpdated] = useState(null);
  const showMessage = useAlertMessage();

  // feature flag check
  const isRelayMessengerR2Enabled =
    featureFlags.find((flag) => flag.flagName === 'relay_messenger_r2')
      ?.flagValue ?? false;
  const isCurrentUserAnAdmin = userRoleIds?.includes('two_way_admin');

  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const closeLeaveModal = () => {
    setIsLeaveModalOpen(false);
  };

  const openLeaveModal = () => {
    setIsLeaveModalOpen(true);
  };

  const openRemoveModal = (agentId) => {
    setAgentToRemove(agentId);
    setIsRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setAgentToRemove(null);
    setIsRemoveModalOpen(false);
  };

  const closeAssignedModal = () => setIsAssignModalOpen(false);

  const fetchMetadata = async (clientId: string, channelSid: string) => {
    const metadata = await TwoWayService.getClientMetaData(
      clientId,
      channelSid,
    );
    if (metadata) setMetadata(metadata as MetadataBody);
  };

  useEffect(() => {
    // clear metadata
    if (
      !customerInfo?.customerId &&
      (isMyConversationsClicked ||
        IsMyUnassignedConversationClicked ||
        IsMyClosedConversationsClicked ||
        agentConversationsClicked)
    ) {
      setMetadata(null);
    }
  }, [
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
    customerInfo,
  ]);

  const getLastUpdatedInfo = async (
    channelSid: string,
    chats: TwilioConversation[] | ParticipantConversation[] | any,
  ) => {
    if (
      chats &&
      chats.length &&
      (isMyConversationsClicked || agentConversationsClicked)
    ) {
      const chat = chats.find((chat) => {
        return chat.sid === channelSid;
      });
      if (chat) {
        setDateUpdated(chat.lastMessage?.dateCreated ?? chat.dateUpdated);
        setLastPinged(chat.attributes['last_ping']);
      }
    } else if (chats && chats.length) {
      const chat = chats.find((chat) => {
        return chat.channel_sid === channelSid;
      });
      if (chat) {
        setDateUpdated(chat.updated_at);
        setLastPinged(chat.last_ping);
      }
    }
  };

  useEffect(() => {
    if (channelSid && isMyConversationsClicked)
      getLastUpdatedInfo(channelSid, allAssignedChats);
    else if (channelSid && IsMyUnassignedConversationClicked)
      getLastUpdatedInfo(channelSid, allUnassignedChats);
    else if (channelSid && IsMyClosedConversationsClicked)
      getLastUpdatedInfo(channelSid, closedConversations);
    else if (channelSid && agentConversationsClicked)
      getLastUpdatedInfo(channelSid, allAgentsChats);
  }, [
    isPingTriggered,
    allAssignedChats,
    allUnassignedChats,
    closedConversations,
    channelSid,
    isMyConversationsClicked,
    IsMyUnassignedConversationClicked,
    IsMyClosedConversationsClicked,
    agentConversationsClicked,
    allAgentsChats,
  ]);

  useEffect(() => {
    if (clientId && channelSid) {
      fetchMetadata(clientId, channelSid);
    }
  }, [clientId, channelSid]);

  const joinConversation = async () => {
    if (clientId && channelSid) {
      try {
        await TwoWayService.joinConversation(clientId, channelSid);
        await TwoWayService.setAgentStatus(clientId, userId, 'join');
        setAgentStatus('active');
        setRefreshConversations(true); // refresh conversations
        showMessage('You have joined this conversation.');
        setTimeout(() => {
          setConversationClicked(channelSid);
        }, 1000);
      } catch (error) {
        if (
          error?.response?.data?.reason === 'Error: Channel has been claimed'
        ) {
          showMessage('Channel has been claimed', 'error');
        } else {
          showMessage('Failed to join a conversation', 'error');
        }
      }
    }
  };

  useEffect(() => {
    if (!dateCreated && !tagName && !channelSid) {
      setCustomer(null); // hide notification channels
      setMetadata(null); // clear rest of additional information
      setUserLeft(false);
    }
  }, [dateCreated, tagName, channelSid]);

  const leaveConversation = async () => {
    setIsLeaveModalOpen(false);
    setUserLeft(true);
    try {
      await TwoWayService.leaveConversation(clientId, channelSid);
      await TwoWayService.setAgentStatus(clientId, userId, 'leave');
      setIsUnassignedConversationsClicked(true);
      setIsMyConversationsClicked(false);
      setAgentStatus('active');
      setCustomerInfo({}); // hide customer information
      setCustomer(null); // hide notification channels
      setMetadata(null); // clear rest of additional information
      showMessage('You have left this conversation.');
      setUserLeft(false);
    } catch (error) {
      console.error(`Failed to leave a conversation: ${error}`);
    }
  };

  const removeAgentFromConversation = async () => {
    setIsRemoveModalOpen(false);
    // using selectedAgent state only for invite/assign functionality
    if (agentToRemove) {
      try {
        await TwoWayService.removeAgentFromConversation(
          clientId,
          agentToRemove,
          channelSid,
        );
        fetchAssignedAgents(twilioClient, channelSid);
        setRefreshConversations(true); // refresh conversations
        setMetadata(null); // clear rest of additional information
        await TwoWayService.setAgentStatus(clientId, userId, 'remove');
        setAgentStatus('active');
        setAgentConversationsClicked(true);
        loadAgentConversations(selectedAgentId);
        setIsMyClosedConversationsClicked(false);
        setIsMyUnassignedConversation(false);
        setIsMyConversationsClicked(false);
        setIsAgentJoined(false);
        setCustomerInfo({});
      } catch (error) {
        const errMsg = error.response?.data?.error;
        showMessage(
          `There was an error removing agent with id ${agentToRemove} from the conversation`,
          'error',
        );
      }
    }
  };

  const inviteAgent = async () => {
    setIsInviteCheckModalOpen(false);
    // using selectedAgent state only for invite/assign functionality
    if (selectedAgent) {
      try {
        await TwoWayService.inviteAgentToConversation(
          clientId,
          selectedAgent.emailAddress,
          channelSid,
          userId,
        );
        fetchAssignedAgents(twilioClient, channelSid);
        await TwoWayService.setAgentStatus(clientId, userId, 'invite');
        setAgentStatus('active');
      } catch (error) {
        const errMsg = error.response?.data?.error;
        if (errMsg === 'new agent already in channel') {
          showMessage(
            'This agent has already been invited to this channel',
            'error',
          );
        } else {
          showMessage(
            `There was an error inviting ${selectedAgent.fullName} to the conversation`,
            'error',
          );
        }
      }
    }
  };
  const assignConversation = async () => {
    setIsAssignCheckModalOpen(false);
    // using selectedAgent state only for invite/assign functionality
    if (selectedAgent) {
      try {
        await TwoWayService.assignAgentToConversation(
          clientId,
          selectedAgent.id,
          channelSid,
          userId,
        );
        await TwoWayService.setAgentStatus(clientId, userId, 'assign');
        setAgentStatus('active');
        setCustomerInfo({});
        setRefreshConversations(true); // refresh conversations
        setMetadata(null); // clear rest of additional information
        showMessage(
          `Conversation successfully assigned to ${selectedAgent.fullName}!`,
          'success',
        );
      } catch (error) {
        showMessage(
          `There was an error assigning ${selectedAgent.fullName} to the conversation`,
          'error',
        );
      }
    }
  };

  useEffect(() => {
    // get agents assigned to conversation
    if (twilioClient && channelSid) {
      fetchAssignedAgents(twilioClient, channelSid);
    }
  }, [twilioClient, channelSid]);

  const getFirstAgentStatus = useCallback(
    async (agentId: string) => {
      const agentStatus = await TwoWayService.getAgentStatus(clientId, agentId);
      if (agentStatus) {
        setFirstAgentStatus(agentStatus);
      }
    },
    [clientId],
  );

  useEffect(() => {
    if (selectedAgentId) {
      getFirstAgentStatus(selectedAgentId);
    } else {
      getFirstAgentStatus(userId);
    }
  }, [selectedAgentId, userId]);

  const filterSecondAgentInfo = useCallback((): AgentInfo | null => {
    return assignedAgents?.reduce((acc, agent) => {
      // to get second agent info we need to:
      // - exclude first agent who would be a current user;
      const isNotCurrentUser =
        !agentConversationsClicked &&
        agent.attributes['agent']['id'] !== userId;
      // exclude an agent that is currently selected on the agents list
      const isNotFromAgentsList =
        agentConversationsClicked &&
        agent.attributes['agent']['id'] !== selectedAgentId;
      if (
        (isNotCurrentUser || isNotFromAgentsList) &&
        agent.attributes['agent']['first_name']
      ) {
        return agent.attributes['agent'];
      }
      return acc;
    }, null);
  }, [userId, assignedAgents, agentConversationsClicked, selectedAgentId]);

  const getSecondAgentInfo = useCallback(() => {
    const secondAgentInfo = filterSecondAgentInfo();
    setSecondAgentInfo(secondAgentInfo);
  }, [filterSecondAgentInfo]);

  useEffect(() => {
    if (assignedAgents.length > 1) {
      getSecondAgentInfo();
    }
  }, [assignedAgents, getSecondAgentInfo]);

  const fetchSecondAgentStatus = useCallback(async () => {
    if (secondAgentInfo) {
      const { id } = secondAgentInfo;
      const agentStatus = await TwoWayService.getAgentStatus(clientId, id);
      if (agentStatus) {
        setSecondAgentStatus(agentStatus);
      }
    }
  }, [secondAgentInfo, clientId]);

  useEffect(() => {
    if (secondAgentInfo) {
      fetchSecondAgentStatus();
    }
  }, [secondAgentInfo, fetchSecondAgentStatus]);

  const reAssignConversation = async () => {
    setIsAssignCheckModalOpen(false);
    if (selectedAgent) {
      try {
        await TwoWayService.reAssignAgentToConversation(
          clientId,
          selectedAgent.id,
          channelSid,
          userId,
        );
        await TwoWayService.setAgentStatus(clientId, userId, 're-assign');
        setAgentStatus('active');
        setCustomerInfo({});
        setRefreshConversations(true); // refresh conversations
        setMetadata(null); // clear rest of additional information
        showMessage(
          `Conversation successfully re-assigned to ${selectedAgent.fullName}!`,
          'success',
        );
      } catch (error) {
        showMessage(
          `There was an error re-assigning ${selectedAgent.fullName} to the conversation`,
          'error',
        );
      }
    }
  };
  const formatPhoneEmailAddress = (channelAddress: string): string => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(channelAddress)) {
      return channelAddress;
    } else {
      const cleanedAddress =
        channelAddress.length === 11 ? channelAddress.slice(1) : channelAddress;
      const phoneNumber = cleanedAddress.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3',
      );
      return phoneNumber;
    }
  };
  return (
    <div>
      <Block>
        <h5 className={css({ textAlign: 'center' })}>Additional Information</h5>
        {isMyConversationsClicked && (
          <Block
            overrides={{
              Block: {
                style: () => ({
                  marginTop: '30px',
                }),
              },
            }}
          >
            {/* 
            Check agents assigned to conversation: one or two agents only
            */}
            {showCustomerOrAgentInfo &&
              (assignedAgents?.length < 2 ? (
                <SingleAgentView
                  userFullName={userFullName}
                  status={status}
                  userLeft={userLeft}
                  openInviteModal={openInviteModal}
                  openLeaveModal={openLeaveModal}
                  openRemoveModal={openRemoveModal}
                  hideAgentActions={!channelSid}
                  agentConversationsClicked={agentConversationsClicked}
                />
              ) : (
                <MultipleAgentsView
                  firstAgentFullName={userFullName}
                  firstAgentStatus={status}
                  userLeft={userLeft}
                  secondAgentInfo={secondAgentInfo}
                  secondAgentStatus={secondAgentStatus}
                  openRemoveModal={openRemoveModal}
                  openLeaveModal={openLeaveModal}
                  agentConversationsClicked={agentConversationsClicked}
                />
              ))}
            {isLeaveModalOpen && (
              <Modal
                closeFn={closeLeaveModal}
                isOpen={isLeaveModalOpen}
                content={
                  <ActionsModal
                    closeModal={closeLeaveModal}
                    successModal={leaveConversation}
                    modalSubTitle="Are you sure you want to leave the conversation? You would need to join the conversation again to be able to respond."
                    cancelBtnText="Cancel"
                    successBtnText="Yes"
                    dataTestId="confirm-leave-conversation-btn"
                  />
                }
                modalTitle="Leave Conversation?"
                closeable={false}
              />
            )}
            {isInviteModalOpen && (
              <Modal
                closeFn={closeInviteModal}
                isOpen={isInviteModalOpen}
                content={
                  <AgentModal
                    customerCcid={customer?.ccid}
                    isInviteModalOpen={isInviteModalOpen}
                    setIsInviteCheckModalOpen={setIsInviteCheckModalOpen}
                    setSelectedAgent={setSelectedAgent}
                    closeModal={closeInviteModal}
                  />
                }
                modalTitle="Invite To Conversation"
                modalSubTitle="Please choose an agent from the list below to invite to the following conversation"
              />
            )}
            {isInviteCheckModalOpen && (
              <Modal
                autoFocus={false}
                closeFn={() => setIsInviteCheckModalOpen(false)}
                isOpen={isInviteCheckModalOpen}
                content={
                  <AgentConfirmModal
                    closeModal={() => setIsInviteCheckModalOpen(false)}
                    successModal={inviteAgent}
                    cancelBtnText="Cancel"
                    successBtnText="Yes"
                    backBtnText="< Choose a different agent"
                    backToPrevStep={() => {
                      setIsInviteModalOpen(true);
                      setIsInviteCheckModalOpen(false);
                    }}
                    dataTestId="confirm-invite-agent-btn"
                    agentName={selectedAgent?.fullName}
                  />
                }
                modalTitle="Invite To Conversation?"
                closeable={false}
              />
            )}
          </Block>
        )}

        {IsMyUnassignedConversationClicked && (
          <Block
            overrides={{
              Block: {
                style: () => ({
                  marginTop: '30px',
                }),
              },
            }}
          >
            <AgentAvatar name="?" status={status}></AgentAvatar>
            {clientId && channelSid && (
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                })}
              >
                <div
                  className={css({
                    paddingRight: '22px',
                  })}
                >
                  <Button
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: 'auto',
                          height: '40px',
                          borderTopLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderTopRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          color: '#3693BF',
                          backgroundColor: customTheme.colors.white,
                          paddingLeft: '2rem',
                          paddingRight: '2rem',
                          boxShadow: 'rgba(54, 147, 191, 0.23) 0px 0px 5px 0px',
                        }),
                        props: {
                          'data-testid': 'join-conversation-button',
                        },
                      },
                    }}
                    onClick={joinConversation}
                  >
                    Join
                  </Button>
                </div>
                {isRelayMessengerR2Enabled && isCurrentUserAnAdmin && (
                  <div>
                    <Button
                      kind={KIND.secondary}
                      size={SIZE.mini}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            width: 'auto',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                            height: '40px',
                            borderTopLeftRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderBottomLeftRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderTopRightRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderBottomRightRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            color: '#BE0000',
                            backgroundColor: customTheme.colors.white,
                            boxShadow:
                              'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                          }),
                          props: {
                            'data-testid': 'assign-conversation-button',
                          },
                        },
                      }}
                      onClick={() => setIsAssignModalOpen(true)}
                    >
                      Assign
                    </Button>
                  </div>
                )}
              </div>
            )}
            {isAssignModalOpen && (
              <Modal
                closeFn={closeAssignedModal}
                isOpen={isAssignModalOpen}
                content={
                  <AgentModal
                    customerCcid={customer?.ccid}
                    isInviteModalOpen={isAssignModalOpen}
                    setIsInviteCheckModalOpen={setIsAssignCheckModalOpen}
                    setSelectedAgent={setSelectedAgent}
                    closeModal={closeAssignedModal}
                    variant="assign"
                  />
                }
                modalTitle="Assign Conversation"
                modalSubTitle="Please choose an agent from the list below to assign to the following conversation"
              />
            )}
            {isAssignCheckModalOpen && (
              <Modal
                autoFocus={false}
                closeFn={() => setIsAssignCheckModalOpen(false)}
                isOpen={isAssignCheckModalOpen}
                content={
                  <AgentConfirmModal
                    closeModal={() => setIsAssignCheckModalOpen(false)}
                    successModal={assignConversation}
                    cancelBtnText="Cancel"
                    successBtnText="Yes"
                    backBtnText="< Choose a different agent"
                    backToPrevStep={() => {
                      setIsAssignModalOpen(true);
                      setIsAssignCheckModalOpen(false);
                    }}
                    dataTestId="confirm-assign-agent-btn"
                    agentName={selectedAgent?.fullName}
                    variant="assign"
                  />
                }
                modalTitle="Assign Conversation?"
                closeable={false}
              />
            )}
          </Block>
        )}
      </Block>
      <Block
        overrides={{
          Block: {
            style: () => ({
              marginTop: '30px',
            }),
          },
        }}
      >
        {isRemoveModalOpen && (
          <Modal
            closeFn={closeRemoveModal}
            isOpen={isRemoveModalOpen}
            content={
              <ActionsModal
                closeModal={closeRemoveModal}
                successModal={removeAgentFromConversation}
                modalSubTitle={`Are you sure you want to remove this agent from the conversation? They would need to join the conversation again to be able to respond.`}
                cancelBtnText="Cancel"
                successBtnText="Yes"
                dataTestId="confirm-leave-conversation-btn"
              />
            }
            modalTitle="Remove Agent From Conversation?"
            closeable={false}
          />
        )}
        {/* 
            Check agents assigned to conversation: one or two agents only
            */}
        {showCustomerOrAgentInfo &&
          isCurrentUserAnAdmin &&
          agentConversationsClicked &&
          (assignedAgents?.length < 2 ? (
            <>
              <SingleAgentView
                userFullName={
                  agentConversationsClicked
                    ? selectedAgentFullName
                    : userFullName
                }
                status={firstAgentStatus}
                userLeft={userLeft}
                openInviteModal={openInviteModal}
                openLeaveModal={openLeaveModal}
                openRemoveModal={openRemoveModal}
                agentConversationsClicked={agentConversationsClicked}
                hideAgentActions={!channelSid}
                firstAgentId={selectedAgentId}
                setIsAssignModalOpen={setIsAssignModalOpen}
              />
              {isAssignModalOpen && (
                <Modal
                  closeFn={closeAssignedModal}
                  isOpen={isAssignModalOpen}
                  content={
                    <AgentModal
                      customerCcid={customer?.ccid}
                      isInviteModalOpen={isAssignModalOpen}
                      setIsInviteCheckModalOpen={setIsAssignCheckModalOpen}
                      setSelectedAgent={setSelectedAgent}
                      closeModal={closeAssignedModal}
                      variant="re-assign"
                    />
                  }
                  modalTitle="Re-assign Conversation"
                  modalSubTitle="Please choose an agent from the list below to re-assign the following conversation"
                />
              )}
              {isAssignCheckModalOpen && (
                <Modal
                  autoFocus={false}
                  closeFn={() => setIsAssignCheckModalOpen(false)}
                  isOpen={isAssignCheckModalOpen}
                  content={
                    <AgentConfirmModal
                      closeModal={() => setIsAssignCheckModalOpen(false)}
                      successModal={reAssignConversation}
                      cancelBtnText="Cancel"
                      successBtnText="Yes"
                      backBtnText="< Choose a different agent"
                      backToPrevStep={() => {
                        setIsAssignModalOpen(true);
                        setIsAssignCheckModalOpen(false);
                      }}
                      dataTestId="confirm-assign-agent-btn"
                      agentName={selectedAgent?.fullName}
                      variant="re-assign"
                      assignedAgents={assignedAgents}
                    />
                  }
                  modalTitle="Re-assign Conversation?"
                  closeable={false}
                />
              )}
            </>
          ) : (
            <>
              <MultipleAgentsView
                firstAgentId={selectedAgentId}
                firstAgentFullName={selectedAgentFullName}
                firstAgentStatus={firstAgentStatus}
                userLeft={userLeft}
                secondAgentInfo={secondAgentInfo}
                secondAgentStatus={secondAgentStatus}
                openRemoveModal={openRemoveModal}
                agentConversationsClicked={agentConversationsClicked}
                openLeaveModal={openLeaveModal}
                setIsAssignModalOpen={setIsAssignModalOpen}
              />
              {isAssignModalOpen && (
                <Modal
                  closeFn={closeAssignedModal}
                  isOpen={isAssignModalOpen}
                  content={
                    <AgentModal
                      customerCcid={customer?.ccid}
                      isInviteModalOpen={isAssignModalOpen}
                      setIsInviteCheckModalOpen={setIsAssignCheckModalOpen}
                      setSelectedAgent={setSelectedAgent}
                      closeModal={closeAssignedModal}
                      variant="re-assign"
                    />
                  }
                  modalTitle="Re-assign Conversation"
                  modalSubTitle="Please choose an agent from the list below to re-assign the following conversation"
                />
              )}
              {isAssignCheckModalOpen && (
                <Modal
                  autoFocus={false}
                  closeFn={() => setIsAssignCheckModalOpen(false)}
                  isOpen={isAssignCheckModalOpen}
                  content={
                    <AgentConfirmModal
                      closeModal={() => setIsAssignCheckModalOpen(false)}
                      successModal={reAssignConversation}
                      cancelBtnText="Cancel"
                      successBtnText="Yes"
                      backBtnText="< Choose a different agent"
                      backToPrevStep={() => {
                        setIsAssignModalOpen(true);
                        setIsAssignCheckModalOpen(false);
                      }}
                      dataTestId="confirm-assign-agent-btn"
                      agentName={selectedAgent?.fullName}
                      variant="re-assign"
                      assignedAgents={assignedAgents}
                    />
                  }
                  modalTitle="Re-assign Conversation?"
                  closeable={false}
                />
              )}
            </>
          ))}
        {metadata && (
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <div
              className={css({
                borderTop: '1px solid #6E7376',
                marginTop: '30px',
              })}
            >
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Experience
              </p>
              <p>{metadata?.experienceName ? metadata.experienceName : '-'}</p>
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Message
              </p>
              <p>{metadata?.messageName ? metadata.messageName : '-'}</p>
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Created On
              </p>
              <p
                className={css({
                  fontSize: '16px',
                })}
              >
                {dateCreated &&
                  Moment(dateCreated).format('MM/DD/YYYY hh:mm A')}
                {!dateCreated && '-'}
              </p>

              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Last Updated
              </p>
              <p
                className={css({
                  fontSize: '16px',
                })}
              >
                {dateUpdated &&
                  Moment(dateUpdated).format('MM/DD/YYYY hh:mm A')}
                {!dateUpdated && '-'}
              </p>
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Last Pinged
              </p>
              <p
                className={css({
                  fontSize: '16px',
                })}
              >
                {customer &&
                  lastPinged &&
                  Moment(lastPinged).format('MM/DD/YYYY hh:mm A')}
                {customer && !lastPinged && '-'}
              </p>
            </div>
            <div
              className={css({
                borderTop: '1px solid #6E7376',
              })}
            >
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  fontStyle: 'bold',
                })}
              >
                Notification Channels
              </p>
              {customer &&
                customer.notification_channels &&
                customer.notification_channels.length &&
                customer.notification_channels.map((channel, i) => {
                  const { channel_addr } = channel;
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  const phoneOrEmail = emailRegex.test(channel.channel_addr)
                    ? 'Email'
                    : 'Phone Number';
                  return (
                    <div key={i}>
                      <p
                        className={css({
                          fontSize: '12px',
                          marginBottom: '0px',
                          paddingBottom: '0px',
                        })}
                      >
                        {phoneOrEmail}
                      </p>
                      <p
                        className={css({
                          fontSize: '16px',
                          marginBottom: '1px',
                        })}
                      >
                        {formatPhoneEmailAddress(channel.channel_addr)}
                      </p>

                      <p
                        className={css({
                          fontSize: '12px',
                          marginBottom: '0px',
                          paddingBottom: '0px',
                        })}
                      >
                        Consent
                      </p>
                      <p
                        className={css({
                          fontSize: '16px',
                          textTransform: 'capitalize',
                        })}
                      >
                        {channel.consent_type}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div
              className={css({
                borderTop: '1px solid #6E7376',
              })}
            >
              <p
                className={css({
                  fontSize: '12px',
                  marginBottom: '0px',
                  paddingBottom: '0px',
                })}
              >
                Tag
              </p>
              <p
                className={css({
                  fontSize: '16px',
                })}
              >
                {tagName}
              </p>
            </div>
          </div>
        )}
      </Block>
    </div>
  );
};
