import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ClientConfigModule } from './client-config/client-config.module';
import { CoreModule } from './core/core.module';
import { CxBuilderModule } from './cx-builder/cx-builder.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JourneyListModule } from './journey-list/journey-list.module';
import { LauncherModule } from './launcher/launcher.module';
import { LegalModule } from './legal/legal.module';
import { MediaLibraryModule } from './media-library/media-library.module';
import { NavigationModule } from './navigation/navigation.module';
import { RelaySharedModule } from './shared/shared.module';
import { SecurityModule } from './security/security.module';
import { CustomerListModule } from './customer/customer.module';
import { AppRoutingModule } from './app-routing.module';
import { PreviewModule } from './preview/preview.module';
import { UserModule } from './user/user.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppWrapperComponent } from '@app/app-wrapper.component';
import { JobsListModule } from '@app/jobs-list/jobs-list.module';
import { TitleTextPipe } from '@app/shared/pipes/title-text.pipe';
import { TwoWayModule } from '@app/two-way/two-way.module';
import { Title } from '@angular/platform-browser';
import { SftpMgmtModule } from '@app/sftp-mgmt/sftp-mgmt.module';
import { ShortcodeMgmtModule } from '@app/shortcode-mgmt/shortcode-mgmt.module';
import { ApiMgmtModule } from './api-mgmt/api-mgmt.module';
import { TimerResetInterceptor } from './core/services/timerResetInterceptor';

@NgModule({
  declarations: [AppComponent, AppWrapperComponent],
  providers: [
    DatePipe,
    TitleTextPipe,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimerResetInterceptor,
      multi: true,
    },
  ],
  imports: [
    AppRoutingModule,
    SecurityModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClientConfigModule,
    TwoWayModule,
    CoreModule,
    LauncherModule,
    ReactiveFormsModule,
    CustomerListModule,
    CxBuilderModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    JourneyListModule,
    JobsListModule,
    LauncherModule,
    LauncherModule,
    LegalModule,
    MediaLibraryModule,
    NavigationModule,
    PreviewModule,
    ReactiveFormsModule,
    RelaySharedModule,
    SecurityModule,
    UserModule,
    SftpMgmtModule,
    ShortcodeMgmtModule,
    ApiMgmtModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
