import React, { useState } from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { ChevronRight, Check } from 'baseui/icon';
import { FilterLastModifiedProps } from '../../relay-messenger-list/RelayMessenger.list';
import { FilterCustomButton } from './FlterCustomButton';
import {
  FilterMethodOptions,
  FilterMethods,
  FilterOptionType,
} from '../../relay-messenger-conversations/RelayMessengerConversations';
import { FilterVariantButton } from './FilterVariantButton';

interface FilterLastModifiedItemProps {
  filterLastModified: FilterLastModifiedProps;
  close: () => void;
  rangeDate: Date[];
  setRangeDate: (value: Date[]) => void;
  clearFilterState: () => void;
  clearSortingState: () => void;
}

export const FilterLastModifiedAction = ({
  filterLastModified,
  close,
  rangeDate,
  setRangeDate,
  clearFilterState,
  clearSortingState,
}: FilterLastModifiedItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  // state to highlight custom button if selected
  const [customDateClicked, setCustomDateClicked] =
    useState<FilterOptionType>(null);

  return (
    <StatefulPopover
      content={({ close: innerClose }) => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '170px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          <FilterVariantButton
            value={FilterMethodOptions.PastDay}
            filterOptionClicked={
              filterLastModified?.filterOptionLastModifiedClicked
            }
            filterByOption={filterLastModified?.filterByLastModified}
            setFilterByOption={filterLastModified?.setFilterByLastModified}
            close={close}
            dataTestId="filter-last-modified-past-day"
          />
          <FilterVariantButton
            value={FilterMethodOptions.PastWeek}
            filterOptionClicked={
              filterLastModified?.filterOptionLastModifiedClicked
            }
            filterByOption={filterLastModified?.filterByLastModified}
            setFilterByOption={filterLastModified?.setFilterByLastModified}
            close={close}
            dataTestId="filter-last-modified-past-week"
          />
          <FilterVariantButton
            value={FilterMethodOptions.PastMonth}
            filterOptionClicked={
              filterLastModified?.filterOptionLastModifiedClicked
            }
            filterByOption={filterLastModified?.filterByLastModified}
            setFilterByOption={filterLastModified?.setFilterByLastModified}
            close={close}
            dataTestId="filter-last-modified-past-month"
          />
          <FilterCustomButton
            value={FilterMethodOptions.Custom}
            customDateClicked={customDateClicked}
            filterOptionClicked={
              filterLastModified?.filterOptionLastModifiedClicked
            }
            filterByValue={filterLastModified?.filterByLastModified}
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
            onSuccess={(value) => {
              setCustomDateClicked(value);
              filterLastModified?.setFilterByLastModified(value);
            }}
            close={close}
            innerClose={innerClose}
            dataTestId="filter-last-modified-custom"
          />
        </Block>
      )}
      placement={PLACEMENT.right}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          onClick={() => {
            filterLastModified?.setFilterOptionLastModifiedClicked(
              FilterMethods.LastModified,
            );
            clearFilterState();
            clearSortingState();
          }}
          value={FilterMethods.LastModified}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: filterLastModified?.filterOptionLastModifiedClicked
                  ? '#3693BF'
                  : 'black',
                backgroundColor:
                  filterLastModified?.filterOptionLastModifiedClicked
                    ? '#E1EFF5'
                    : 'transparent',
                fontWeight: filterLastModified?.filterOptionLastModifiedClicked
                  ? 'bolder'
                  : 'inherit',
                textAlign: 'center',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'filter-last-modified-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            filterLastModified?.filterByLastModified ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {FilterMethods.LastModified}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
