import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { NavbarStateService } from '@app/core/services/navbar-state.service';
import { SessionService } from '@app/security/session.service';
import { AuthGuardService } from '@app/security/auth-guard.service';
import { appRoutes } from '@app/app-routing.module';
import { environment } from '@env/environment';
import { FeatureService } from '@app/core/services/feature.service';
import { LoggerService } from '@app/core/services/logger.service';
import { Client } from '@app/core/models/client';
import * as _ from 'lodash';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChild('sideNavMenu', { static: true }) sideNavMenu: ElementRef;
  @ViewChild('sideNavFooter', { static: true }) sideNavFooter: ElementRef;
  version: string;
  searchText = '';
  client: Client;
  featureFlags = FeatureFlags;
  menuItems = [
    {
      title: 'Experience Center',
      children: [
        {
          title: 'Onboarding',
          link: '/onboarding',
          icon: 'fa fa-handshake-simple',
        },
        {
          title: 'All Experiences',
          link: '/experiences',
          icon: 'fa fa-rectangle-list',
        },
        {
          title: 'Experience Launcher',
          link: '/launcher',
          icon: 'fa fa-rocket-launch',
        },
        {
          title: 'Experience Builder',
          link: '/cx-builder',
          icon: 'fa fa-hammer',
        },
        {
          title: 'Media Library',
          link: '/media-branding-library',
          icon: 'fa fa-photo-film',
        },
      ],
    },
    {
      title: 'Data',
      children: [
        {
          title: 'Data & Reporting',
          link: '/data-and-reporting',
          icon: 'fa fa-bar-chart',
        },
        {
          title: 'File Engine Jobs',
          link: '/file-engine-jobs',
          icon: 'fa fa-files',
        },
      ],
    },
    {
      title: 'two_way',
      children: [
        {
          title: 'Setup',
          link: '/two-way/setup',
          icon: 'fa fa-gear',
        },
        {
          title: 'Control Groups',
          link: '/two-way/control-groups',
          icon: 'fa fa-users-gear',
        },
        {
          title: 'Messaging',
          link: '/two-way/messaging',
          icon: 'fa fa-comments',
        },
        {
          title: 'Relay Messenger',
          link: '/two-way/relay-messenger',
          icon: 'fa fa-comments',
        },
        {
          title: 'Dashboard',
          link: '/two-way/dashboard',
          icon: 'fa fa-chart-line',
        },
      ],
    },
    {
      title: 'Settings',
      children: [
        {
          title: 'All Clients',
          link: '/client',
          icon: 'fa fa-user-group',
        },
        {
          title: 'User Management',
          link: '/user-mgmt',
          icon: 'fa fa-user-gear',
        },
        {
          title: 'Customer Lookup',
          link: '/customer-lookup',
          icon: 'fa fa-user-magnifying-glass',
        },
        {
          title: 'Relay IQ',
          link: '/relay-iq',
          icon: 'fa fa-brain',
        },
        {
          title: 'SFTP & GPG',
          link: '/sftp-mgmt',
          icon: 'fa fa-cloud-check',
        },
        {
          title: 'Single Sign-On',
          link: '/sso',
          icon: 'fa fa-repeat-1',
        },
        {
          title: 'API Management',
          link: '/api-mgmt',
          icon: 'fa fa-key',
        },
        {
          title: 'Report Configuration',
          link: '/report-configuration',
          icon: 'fa-sharp fa-light fa-file-lines',
        },
        {
          title: 'Short Codes',
          link: '/shortcode-mgmt',
          icon: 'fa fa-code-simple',
        },
        {
          title: 'Custom Keywords',
          link: '/t2e-mgmt',
          icon: 'fa-solid fa-message-text',
        },
      ],
    },
  ];

  constructor(
    public navbarStateService: NavbarStateService,
    public sessionService: SessionService,
    private router: Router,
    private featureSwitch: FeatureService,
    private authGuardService: AuthGuardService,
  ) {}

  ngOnInit() {
    this.version = environment.version;
    const menuFiltered = [];
    if (this.sessionService.currentUser) {
      this.client = this.sessionService.currentUser.client;

      const expLibRoute = {
        title: 'Experience Library',
        link: '/experience-library',
        icon: 'fa fa-books',
      };

      const allRoutes = appRoutes.concat(appRoutes[1].children);

      if (
        this.client['industry'] !== '' &&
        this.client['company_type'] !== ''
      ) {
        this.menuItems[0].children.push(expLibRoute);
      }
      const menuMedia = this.menuItems[0].children.find(
        (m) => m.link === '/media-branding-library',
      );
      let mediaTitle = this.showMediaBrandingLibrary()
        ? 'Media & Branding'
        : 'Media Library';
      const mediaLibrary = {
        title: mediaTitle,
        link: '/media-branding-library',
        icon: 'fa fa-photo-film',
      };
      Object.assign(menuMedia, mediaLibrary);
      this.menuItems.forEach((menu) => {
        const children = menu.children.filter((c) => {
          const menuLink = c.link.substr(1);
          if (menuLink == 'two-way/messaging') {
            return this.showTwoWayMessaging();
          } else if (menuLink == 'two-way/relay-messenger') {
            return this.showRelayMessenger();
          }
          else if (menuLink =='two-way/dashboard') {
            return this.showTwoWayDashboard();
          } else {
            const route = allRoutes.find(
              (r) => r.path.toLowerCase() === menuLink.toLowerCase(),
            );
            return this.checkRouteMatrix(route);
          }
        });
        if (children.length) {
          menuFiltered.push({ title: menu.title, children: children });
        }
      });
    }
    this.menuItems = menuFiltered;
  }

  showRelayMessenger(): boolean {
    const twoWayEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'two_way_config.enabled',
      false, // Default to false for safety
    );
    const feedEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'feed_enabled',
      false, // Default to false for safety
    );

    // Show only if both two_way_config.enabled and feed_enabled are true
    return twoWayEnabled && feedEnabled;
  }

  showTwoWayMessaging(): boolean {
    const twoWayEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'two_way_config.enabled',
      false,
    );
    const feedEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'feed_enabled',
      false,
    );

    // Show (return true) only if two_way_config.enabled is true and feed_enabled is false
    return twoWayEnabled && !feedEnabled;
  }

  showTwoWayDashboard(): boolean {
    const relayMessengerR3Flag = this.featureSwitch.checkFlag(
      this.featureFlags.relay_messenger_r3,
    );
    const feedEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'feed_enabled',
      false,
    );
    const twoWayEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'two_way_config.enabled',
      false,
    );
    return relayMessengerR3Flag && feedEnabled && twoWayEnabled;
  }

  showMediaBrandingLibrary(): boolean {
    const messageBrandingFlag = this.featureSwitch.checkFlag(
      this.featureFlags.feed2_message_level_branding,
    );
    const feedEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'feed_enabled',
      false,
    );

    return messageBrandingFlag && feedEnabled;
  }

  setMatTooltipMessage(message: string): string {
    return !this.navbarStateService.fullSize ? message : '';
  }

  toggle(): void {
    this.navbarStateService.fullSize = !this.navbarStateService.fullSize;
  }

  search(): void {
    if (this.searchText.trim()) {
      this.router.navigateByUrl(`/search/${this.searchText}`);
    }
  }

  termsText(): string {
    return this.navbarStateService.fullSize ? 'Terms & Conditions' : 'Terms';
  }

  privacyText(): string {
    return this.navbarStateService.fullSize ? 'Privacy Policy' : 'Privacy';
  }

  private checkRouteMatrix(route: Route): boolean {
    const checks = [];

    if (_.get(route, 'data.perm')) {
      checks.push(
        this.authGuardService.permService.checkPermissions(route.data.perm),
      );
    }

    if (_.get(route, 'data.flag')) {
      checks.push(this.featureSwitch.checkFlag(route.data.flag));
    }

    if (_.get(route, 'data.config')) {
      checks.push(
        _.get(this.sessionService.getCurrentUsersClient(), route.data.config),
      );
    }

    LoggerService.log('SideNavComponent', `checking route matrix: ${checks}`);

    return checks.indexOf(false) === -1;
  }
}
