import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RadioBtnComponent } from '@app/shared/radio-btn/radio-btn.component';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { LoggerService } from '@app/core/services/logger.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { NewFeedTransitionService} from '@app/core/services/new-feed-transition.service';
import * as _ from 'lodash';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';


@Component({
  selector: 'app-new-product-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class ProductLegalComponent implements OnInit, OnDestroy {
  @ViewChild(RadioBtnComponent) radioBtn;
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  @ViewChild('feedMenuExampleDialog', { static: true }) feedMenuExampleDialog: MessageDialogComponent;
  @ViewChild('feedViewExampleDialog', { static: true }) feedViewExampleDialog: MessageDialogComponent;
  @ViewChild('messageDetailsExampleDialog', { static: true }) messageDetailsExampleDialog: MessageDialogComponent;
  @ViewChild('messageLevelExampleDialog', { static: true }) messageLevelExampleDialog: MessageDialogComponent;

  client: Client;
  currentGroup: ProductGroup;
  currentGroupID: string;
  headerGroups = {
    boringLegalStuff: true,
    termsAndConditions: true,      // only used when new
    additionalLegalSettings: true, // feed
    passwordLegalSettings: true, // is enabled
  };
  legalFormGroup: UntypedFormGroup;

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private router: Router,
              private titleService: TitleService,
              private actionService: ActionService,
              public newFeed: NewFeedTransitionService,
  ) {

  }

  ngOnInit() {
    this.getClientAndProductGroup();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  initForm(currentGroup: ProductGroup) {
    const iwcuValidators = [];
    if (this.currentGroup.consent.in_wire_upgrade.enabled) {
      iwcuValidators.push(Validators.required);
    }
    if (this.client.feed_enabled) {
      iwcuValidators.push(Validators.maxLength(CharacterLimits.InFeedConsentUpgradeTsCsCharLimit))
    }

    this.legalFormGroup = new UntypedFormGroup({
      'privacy_policy': new UntypedFormControl(currentGroup.privacy_policy, CustomValidators.validURL),
      'terms': new UntypedFormControl(currentGroup.terms, CustomValidators.validURL),
      'consent.in_wire_upgrade.ts_cs': new UntypedFormControl(currentGroup.consent.in_wire_upgrade.ts_cs, iwcuValidators),
      'consent.express_consent_ts_cs': new UntypedFormControl(currentGroup.consent.express_consent_ts_cs, this.client.feed_enabled ? [Validators.maxLength(CharacterLimits.ExpressConsentTsCsCharLimit)] : []),
      'consent.express_written_consent_ts_cs': new UntypedFormControl(currentGroup.consent.express_written_consent_ts_cs, this.client.feed_enabled ? [Validators.maxLength(CharacterLimits.ExpressWrittenConsentTsCsCharLimit)] : []),
      'password.tcs': new UntypedFormControl(currentGroup.password.tcs, (currentGroup.password.tc_enabled && this.client.feed_enabled) ? [Validators.maxLength(CharacterLimits.PasswordPageTsCsCharLimit)] : []),
      'disclaimer.text': new UntypedFormControl(currentGroup.disclaimer.text, this.client.feed_enabled ? [Validators.maxLength(CharacterLimits.DisclaimerCharacterLimit)] : [])
    });

    // We want the error icon to appear on first render for disclaimer
    if (!this.legalFormGroup.controls['disclaimer.text'].valid) {
      this.legalFormGroup.controls['disclaimer.text'].markAsTouched();
    }

    if (!this.legalFormGroup.controls['consent.express_consent_ts_cs'].valid) {
      this.legalFormGroup.controls['consent.express_consent_ts_cs'].markAsTouched();
    }

    if (!this.legalFormGroup.controls['consent.express_written_consent_ts_cs'].valid) {
      this.legalFormGroup.controls['consent.express_written_consent_ts_cs'].markAsTouched();
    }

    if (!this.iwcuFormCtl.valid) {
      this.iwcuFormCtl.markAsTouched();
    }

    if (!this.legalFormGroup.controls['password.tcs'].valid) {
      this.legalFormGroup.controls['password.tcs'].markAsTouched();
    }
  }

  updateModel() {
    // NOTE: have to omit keys with `null` values - the api won't accept null, and we've collectively decided
    // that deleting the key is preferable to sending an empty string.  Note that a null value only occurs when
    // creating a new product group.
    const data = _.omitBy(this.legalFormGroup.getRawValue(), _.isNil);

    _.each(data, (val, key) => {
      _.set(this.currentGroup, key, val);
    });
  }

  cancelCurrent(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/product-group/${this.currentGroupID}`);
  }

  getClientAndProductGroup(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.currentGroupID = params.productId;
        this.clientService.getClient(params.clientId).subscribe(
          client => {
            this.client = client;
            this.clientService.getProductGroup(this.client.id, this.currentGroupID).subscribe(
              productGroup => {
                this.currentGroup = productGroup;
                this.initForm(this.currentGroup);
              },
              error => {
                this.messageDialog.showMessage(`Oops...could not load client product group data: ${error}`);
                LoggerService.log('ProductLegalComponent', `getClientAndProductGroup() error: ${error}`);
              }
            );
          },
          error => {
            this.messageDialog.showMessage(`Oops...could not load client data: ${error}`);
            LoggerService.log('ProductLegalComponent', `getClientAndProductGroup() error: ${error}`);
          },
          () => {
            this.setPrimaryTitle();
          }
        );
      }
    );
  }

  saveProduct(): void {
    if (!this.legalFormGroup.valid) {
      this.legalFormGroup.markAllAsTouched();
      return;
    }

    this.updateModel();

    const subs = [this.clientService.updateClient(this.client), this.clientService.updateProductGroup(this.client.id, this.currentGroup)];
    forkJoin(subs)
      .subscribe(
        res => this.router.navigateByUrl(`/client/${this.client.id}/product-group/${this.currentGroupID}/onboarding`),
        err => this.onFailedSaved(err)
      );
  }

  get iwcuFormCtl(): UntypedFormControl {
    return this.legalFormGroup.controls['consent.in_wire_upgrade.ts_cs'] as UntypedFormControl;
  }

  get iwcuFormIsEmpty(): boolean {
    return this.iwcuFormCtl.value.length === 0;
  }

  getTooltipData(errors: Object): string | void {
    return this.actionService.getTooltipData(errors);
  }


  getCharacterLimit(field: string): number {
    switch (field) {
      case 'disclaimer':
        return CharacterLimits.DisclaimerCharacterLimit;
      case 'expressConsentTsCs':
        return CharacterLimits.ExpressConsentTsCsCharLimit;
      case 'expressWrittenConsentTsCs':
        return CharacterLimits.ExpressWrittenConsentTsCsCharLimit;
      case 'inFeedConsentUpgradeTsCs':
        return CharacterLimits.InFeedConsentUpgradeTsCsCharLimit;
      case 'passwordTsCs':
        return CharacterLimits.PasswordPageTsCsCharLimit;
    }
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    LoggerService.log('ProductLegalComponent', `saveProduct() error: ${err}`);
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(`Oops...could not save client${serverMessage}`);
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Edit Product Group - ' + this.client.company_name
        : 'Edit Product Group');
  }
}
